import { ComponentProps } from "."
import CriticsPodSource from "components/Critics/PodSource"
import Overrides from "./Overrides"

const PodSource = ({
  areReviewsLoading,
  inputRefs,
  isCompact,
  isCreated,
  isLoading,
  isValid,
  label,
  onCancel,
  onClear,
  onSave,
  onSetReleaseDate,
  overrides,
  serviceName,
  text
}: ComponentProps) => (
  <CriticsPodSource
    areItemsLoading={areReviewsLoading}
    isCompact={isCompact}
    isCreated={isCreated}
    isLoading={isLoading}
    isValid={isValid}
    itemLabel="Reviews"
    label={label}
    onCancelOverrides={onCancel}
    onClear={onClear}
    onSaveOverrides={onSave}
    overrides={
      <Overrides
        inputRefs={inputRefs}
        onSetReleaseDate={onSetReleaseDate}
        overrides={overrides}
      />
    }
    serviceName={serviceName}
    text={text}
  />
)
export default PodSource
