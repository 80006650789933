import gql from "graphql-tag"

const SEARCH_NEWS = gql`
  query searchNews($searchNewsInput: SearchNewsInput!) {
    searchNews(searchNewsInput: $searchNewsInput) {
      id
      name
    }
  }
`

export default SEARCH_NEWS
