import { gql } from "graphql-tag"

const mas = gql`
  type Mutation {
    addMAS(masAddInput: MASAddInput!): MAS!
    addMASFromImdbId(imdbId: String!): MAS!

    previewMAS(imdbId: String!): PreviewMASResponse!

    updateMASFromImdbId(imdbId: String!): MAS!
  }
`

export default mas
