/* eslint-disable max-lines-per-function, no-empty-function, max-statements, max-lines */
import { useCallback, useMemo } from "react";
import createNewsCritics from "./createNewsCritics";
import { mutations } from "@bluframe/mightychroma-mightytool-graphql-schema";
import { useMutation } from "@apollo/client";
const { news: { ADD_NEWS_SOURCE } } = mutations;
export const initialContext = {
    areNewsSourcesCalled: false,
    areNewsSourcesLoading: false,
    avNirvanaData: undefined,
    bluRayData: undefined,
    hidefDigestData: undefined,
    onCreateNewsSource: () => { },
    resetNewsSources: () => { },
    whySoBluData: undefined
};
const useCreateNewsCriticsContext = ({ avNirvana, bluRay, hidefDigest, news, whySoBlu }) => {
    const [addAvNirvana, { called: avNirvanaCalled, data: avNirvanaData, loading: avNirvanaLoading, reset: avNirvanaReset }] = useMutation(ADD_NEWS_SOURCE, {
        variables: {
            newsSourceAddInput: {
                overrides: avNirvana.selected.overrides,
                serviceName: "avNirvana",
                url: avNirvana.selected.url
            }
        }
    });
    const [addBluRay, { called: bluRayCalled, data: bluRayData, loading: bluRayLoading, reset: bluRayReset }] = useMutation(ADD_NEWS_SOURCE, {
        variables: {
            newsSourceAddInput: {
                overrides: bluRay.selected.overrides,
                serviceName: "bluRay",
                url: bluRay.selected.url
            }
        }
    });
    const [addHidefDigest, { called: hidefDigestCalled, data: hidefDigestData, loading: hidefDigestLoading, reset: hidefDigestReset }] = useMutation(ADD_NEWS_SOURCE, {
        variables: {
            newsSourceAddInput: {
                overrides: hidefDigest.selected.overrides,
                serviceName: "hidefDigest",
                url: hidefDigest.selected.url
            }
        }
    });
    const [addWhySoBlu, { called: whySoBluCalled, data: whySoBluData, loading: whySoBluLoading, reset: whySoBluReset }] = useMutation(ADD_NEWS_SOURCE, {
        variables: {
            newsSourceAddInput: {
                overrides: whySoBlu.selected.overrides,
                serviceName: "whySoBlu",
                url: whySoBlu.selected.url
            }
        }
    });
    const areNewsSourcesCalled = useMemo(() => avNirvanaCalled || bluRayCalled || hidefDigestCalled || whySoBluCalled, [avNirvanaCalled, bluRayCalled, hidefDigestCalled, whySoBluCalled]);
    const areNewsSourcesLoading = useMemo(() => avNirvanaLoading ||
        bluRayLoading ||
        hidefDigestLoading ||
        whySoBluLoading, [avNirvanaLoading, bluRayLoading, hidefDigestLoading, whySoBluLoading]);
    const resetNewsSources = useCallback(() => {
        if (avNirvanaData) {
            avNirvanaReset();
        }
        if (bluRayData) {
            bluRayReset();
        }
        if (hidefDigestData) {
            hidefDigestReset();
        }
        if (whySoBluData) {
            whySoBluReset();
        }
    }, [
        avNirvanaData,
        avNirvanaReset,
        bluRayData,
        bluRayReset,
        hidefDigestData,
        hidefDigestReset,
        whySoBluData,
        whySoBluReset
    ]);
    const onCreateNewsSource = useCallback(() => {
        createNewsCritics({
            addAvNirvana,
            addBluRay,
            addHidefDigest,
            addWhySoBlu,
            avNirvanaReady: Boolean(avNirvana.selected.url) && !news.avNirvanaId,
            bluRayReady: Boolean(bluRay.selected.url) && !news.bluRayId,
            hidefDigestReady: Boolean(hidefDigest.selected.url) && !news.hidefDigestId,
            whySoBluReady: Boolean(whySoBlu.selected.url) && !news.whySoBluId
        });
    }, [
        addAvNirvana,
        addBluRay,
        addHidefDigest,
        addWhySoBlu,
        avNirvana,
        bluRay,
        hidefDigest,
        news,
        whySoBlu
    ]);
    return useMemo(() => ({
        areNewsSourcesCalled,
        areNewsSourcesLoading,
        avNirvanaData,
        bluRayData,
        hidefDigestData,
        onCreateNewsSource,
        resetNewsSources,
        whySoBluData
    }), [
        areNewsSourcesCalled,
        areNewsSourcesLoading,
        avNirvanaData,
        bluRayData,
        hidefDigestData,
        onCreateNewsSource,
        resetNewsSources,
        whySoBluData
    ]);
};
export default useCreateNewsCriticsContext;
