/* eslint-disable no-ternary, no-nested-ternary */

import Reset from "components/Reset"
import mediaQueries from "styles/mediaQueries"
import styled from "styled-components"

export const SocialLinksUl = styled(Reset.Ul)`
  align-items: center;
  display: grid;
  font-size: 14px;
  justify-items: start;
  row-gap: 10px;
  width: 100%;

  ${mediaQueries.tablet} {
    font-size: 16px;
    row-gap: 10px;
  }
`

export const SocialMediaUl = styled(Reset.Ul)<{
  $isBig?: boolean
  $isShowLabels?: boolean
}>`
  align-items: center;
  column-gap: ${({ $isBig }) => ($isBig ? "20px" : "10px")};
  display: grid;
  font-size: 14px;
  justify-items: start;
  grid-template-columns: repeat(
    auto-fit,
    minmax(
      ${({ $isBig, $isShowLabels }) =>
        $isShowLabels ? "200px, 1fr" : $isBig ? "30px, 30px" : "20px, 20px"}
    )
  );
  row-gap: 10px;
  width: 100%;

  ${mediaQueries.tablet} {
    column-gap: ${({ $isBig }) => ($isBig ? "20px" : "10px")};
    font-size: 16px;
    grid-template-columns: repeat(
      auto-fit,
      minmax(
        ${({ $isBig, $isShowLabels }) =>
          $isShowLabels ? "200px, 1fr" : $isBig ? "30px, 30px" : "20px, 20px"}
      )
    );
    row-gap: 10px;
  }
`

export const Li = styled(Reset.Li)``
