import gql from "graphql-tag"

const ADD_NEWS_SOURCE = gql`
  mutation addNewsSource($newsSourceAddInput: NewsSourceAddInput!) {
    addNewsSource(newsSourceAddInput: $newsSourceAddInput) {
      id
      serviceName
    }
  }
`

export default ADD_NEWS_SOURCE
