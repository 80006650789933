import {
  H1,
  H2,
  Left,
  Main,
  Paragraph,
  Right,
  Row,
  Top,
  Wrapper
} from "./styled"
import Autosuggest from "components/Autosuggest"
import Button from "components/buttons/Button"
import { ComponentProps } from "."

const ONE = 1

const ImagesGrid = ({
  homeVideoReviewIds,
  homeVideoReviewTitles,
  isAutoSuggestLoading,
  isCreateHomeVideoImagesGridLoading,
  onClickGenerate,
  onGetSuggestions,
  onSelectHomeVideoReviewId,
  reviewNames
}: ComponentProps) => (
  <Wrapper>
    <Top>
      <H1>{"Images Grid"}</H1>
      <Button
        disabled={isCreateHomeVideoImagesGridLoading}
        label="Create Images Grid"
        loading={isCreateHomeVideoImagesGridLoading}
        onClick={onClickGenerate}
      />
    </Top>
    <Main>
      <Left>
        {homeVideoReviewIds.map((homeVideoReviewId, index) => (
          <Row key={homeVideoReviewId}>
            <Autosuggest
              disabled={isAutoSuggestLoading}
              inputId={`homeVideoReviewId-${index + ONE}`}
              label={`Select Home Video Review ${index + ONE}`}
              onGetSuggestions={onGetSuggestions}
              onSuggestionClick={onSelectHomeVideoReviewId(index)}
              suggestions={homeVideoReviewTitles}
              value={homeVideoReviewId}
            />
          </Row>
        ))}
      </Left>
      <Right>
        {reviewNames?.map((reviewName, index) => (
          <Row key={reviewName}>
            <H2>{`Review ${index + ONE}`}</H2>
            <Paragraph>{reviewName}</Paragraph>
          </Row>
        ))}
      </Right>
    </Main>
  </Wrapper>
)

export default ImagesGrid
