import Overrides from "./Overrides"
import { RefObject } from "react"
import { ReviewOverrides } from "@bluframe/mightychroma-mightytool-frontend-types"

export interface Props {
  inputRefs: {
    [key: string]: {
      quote: RefObject<HTMLTextAreaElement>
      score: RefObject<HTMLInputElement>
      section: RefObject<HTMLTextAreaElement>
    }
  }
  // eslint-disable-next-line no-unused-vars
  onSetReleaseDate: (releaseDate: string) => void
  overrides: ReviewOverrides
}

export interface ComponentProps extends Props {}

export default Overrides
