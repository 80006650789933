/* eslint-disable max-lines-per-function, max-statements */
import { useEffect, useMemo, useState } from "react";
import { mutations, queries } from "@bluframe/mightychroma-mightytool-graphql-schema";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Roundup from "./Roundup";
import prepareComponent from "@bluframe/grapple/prepareComponent";
const ONE = 1;
const { blog: { GET_CHARACTERS }, homeVideoReviews: { GET_HOME_VIDEO_REVIEW, SEARCH_HOME_VIDEO_REVIEWS } } = queries;
const { blog: { GENERATE_BLOG_ROUNDUP } } = mutations;
const ZERO = 0;
const SLICE_END = 80;
const SLICE_START = 0;
const usePrepareComponent = () => {
    const [characterId, setCharacterId] = useState(null);
    const [homeVideoReviewIds, setHomeVideoReviewIds] = useState([""]);
    const [theme, setTheme] = useState("");
    const [reviewNames, setReviewNames] = useState([]);
    const [getHomeVideoReview] = useLazyQuery(GET_HOME_VIDEO_REVIEW);
    const [generateBlogRoundup, { loading: isGenerateBlogRoundupLoading }] = useMutation(GENERATE_BLOG_ROUNDUP);
    const [searchHomeVideoReviews, { data: searchHomeVideoReviewsData, loading: searchHomeVideoReviewsLoading }] = useLazyQuery(SEARCH_HOME_VIDEO_REVIEWS);
    useEffect(() => {
        const hasEmptyRow = homeVideoReviewIds.filter((id) => id === "").length > ZERO;
        homeVideoReviewIds.forEach((homeVideoReviewId, index) => {
            if (homeVideoReviewId) {
                getHomeVideoReview({
                    onCompleted: (homeVideoReviewData) => {
                        setReviewNames((prevReviewNames) => {
                            const newReviewNames = [...prevReviewNames];
                            // @ts-ignore
                            newReviewNames[index] = `${homeVideoReviewData?.getHomeVideoReview.summary.total.long.slice(SLICE_START, SLICE_END)}...`;
                            return newReviewNames;
                        });
                    },
                    variables: {
                        id: homeVideoReviewId
                    }
                });
            }
        });
        if (!hasEmptyRow) {
            setHomeVideoReviewIds([...homeVideoReviewIds, ""]);
        }
    }, [getHomeVideoReview, homeVideoReviewIds]);
    const { data: charactersData, loading: isCharactersLoading } = useQuery(GET_CHARACTERS);
    const characters = useMemo(() => charactersData?.getCharacters.map((item) => ({
        label: item.firstName,
        value: item.id
    })) ?? [], [charactersData]);
    const onChangeCharacterId = (value) => {
        setCharacterId(value);
    };
    const onSelectHomeVideoReviewId = (index) => (id) => {
        const newHomeVideoReviewIds = [...homeVideoReviewIds];
        newHomeVideoReviewIds[index] = id;
        setHomeVideoReviewIds(newHomeVideoReviewIds);
    };
    const onChangeTheme = (event) => {
        setTheme(event.target.value);
    };
    const onGetSuggestions = ({ inputValue }) => {
        searchHomeVideoReviews({
            variables: {
                searchHomeVideoReviewsInput: {
                    title: inputValue
                }
            }
        });
    };
    const onClickGenerate = () => {
        generateBlogRoundup({
            variables: {
                blogRoundupGenerateInput: {
                    characterId,
                    homeVideoReviewIds: homeVideoReviewIds.filter((id) => id),
                    theme
                }
            }
        });
    };
    const isGenerateBlogRoundupEnabled = Boolean(characterId && homeVideoReviewIds.length > ONE);
    return {
        characterId,
        characters,
        homeVideoReviewIds,
        homeVideoReviewTitles: searchHomeVideoReviewsData?.searchHomeVideoReviews ?? [],
        isAutoSuggestLoading: searchHomeVideoReviewsLoading,
        isCharactersLoading,
        isGenerateBlogRoundupEnabled,
        isGenerateBlogRoundupLoading,
        onChangeCharacterId,
        onChangeTheme,
        onClickGenerate,
        onGetSuggestions,
        onSelectHomeVideoReviewId,
        reviewNames,
        theme
    };
};
export default prepareComponent(usePrepareComponent)(Roundup);
