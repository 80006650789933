/* eslint-disable no-ternary, max-lines-per-function, max-statements */
import isNewsServiceName from "helpers/isNewsServiceName";
import useNewsCritics from "hooks/useNewsCritics";
import usePreviews from "hooks/usePreviews";
import usePreviewsNewsCritics from "hooks/usePreviewsNewsCritics";
import useReviews from "hooks/useReviews";
import { useState } from "react";
const DEFAULT_IS_MODAL_OPEN = false;
const useImageSelector = ({ serviceType }) => {
    const { images: newsCriticsImages } = usePreviewsNewsCritics();
    const { images: reviewsImages } = usePreviews();
    const { setImageServiceName: newsCriticsSetImageServiceName, state: { news: { imageServiceName: newsCriticsImageServiceName } } } = useNewsCritics();
    const { setImageServiceName: reviewsSetImageServiceName, state: { homeVideoReview: { imageServiceName: reviewsImageServiceName } } } = useReviews();
    const [isModalOpen, setIsModalOpen] = useState(DEFAULT_IS_MODAL_OPEN);
    const onModalClose = () => {
        setIsModalOpen(false);
    };
    const onSelectClick = () => {
        setIsModalOpen(true);
    };
    const images = serviceType === "news" ? newsCriticsImages : reviewsImages;
    const imageServiceName = serviceType === "news"
        ? newsCriticsImageServiceName
        : reviewsImageServiceName;
    const onSelectFrontImage = (serviceName) => {
        if (isNewsServiceName(serviceName)) {
            newsCriticsSetImageServiceName(serviceName);
        }
        else {
            reviewsSetImageServiceName(serviceName);
        }
    };
    return {
        imageReviewsServiceNameSelected: imageServiceName,
        images,
        modal: {
            isOpen: isModalOpen,
            name: "Image Selector",
            onClose: onModalClose
        },
        onSelectClick,
        onSelectFrontImage
    };
};
export default useImageSelector;
