import { gql } from "graphql-tag"

const emails = gql`
  type Query {
    getEmailsFooters: [EmailsFooter]
    getEmailsFooter(id: ID!): EmailsFooter
    getEmailsHeadings: [EmailsHeading]
    getEmailsHeading(id: ID!): EmailsHeading
    getEmailsTemplates: [EmailsTemplate]
    getEmailsTemplate(id: ID!): EmailsTemplate
  }
`

export default emails
