/* eslint-disable max-lines-per-function, max-statements */
import { useCallback, useEffect, useMemo } from "react";
import News from "./News";
import { mutations } from "@bluframe/mightychroma-mightytool-graphql-schema";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import useEightLeggedFreakNewsCriticsList from "hooks/useEightLeggedFreakNewsCriticsList";
import { useMutation } from "@apollo/client";
import useNewsCritics from "hooks/useNewsCritics";
import usePreviewsNewsCritics from "hooks/usePreviewsNewsCritics";
import useUser from "hooks/useUser";
const { news: { ADD_PREVIEW_NEWS_SOURCE }, eightLeggedFreak: { EIGHT_LEGGED_FREAK_NEWS_SOURCE_UPDATE } } = mutations;
const ZERO = 0;
const THREE = 3;
const usePrepareComponent = ({ actions, name, serviceName }) => {
    const { addImage } = usePreviewsNewsCritics();
    const { setIsToggleFlaggedLoading, setSelectedId, setSelectedIsPreviewLoading, setSelectedIsPreviewValid, state } = useNewsCritics();
    const { isAdmin } = useUser();
    const serviceState = useMemo(() => state[serviceName], [state, serviceName]);
    const [addPreview, { data: addPreviewData, loading: addPreviewLoading }] = useMutation(ADD_PREVIEW_NEWS_SOURCE, {
        onCompleted: (data) => {
            if (data.addPreviewNewsSource.image) {
                // Add image to image selector
                addImage({
                    serviceName,
                    url: data.addPreviewNewsSource.image
                });
            }
        }
    });
    const { refetch } = useEightLeggedFreakNewsCriticsList(serviceName);
    const [eightLeggedFreakNewsCriticsUpdate, { loading: flagLoading }] = useMutation(EIGHT_LEGGED_FREAK_NEWS_SOURCE_UPDATE);
    useEffect(() => {
        setIsToggleFlaggedLoading(flagLoading);
        // It just goes into infinite loop if we include
        // setIsToggleFlaggedLoading in the dependency array
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flagLoading]);
    useEffect(() => {
        if (serviceState.selected.url) {
            addPreview({
                variables: {
                    previewNewsSourceAddInput: {
                        overrides: serviceState.selected.overrides,
                        serviceName,
                        url: serviceState.selected.url
                    }
                }
            });
        }
    }, [
        addPreview,
        serviceName,
        serviceState.selected.overrides,
        serviceState.selected.url
    ]);
    useEffect(() => {
        if (addPreviewData) {
            setSelectedIsPreviewValid(addPreviewData.addPreviewNewsSource.isValid, serviceName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addPreviewData]);
    useEffect(() => {
        setSelectedIsPreviewLoading(addPreviewLoading, serviceName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addPreviewLoading]);
    const display = useMemo(() => {
        if (serviceState.isOnlyNew) {
            return serviceState.display.filter(({ isNew }) => isNew);
        }
        if (serviceState.isOnlyPresent) {
            return serviceState.display.filter(({ isNew }) => !isNew);
        }
        return serviceState.display;
    }, [serviceState.display, serviceState.isOnlyNew, serviceState.isOnlyPresent]);
    const filteredDisplay = useMemo(() => {
        if (serviceState.isShowFlagged) {
            return display;
        }
        return display.filter(({ isFlagged }) => !isFlagged);
    }, [display, serviceState.isShowFlagged]);
    // @ts-ignore
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const subheader = useMemo(() => {
        if (serviceState.isOnlyNew) {
            return `Total New: ${filteredDisplay.length}`;
        }
        if (serviceState.isOnlyPresent) {
            return `Total Present: ${filteredDisplay.length}`;
        }
        return `Total Available: ${filteredDisplay.length}`;
    });
    // @ts-ignore
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const title = useMemo(() => `${name} News`);
    // @ts-ignore
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const avatar = useMemo(() => name.slice(ZERO, THREE));
    const onToggleFlagged = useCallback((id, isFlagged) => () => {
        eightLeggedFreakNewsCriticsUpdate({
            onCompleted: () => {
                refetch({
                    eightLeggedFreakNewsSourcesListInput: {
                        serviceName
                    }
                });
            },
            variables: {
                eightLeggedFreakNewsSourceUpdateInput: {
                    id,
                    isFlagged: !isFlagged
                },
                serviceName
            }
        });
    }, [eightLeggedFreakNewsCriticsUpdate, refetch, serviceName]);
    const onSelect = useCallback((ev) => {
        setSelectedId(ev.currentTarget.value, serviceName);
    }, [serviceName, setSelectedId]);
    return {
        actions,
        avatar,
        display: filteredDisplay,
        flagLoading,
        isShowFlagButton: isAdmin,
        onSelect,
        onToggleFlagged,
        selected: serviceState.selected.id,
        serviceName,
        subheader,
        title
    };
};
export default prepareComponent(usePrepareComponent)(News);
