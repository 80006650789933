/* eslint-disable max-lines-per-function, no-ternary */

import { Buttons, Left, Name, Wrapper } from "./styled"
import { FormModal, Modal } from "@bluframe/blublocks"
import Button from "components/buttons/Button"
import Check from "./Check"
import { ComponentProps } from "."
import InputText from "components/inputs/Text"

const PodSource = ({
  areItemsLoading,
  isCompact,
  isCreated,
  isEditOpen,
  isLoading,
  isValid,
  itemLabel,
  label,
  onCancelOverrides,
  onClear,
  onSaveOverrides,
  onSetIsEditOpen,
  overrides,
  serviceName,
  text
}: ComponentProps) => (
  <Wrapper $isCompact={isCompact}>
    <Left $isCompact={isCompact}>
      {isCompact ? (
        <Name>{label}</Name>
      ) : (
        <InputText
          disabled
          id={serviceName}
          label={label}
          name={serviceName}
          text={text}
        />
      )}

      <Check
        disabled={areItemsLoading || !text}
        isGreen={isValid}
        isLoading={isLoading}
        label={isCompact ? undefined : "Preview Valid"}
      />
      <Check
        disabled={areItemsLoading || !text}
        isGreen={isCreated}
        isLoading={isLoading}
        label={isCompact ? undefined : `${itemLabel} Created`}
      />
    </Left>
    {isCompact ? null : (
      <Buttons>
        <Button
          disabled={areItemsLoading || isLoading || !text}
          label="Edit"
          onClick={onSetIsEditOpen(true)}
          outlined
        />
        <Button
          disabled={areItemsLoading || isLoading || !text}
          label="Clear"
          onClick={onClear}
          outlined
          secondary
        />
      </Buttons>
    )}
    <Modal isOpen={isEditOpen} onClose={onCancelOverrides}>
      <FormModal onClose={onCancelOverrides} onSubmit={onSaveOverrides}>
        {overrides}
      </FormModal>
    </Modal>
  </Wrapper>
)
export default PodSource
