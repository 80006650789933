/* eslint-disable no-ternary, max-lines-per-function */

import { ComponentProps } from "."
import IsCompact from "./IsCompact"
import { Ul } from "./styled"

const ControlCheckboxes = ({ isCompactCheckboxProps }: ComponentProps) => (
  <Ul aria-label="Control Toggles">
    {isCompactCheckboxProps ? (
      <li aria-label="Is Compact Toggle">
        <IsCompact {...isCompactCheckboxProps} />
      </li>
    ) : null}
  </Ul>
)

export default ControlCheckboxes
