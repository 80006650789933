import { NewsSourceOverrides } from "@bluframe/mightychroma-mightytool-frontend-types"
import Overrides from "./Overrides"
import { RefObject } from "react"

export interface Props {
  inputRefs: {
    name: RefObject<HTMLInputElement>
    text: RefObject<HTMLTextAreaElement>
  }
  overrides: NewsSourceOverrides
}

export interface ComponentProps extends Props {}

export default Overrides
