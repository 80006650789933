import { gql } from "graphql-tag"

const mutation = gql`
  type Mutation {
    addNewsletter(
      newsletterAddInput: NewsletterAddInput!
    ): NewsletterAddResponse!

    deleteNewsletter(id: ID!): NewsletterDeleteResponse!

    updateNewsletter(
      id: ID!
      newsletterUpdateInput: NewsletterUpdateInput!
    ): NewsletterUpdateResponse!
  }
`

export default mutation
