/* eslint-disable max-lines-per-function */

import { InputText, TextArea } from "@bluframe/blublocks"
import { ComponentProps } from "."
import CriticsOverrides from "components/Critics/PodSource/Overrides"

const Overrides = ({ inputRefs, overrides }: ComponentProps) => (
  <CriticsOverrides>
    <InputText
      defaultValue={overrides.name ?? ""}
      inputId="name"
      label="Name"
      name="name"
      ref={inputRefs.name}
    />
    <TextArea
      defaultValue={overrides.text ?? ""}
      inputId="text"
      label="Text"
      name="text"
      ref={inputRefs.text}
    />
  </CriticsOverrides>
)
export default Overrides
