import gql from "graphql-tag"
import news from "fragments/news"

const GET_DRAFTS_NEWS_WAVE = gql`
  ${news}

  query getDraftsNewsWave($cursor: String) {
    draftsNewsWave(cursor: $cursor) {
      cursor
      hasNextData
      hasPrevData
      news {
        ...News
      }
      prevCursor
    }
  }
`
export default GET_DRAFTS_NEWS_WAVE
