/* eslint-disable no-ternary, max-lines-per-function */

import {
  FormEventHandler,
  RefObject,
  useCallback,
  useRef,
  useState
} from "react"
import {
  ReviewOverrides,
  ReviewsServiceName
} from "@bluframe/mightychroma-mightytool-frontend-types"
import PodSource from "./PodSource"
import prepareComponent from "@bluframe/grapple/prepareComponent"

export interface Props {
  areReviewsLoading: boolean
  isCompact: boolean
  isCreated: boolean
  isLoading: boolean
  isValid: boolean
  label: string
  // eslint-disable-next-line no-unused-vars
  onClear: (serviceName: ReviewsServiceName) => () => void
  onSetOverrides: (
    // eslint-disable-next-line no-unused-vars
    serviceName: ReviewsServiceName
    // eslint-disable-next-line no-unused-vars
  ) => (overrides: ReviewOverrides) => void
  overrides?: ReviewOverrides
  serviceName: ReviewsServiceName
  text: string
}

export interface ComponentProps
  extends Omit<Props, "onClear" | "onSetOverrides"> {
  inputRefs: {
    [key: string]: {
      quote: RefObject<HTMLTextAreaElement>
      score: RefObject<HTMLInputElement>
      section: RefObject<HTMLTextAreaElement>
    }
  }
  onCancel: () => void
  onClear: () => void
  onSave: FormEventHandler
  // eslint-disable-next-line no-unused-vars
  onSetReleaseDate: (releaseDate: string) => void
  overrides: ReviewOverrides
}

const DEFAULT_OVERRIDES: ReviewOverrides = {}

const usePrepareComponent = ({
  onClear: onClearProps,
  onSetOverrides,
  serviceName,
  ...props
}: Props): ComponentProps => {
  const [newOverrides, setNewOverrides] =
    useState<ReviewOverrides>(DEFAULT_OVERRIDES)

  const inputRefs = {
    audio: {
      quote: useRef<HTMLTextAreaElement>(null),
      score: useRef<HTMLInputElement>(null),
      section: useRef<HTMLTextAreaElement>(null)
    },
    extra: {
      quote: useRef<HTMLTextAreaElement>(null),
      score: useRef<HTMLInputElement>(null),
      section: useRef<HTMLTextAreaElement>(null)
    },
    movie: {
      quote: useRef<HTMLTextAreaElement>(null),
      score: useRef<HTMLInputElement>(null),
      section: useRef<HTMLTextAreaElement>(null)
    },
    total: {
      quote: useRef<HTMLTextAreaElement>(null),
      score: useRef<HTMLInputElement>(null),
      section: useRef<HTMLTextAreaElement>(null)
    },
    video: {
      quote: useRef<HTMLTextAreaElement>(null),
      score: useRef<HTMLInputElement>(null),
      section: useRef<HTMLTextAreaElement>(null)
    }
  }

  const onSetReleaseDate = (releaseDate: string) => {
    setNewOverrides((prev) => ({ ...prev, releaseDate }))
  }

  const onCancel = () => {
    setNewOverrides(DEFAULT_OVERRIDES)
  }

  const onSave: FormEventHandler = (event) => {
    event.preventDefault()

    onSetOverrides(serviceName)({
      audio: {
        // @ts-ignore
        quote: inputRefs.audio.quote.current?.value || undefined,
        // @ts-ignore
        score: inputRefs.audio.score.current?.value
          ? // eslint-disable-next-line line-comment-position, no-inline-comments
            // @ts-ignore
            Number(inputRefs.audio.score.current?.value)
          : undefined,
        // @ts-ignore
        section: inputRefs.audio.section.current?.value || undefined
      },
      extra: {
        // @ts-ignore
        quote: inputRefs.extra.quote.current?.value || undefined,
        // @ts-ignore
        score: inputRefs.extra.score.current?.value
          ? // eslint-disable-next-line line-comment-position, no-inline-comments
            // @ts-ignore
            Number(inputRefs.extra.score.current?.value)
          : undefined,
        // @ts-ignore
        section: inputRefs.extra.section.current?.value || undefined
      },
      movie: {
        // @ts-ignore
        quote: inputRefs.movie.quote.current?.value || undefined,
        // @ts-ignore
        score: inputRefs.movie.score.current?.value
          ? // eslint-disable-next-line line-comment-position, no-inline-comments
            // @ts-ignore
            Number(inputRefs.movie.score.current?.value)
          : undefined,
        // @ts-ignore
        section: inputRefs.movie.section.current?.value || undefined
      },
      releaseDate: newOverrides.releaseDate,
      total: {
        // @ts-ignore
        quote: inputRefs.total.quote.current?.value || undefined,
        // @ts-ignore
        score: inputRefs.total.score.current?.value
          ? // eslint-disable-next-line line-comment-position, no-inline-comments
            // @ts-ignore
            Number(inputRefs.total.score.current?.value)
          : undefined,
        // @ts-ignore
        section: inputRefs.total.section.current?.value || undefined
      },
      video: {
        // @ts-ignore
        quote: inputRefs.video.quote.current?.value || undefined,
        // @ts-ignore
        score: inputRefs.video.score.current?.value
          ? // eslint-disable-next-line line-comment-position, no-inline-comments
            // @ts-ignore
            Number(inputRefs.video.score.current?.value)
          : undefined,
        // @ts-ignore
        section: inputRefs.video.section.current?.value || undefined
      }
    })
  }

  const onClear = useCallback(() => {
    const onClearServiceName = onClearProps(serviceName)

    onClearServiceName()
  }, [onClearProps, serviceName])

  return {
    ...props,
    inputRefs,
    onCancel,
    onClear,
    onSave,
    onSetReleaseDate,
    overrides: newOverrides,
    serviceName
  }
}

export default prepareComponent(usePrepareComponent)(PodSource)
