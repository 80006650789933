import List from "./List";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import { useCallback } from "react";
import useEightLeggedFreakList from "hooks/useEightLeggedFreakList";
import useEightLeggedFreakNewsCriticsList from "hooks/useEightLeggedFreakNewsCriticsList";
const usePrepareComponent = ({ serviceName, serviceType }) => {
    const { getList: getReviewsList, isSkipGetList, loading: reviewsLoading } = useEightLeggedFreakList(serviceName);
    const { getNewsCriticsList, loading: newsCriticsLoading } = useEightLeggedFreakNewsCriticsList(serviceName);
    const onList = useCallback(() => {
        if (serviceType === "reviews" && !isSkipGetList) {
            getReviewsList();
        }
        if (serviceType === "news") {
            getNewsCriticsList();
        }
    }, [getNewsCriticsList, getReviewsList, isSkipGetList, serviceType]);
    return {
        isDisabled: isSkipGetList || reviewsLoading || newsCriticsLoading,
        loading: reviewsLoading || newsCriticsLoading,
        onList
    };
};
export default prepareComponent(usePrepareComponent)(List);
