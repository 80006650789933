/* eslint-disable max-lines-per-function */

import { Ul, Wrapper } from "./styled"
import { ComponentProps } from "."
import PodSource from "components/News/PodSource"

const HeaderPods = ({
  areNewsLoading,
  avNirvana,
  bluRay,
  hidefDigest,
  news,
  isCompact,
  left,
  onClear,
  onSetOverrides,
  whySoBlu
}: ComponentProps) => (
  <Wrapper>
    {left}
    <Ul $isCompact={isCompact} aria-label="Header Pods">
      <li aria-label="Blu-ray.com">
        <PodSource
          areNewsLoading={areNewsLoading}
          isCompact={isCompact}
          isCreated={Boolean(news.bluRayId)}
          isLoading={bluRay.isPreviewLoading}
          isValid={bluRay.isPreviewValid}
          label="Blu-ray.com"
          onClear={onClear}
          onSetOverrides={onSetOverrides}
          overrides={bluRay.overrides}
          serviceName="bluRay"
          text={bluRay.url}
        />
      </li>

      <li aria-label="AV Nirvana">
        <PodSource
          areNewsLoading={areNewsLoading}
          isCompact={isCompact}
          isCreated={Boolean(news.avNirvanaId)}
          isLoading={avNirvana.isPreviewLoading}
          isValid={avNirvana.isPreviewValid}
          label="AV Nirvana"
          onClear={onClear}
          onSetOverrides={onSetOverrides}
          overrides={avNirvana.overrides}
          serviceName="avNirvana"
          text={avNirvana.url}
        />
      </li>

      <li aria-label="Hi-Def Digest">
        <PodSource
          areNewsLoading={areNewsLoading}
          isCompact={isCompact}
          isCreated={Boolean(news.hidefDigestId)}
          isLoading={hidefDigest.isPreviewLoading}
          isValid={hidefDigest.isPreviewValid}
          label="Hi-Def Digest"
          onClear={onClear}
          onSetOverrides={onSetOverrides}
          overrides={hidefDigest.overrides}
          serviceName="hidefDigest"
          text={hidefDigest.url}
        />
      </li>

      <li aria-label="Why So Blu?">
        <PodSource
          areNewsLoading={areNewsLoading}
          isCompact={isCompact}
          isCreated={Boolean(news.whySoBluId)}
          isLoading={whySoBlu.isPreviewLoading}
          isValid={whySoBlu.isPreviewValid}
          label="Why So Blu?"
          onClear={onClear}
          onSetOverrides={onSetOverrides}
          overrides={whySoBlu.overrides}
          serviceName="whySoBlu"
          text={whySoBlu.url}
        />
      </li>
    </Ul>
  </Wrapper>
)

export default HeaderPods
