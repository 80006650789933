import gql from "graphql-tag"

const character = gql`
  fragment Character on Character {
    bio
    email
    firstName
    id
    lastName
    writingInstructions {
      conclusion
      expectedOutput
      intro
      objective
      styleToAdopt
      outputAndStyleReminderFrequency
      writingSamples {
        after
        before
        samples
      }
    }
  }
`

export default character
