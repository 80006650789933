/* eslint-disable no-ternary, react/jsx-indent */

import { List, Wrapper } from "./styled"
import { ComponentProps } from "."
import { InputText } from "@bluframe/blublocks"
import Suggestion from "./Suggestion"

const Autosuggest = ({
  disabled,
  getItemProps,
  getMenuProps,
  inputProps: { id, ref, ...inputProps },
  isOpen,
  label,
  onKeyDown,
  onSuggestionClick,
  suggestions
}: ComponentProps) => (
  <Wrapper>
    <InputText
      disabled={disabled}
      inputId={id}
      inputProps={inputProps}
      label={label}
      name="search"
      onKeyDown={onKeyDown}
      ref={ref}
    />
    <List {...getMenuProps()}>
      {isOpen
        ? suggestions.map((suggestion, index) => (
            <Suggestion
              item={suggestion}
              itemProps={getItemProps({ index, item: suggestion })}
              key={suggestion.id}
              onSuggestionClick={onSuggestionClick}
            />
          ))
        : null}
    </List>
  </Wrapper>
)

export default Autosuggest
