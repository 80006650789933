import {
  NewsAddInput,
  NewsServiceName,
  NewsSourceOverrides,
  SelectedNewsSource
} from "@bluframe/mightychroma-mightytool-frontend-types"
import HeaderPods from "./HeaderPods"

export interface Props {
  areNewsLoading: boolean
  avNirvana: SelectedNewsSource
  bluRay: SelectedNewsSource
  hidefDigest: SelectedNewsSource
  news: NewsAddInput
  isCompact: boolean
  left: React.ReactNode
  // eslint-disable-next-line no-unused-vars
  onClear: (serviceName: NewsServiceName) => () => void
  onSetOverrides: (
    // eslint-disable-next-line no-unused-vars
    serviceName: NewsServiceName
    // eslint-disable-next-line no-unused-vars
  ) => (overrides: NewsSourceOverrides) => void
  whySoBlu: SelectedNewsSource
}

export interface ComponentProps extends Props {}

export default HeaderPods
