/* eslint-disable max-lines-per-function, max-statements */
import { useCallback, useEffect } from "react";
import useCreateNewsCritics from "hooks/useCreateNewsCritics";
import useNewsCritics from "hooks/useNewsCritics";
import usePreviewsNewsCritics from "hooks/usePreviewsNewsCritics";
const useAddEditNews = () => {
    const { resetImages } = usePreviewsNewsCritics();
    const { clearAll, setSelectedIsNewsCriticsCreated, state: { news } } = useNewsCritics();
    const { avNirvanaData, bluRayData, hidefDigestData, whySoBluData } = useCreateNewsCritics();
    useEffect(() => {
        if (avNirvanaData?.addNewsSource && !news.avNirvanaId) {
            const { addNewsSource: { id, serviceName } } = avNirvanaData;
            setSelectedIsNewsCriticsCreated(id, serviceName);
        }
    }, [avNirvanaData, news, setSelectedIsNewsCriticsCreated]);
    useEffect(() => {
        if (bluRayData?.addNewsSource && !news.bluRayId) {
            const { addNewsSource: { id, serviceName } } = bluRayData;
            setSelectedIsNewsCriticsCreated(id, serviceName);
        }
    }, [bluRayData, news, setSelectedIsNewsCriticsCreated]);
    useEffect(() => {
        if (hidefDigestData?.addNewsSource && !news.hidefDigestId) {
            const { addNewsSource: { id, serviceName } } = hidefDigestData;
            setSelectedIsNewsCriticsCreated(id, serviceName);
        }
    }, [hidefDigestData, news, setSelectedIsNewsCriticsCreated]);
    useEffect(() => {
        if (whySoBluData?.addNewsSource && !news.whySoBluId) {
            const { addNewsSource: { id, serviceName } } = whySoBluData;
            setSelectedIsNewsCriticsCreated(id, serviceName);
        }
    }, [whySoBluData, news, setSelectedIsNewsCriticsCreated]);
    const onClearAll = useCallback(() => {
        // Reset all images
        resetImages();
        // Clear all reviews
        clearAll();
    }, [clearAll, resetImages]);
    return {
        onClearAll
    };
};
export default useAddEditNews;
