/* eslint-disable max-lines-per-function */

import { Actions, Wrapper } from "./styled"
import CardActions from "@material-ui/core/CardActions"
import { ComponentProps } from "."
import Content from "./Content"
import MUICard from "@material-ui/core/Card"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  cardActions: {
    padding: 20
  }
})

const Card = ({
  actions,
  avatar,
  display,
  flagLoading,
  isShowFlagButton,
  label,
  name,
  onSelect,
  onToggleFlagged,
  selected,
  serviceName,
  subheader,
  title
}: ComponentProps) => {
  const classes = useStyles()

  return (
    <Wrapper>
      <MUICard>
        <Content
          avatar={avatar}
          display={display}
          flagLoading={flagLoading}
          isShowFlagButton={isShowFlagButton}
          label={label}
          name={name}
          onSelect={onSelect}
          onToggleFlagged={onToggleFlagged}
          selected={selected}
          serviceName={serviceName}
          subheader={subheader}
          title={title}
        />
        <CardActions className={classes.cardActions}>
          <Actions>{actions}</Actions>
        </CardActions>
      </MUICard>
    </Wrapper>
  )
}

export default Card
