import SocialLinks from "./SocialLinks"

export interface Props {
  blueSky?: string
  className?: string
  facebook?: string
  instagram?: string
  isBig?: boolean
  isShowLabels?: boolean
  reddit?: string
  redditSubreddit?: string
  threads?: string
  twitterX?: string
  website?: string
  youtube?: string
}

export interface ComponentProps extends Props {}

export default SocialLinks
