import gql from "graphql-tag"

const GET_NEWS_LIST = gql`
  query getNewsList {
    getNewsList {
      id
    }
  }
`

export default GET_NEWS_LIST
