import gql from "graphql-tag"

const ADD_REVIEW = gql`
  mutation addReview($reviewAddInput: ReviewAddInput!) {
    addReview(reviewAddInput: $reviewAddInput) {
      id
      serviceName
    }
  }
`
export default ADD_REVIEW
