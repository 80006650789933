/* eslint-disable max-lines-per-function, no-empty-function, max-lines */
import reducer, { DEFAULT_NEWS, RESET_SELECTED_NEWS_CRITICS, RESET_SELECTED_NEWS_CRITICS_LIST, SET_AUTHOR_ID, SET_IMAGE_SERVICE_NAME, SET_IS_ONLY_NEW, SET_IS_ONLY_PRESENT, SET_IS_SHOW_FLAGGED, SET_IS_TOGGLE_FLAGGED_LOADING, SET_LIST, SET_NAME, SET_NEXT_CURSOR, SET_OVERRIDES, SET_SELECTED_ID, SET_SELECTED_IS_NEWS_CRITICS_CREATED, SET_SELECTED_IS_PREVIEW_LOADING, SET_SELECTED_IS_PREVIEW_VALID, SET_SELECTED_NEWS, initialState } from "./reducer";
import { useCallback, useReducer } from "react";
export { DEFAULT_NEWS };
export const initialContext = {
    clear: () => { },
    clearAll: () => { },
    resetSelectedNewsCritics: () => { },
    setAuthorId: () => { },
    setImageServiceName: () => { },
    setIsOnlyNew: () => { },
    setIsOnlyPresent: () => { },
    setIsShowFlagged: () => { },
    setIsToggleFlaggedLoading: () => { },
    setList: () => { },
    setName: () => { },
    setNextCursor: () => { },
    setOverrides: () => { },
    setSelectedId: () => { },
    setSelectedIsNewsCriticsCreated: () => { },
    setSelectedIsPreviewLoading: () => { },
    setSelectedIsPreviewValid: () => { },
    setSelectedNewsCritics: () => { },
    state: initialState
};
const useNewsCriticsContext = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const resetSelectedNewsCritics = useCallback(() => {
        dispatch({ type: RESET_SELECTED_NEWS_CRITICS_LIST });
    }, [dispatch]);
    const setNextCursor = useCallback((nextCursor, service) => {
        dispatch({
            payload: { nextCursor, service },
            type: SET_NEXT_CURSOR
        });
    }, [dispatch]);
    const clear = useCallback((serviceName) => {
        dispatch({
            payload: { service: serviceName },
            type: RESET_SELECTED_NEWS_CRITICS
        });
    }, [dispatch]);
    const clearAll = useCallback(() => {
        resetSelectedNewsCritics();
    }, [resetSelectedNewsCritics]);
    const setAuthorId = useCallback((authorId) => {
        dispatch({
            payload: { authorId },
            type: SET_AUTHOR_ID
        });
    }, [dispatch]);
    const setImageServiceName = useCallback((imageServiceName) => {
        dispatch({
            payload: { imageServiceName },
            type: SET_IMAGE_SERVICE_NAME
        });
    }, [dispatch]);
    return {
        clear,
        clearAll,
        resetSelectedNewsCritics,
        setAuthorId,
        setImageServiceName,
        setIsOnlyNew: useCallback((isOnlyNew, serviceName) => {
            dispatch({
                payload: { isOnlyNew, service: serviceName },
                type: SET_IS_ONLY_NEW
            });
        }, [dispatch]),
        setIsOnlyPresent: useCallback((isOnlyPresent, serviceName) => {
            dispatch({
                payload: { isOnlyPresent, service: serviceName },
                type: SET_IS_ONLY_PRESENT
            });
        }, [dispatch]),
        setIsShowFlagged: useCallback((isShowFlagged, serviceName) => {
            dispatch({
                payload: { isShowFlagged, service: serviceName },
                type: SET_IS_SHOW_FLAGGED
            });
        }, [dispatch]),
        setIsToggleFlaggedLoading: useCallback((isToggleFlaggedLoading) => {
            dispatch({
                payload: { isToggleFlaggedLoading },
                type: SET_IS_TOGGLE_FLAGGED_LOADING
            });
        }, [dispatch]),
        setList: useCallback((list, serviceName) => {
            dispatch({
                payload: { list, service: serviceName },
                type: SET_LIST
            });
        }, [dispatch]),
        setName: useCallback((name) => {
            dispatch({ payload: { name }, type: SET_NAME });
        }, [dispatch]),
        setNextCursor,
        setOverrides: useCallback((overrides, serviceName) => {
            dispatch({
                payload: { overrides, serviceName },
                type: SET_OVERRIDES
            });
        }, [dispatch]),
        setSelectedId: useCallback((selected, serviceName) => {
            dispatch({
                payload: { selected, service: serviceName },
                type: SET_SELECTED_ID
            });
        }, [dispatch]),
        setSelectedIsNewsCriticsCreated: useCallback((id, serviceName) => {
            dispatch({
                payload: { id, service: serviceName },
                type: SET_SELECTED_IS_NEWS_CRITICS_CREATED
            });
        }, [dispatch]),
        setSelectedIsPreviewLoading: useCallback((isPreviewLoading, serviceName) => {
            dispatch({
                payload: { isPreviewLoading, service: serviceName },
                type: SET_SELECTED_IS_PREVIEW_LOADING
            });
        }, [dispatch]),
        setSelectedIsPreviewValid: useCallback((isPreviewValid, serviceName) => {
            dispatch({
                payload: { isPreviewValid, service: serviceName },
                type: SET_SELECTED_IS_PREVIEW_VALID
            });
        }, [dispatch]),
        setSelectedNewsCritics: useCallback(({ id, isPublished, serviceName, title, url }) => {
            dispatch({
                payload: { id, isPublished, service: serviceName, title, url },
                type: SET_SELECTED_NEWS
            });
        }, [dispatch]),
        state
    };
};
export default useNewsCriticsContext;
