import { Props as AutosuggestProps } from "components/Autosuggest"
import EditActions from "./EditActions"
import { HomeVideoReviewSearchItem } from "@bluframe/mightychroma-mightytool-frontend-types"

export interface Props {
  homeVideoReviewTitles: HomeVideoReviewSearchItem[]
  isUpdateHomeVideoReviewEnabled: boolean
  label: string
  loading: boolean
  onClearAll: () => void
  onGetSuggestions: AutosuggestProps["onGetSuggestions"]
  // eslint-disable-next-line no-unused-vars
  onSelectHomeVideoReviewId: (id: string, name: string) => void
  onUpdateHomeVideoReview: () => void
  selectedHomeVideoReviewId: string
}

export interface ComponentProps extends Props {}

export default EditActions
