import { gql } from "graphql-tag"

const eightLeggedFreak = gql`
  type Mutation {
    eightLeggedFreakNewsSourcesGather(
      eightLeggedFreakNewsSourcesGatherInput: EightLeggedFreakNewsSourcesGatherInput!
    ): EightLeggedFreakNewsSourcesGather

    eightLeggedFreakNewsSourceUpdate(
      eightLeggedFreakNewsSourceUpdateInput: EightLeggedFreakNewsSourceInput!
      serviceName: String!
    ): EightLeggedFreakNewsSourceResponse

    eightLeggedFreakGather(
      eightLeggedFreakGatherInput: EightLeggedFreakGatherInput!
    ): EightLeggedFreakGather

    eightLeggedFreakReviewUpdate(
      review: EightLeggedFreakReviewInput!
      serviceName: String!
    ): EightLeggedFreakReviewResponse
  }
`

export default eightLeggedFreak
