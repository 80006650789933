/* eslint-disable max-lines-per-function, max-statements */
import pick from "ramda/src/pick";
import { queries } from "@bluframe/mightychroma-mightytool-graphql-schema";
import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import useNewsCritics from "hooks/useNewsCritics";
import usePrevious from "@bluframe/grapple/usePrevious";
const { eightLeggedFreak: { GET_EIGHT_LEGGED_FREAK_NEWS_SOURCES_LIST } } = queries;
const useEightLeggedFreakNewsCriticsList = (serviceName) => {
    const { setList, setNextCursor, state } = useNewsCritics();
    const stateByServiceName = state[serviceName];
    const [getNewsCriticsList, { data, fetchMore, loading, refetch }] = useLazyQuery(GET_EIGHT_LEGGED_FREAK_NEWS_SOURCES_LIST, {
        fetchPolicy: "network-only",
        nextFetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        onCompleted: ({ eightLeggedFreakNewsSourcesList }) => {
            setList(eightLeggedFreakNewsSourcesList.links.map((item) => ({
                ...pick(["id", "isFlagged", "isNew", "name", "url"])(item),
                serviceName
            })), serviceName);
            setNextCursor(eightLeggedFreakNewsSourcesList.nextCursor ?? null, serviceName);
        },
        variables: {
            eightLeggedFreakNewsSourcesListInput: {
                serviceName
            }
        }
    });
    const selectedId = stateByServiceName?.selected.id;
    const previousSelectedId = usePrevious(stateByServiceName?.selected.id);
    // Refetch on reset
    useEffect(() => {
        if (!selectedId && selectedId !== previousSelectedId && data) {
            refetch();
        }
    }, [
        data,
        previousSelectedId,
        refetch,
        selectedId,
        // This is important to differentiate between services
        serviceName
    ]);
    return {
        fetchMore,
        getNewsCriticsList,
        loading,
        nextCursor: data?.eightLeggedFreakNewsSourcesList?.nextCursor,
        refetch
    };
};
export default useEightLeggedFreakNewsCriticsList;
