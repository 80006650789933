import ADD_NEWS from "./addNews"
import ADD_NEWS_SOURCE from "./addNewsSource"
import ADD_PREVIEW_NEWS_SOURCE from "./addPreviewNewsSource"
import PUBLISH_DRAFT_NEWS from "./publishDraftNews"
import UPDATE_NEWS from "./updateNews"
import UPDATE_NEWS_SOURCE from "./updateNewsSource"
import UPDATE_NEWS_SOURCES_FROM_URL from "./updateNewsSourcesFromUrl"

export default {
  ADD_NEWS,
  ADD_NEWS_SOURCE,
  ADD_PREVIEW_NEWS_SOURCE,
  PUBLISH_DRAFT_NEWS,
  UPDATE_NEWS,
  UPDATE_NEWS_SOURCE,
  UPDATE_NEWS_SOURCES_FROM_URL
}
