import gql from "graphql-tag"

const ADD_PREVIEW_NEWS_SOURCE = gql`
  mutation addPreviewNewsSource(
    $previewNewsSourceAddInput: PreviewNewsSourceAddInput!
  ) {
    addPreviewNewsSource(
      previewNewsSourceAddInput: $previewNewsSourceAddInput
    ) {
      errorFields
      image
      isValid
      name
      text
      url
    }
  }
`

export default ADD_PREVIEW_NEWS_SOURCE
