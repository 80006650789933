import { ComponentProps } from "."
import CriticsAddActions from "components/Critics/AddActions"

const AddActions = ({
  contentAuthors,
  imageSelector,
  isAddNewsEnabled,
  isAdmin,
  label,
  loading,
  name,
  onAddNews,
  onClearAll,
  onContentAuthorSelect,
  onNameChange
}: ComponentProps) => (
  <CriticsAddActions
    contentAuthors={contentAuthors}
    imageSelector={imageSelector}
    isAddItemEnabled={isAddNewsEnabled}
    isAdmin={isAdmin}
    label={label}
    loading={loading}
    name={name}
    onAddItem={onAddNews}
    onClearAll={onClearAll}
    onContentAuthorSelect={onContentAuthorSelect}
    onNameChange={onNameChange}
    serviceType="news"
  />
)

export default AddActions
