import ControlCheckboxes from "./ControlCheckboxes"
import { Props as IsCompactCheckboxProps } from "./IsCompact"

export interface Props {
  isCompactCheckboxProps?: IsCompactCheckboxProps
}

export interface ComponentProps extends Props {}

export default ControlCheckboxes
