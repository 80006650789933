import character from "fragments/character"
import gql from "graphql-tag"

const GET_CHARACTER = gql`
  ${character}

  query getCharacter($id: ID!) {
    getCharacter(id: $id) {
      ...Character
    }
  }
`
export default GET_CHARACTER
