import { Props as AutosuggestProps } from "components/Autosuggest"
import { ChangeEvent } from "react"
import { HomeVideoReviewSearchItem } from "@bluframe/mightychroma-mightytool-frontend-types"
import { OptionValue } from "@bluframe/blublocks"
import Roundup from "./Roundup"
import { Props as SelectProps } from "components/inputs/Select"

export interface Props {
  characters: SelectProps["options"]
  characterId: OptionValue | null
  homeVideoReviewIds: string[]
  homeVideoReviewTitles: HomeVideoReviewSearchItem[]
  isAutoSuggestLoading: boolean
  isCharactersLoading: boolean
  isGenerateBlogRoundupEnabled: boolean
  isGenerateBlogRoundupLoading: boolean
  onChangeCharacterId: SelectProps["onChange"]
  // eslint-disable-next-line no-unused-vars
  onChangeTheme: (event: ChangeEvent<HTMLInputElement>) => void
  onClickGenerate: () => void
  onGetSuggestions: AutosuggestProps["onGetSuggestions"]
  // eslint-disable-next-line no-unused-vars
  onSelectHomeVideoReviewId: (index: number) => (
    // eslint-disable-next-line no-unused-vars
    id: string,
    // eslint-disable-next-line no-unused-vars
    name: string
  ) => void
  reviewNames: string[]
  theme: string
}

export interface ComponentProps extends Props {}

export default Roundup
