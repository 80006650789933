import {
  H1 as BluBlocksH1,
  H2 as BluBlocksH2,
  Paragraph as BluBlocksParagraph
} from "@bluframe/blublocks"
import styled from "styled-components"

export const Wrapper = styled.div`
  display: grid;
  padding: 20px 40px;
  row-gap: 40px;
`

export const Top = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr auto;
`

export const Main = styled.div`
  column-gap: 100px;
  display: grid;
  grid-template-columns: 1fr 1.5fr;
`

export const Row = styled.div`
  display: grid;
  height: 50px;
`

export const Left = styled.div`
  display: grid;
  row-gap: 20px;
`

export const Right = styled.div`
  display: grid;
  row-gap: 20px;
`

export const H1 = styled(BluBlocksH1)`
  font-size: 24px;
`

export const H2 = styled(BluBlocksH2)`
  font-size: 20px;
`

export const Paragraph = styled(BluBlocksParagraph)`
  font-size: 18px;
`
