/* eslint-disable max-lines-per-function */

import { Ul, Wrapper } from "./styled"
import { ComponentProps } from "."
import PodSource from "components/HomeVideoReviews/PodSource"

const HeaderPods = ({
  areReviewsLoading,
  avForums,
  avNirvana,
  avsForum,
  bluRay,
  bluRayAuthority,
  digitalBits,
  doBlu,
  hidefDigest,
  homeTheaterForum,
  homeVideoReview,
  isCompact,
  left,
  onClear,
  onSetOverrides,
  theaterByte,
  whySoBlu
}: ComponentProps) => (
  <Wrapper>
    {left}
    <Ul $isCompact={isCompact} aria-label="Header Pods">
      <li aria-label="Blu-ray.com">
        <PodSource
          areReviewsLoading={areReviewsLoading}
          isCompact={isCompact}
          isCreated={Boolean(homeVideoReview.bluRayId)}
          isLoading={bluRay.isPreviewLoading}
          isValid={bluRay.isPreviewValid}
          label="Blu-ray.com"
          onClear={onClear}
          onSetOverrides={onSetOverrides}
          overrides={bluRay.overrides}
          serviceName="bluRay"
          text={bluRay.url}
        />
      </li>

      <li aria-label="AV Forums">
        <PodSource
          areReviewsLoading={areReviewsLoading}
          isCompact={isCompact}
          isCreated={Boolean(homeVideoReview.avForumsId)}
          isLoading={avForums.isPreviewLoading}
          isValid={avForums.isPreviewValid}
          label="AV Forums"
          onClear={onClear}
          onSetOverrides={onSetOverrides}
          overrides={avForums.overrides}
          serviceName="avForums"
          text={avForums.url}
        />
      </li>

      <li aria-label="AV Nirvana">
        <PodSource
          areReviewsLoading={areReviewsLoading}
          isCompact={isCompact}
          isCreated={Boolean(homeVideoReview.avNirvanaId)}
          isLoading={avNirvana.isPreviewLoading}
          isValid={avNirvana.isPreviewValid}
          label="AV Nirvana"
          onClear={onClear}
          onSetOverrides={onSetOverrides}
          overrides={avNirvana.overrides}
          serviceName="avNirvana"
          text={avNirvana.url}
        />
      </li>

      <li aria-label="AVS Forum">
        <PodSource
          areReviewsLoading={areReviewsLoading}
          isCompact={isCompact}
          isCreated={Boolean(homeVideoReview.avsForumId)}
          isLoading={avsForum.isPreviewLoading}
          isValid={avsForum.isPreviewValid}
          label="AVS Forum"
          onClear={onClear}
          onSetOverrides={onSetOverrides}
          overrides={avsForum.overrides}
          serviceName="avsForum"
          text={avsForum.url}
        />
      </li>

      <li aria-label="Blu-ray Authority">
        <PodSource
          areReviewsLoading={areReviewsLoading}
          isCompact={isCompact}
          isCreated={Boolean(homeVideoReview.bluRayAuthorityId)}
          isLoading={bluRayAuthority.isPreviewLoading}
          isValid={bluRayAuthority.isPreviewValid}
          label="Blu-ray Authority"
          onClear={onClear}
          onSetOverrides={onSetOverrides}
          overrides={bluRayAuthority.overrides}
          serviceName="bluRayAuthority"
          text={bluRayAuthority.url}
        />
      </li>

      <li aria-label="Digital Bits">
        <PodSource
          areReviewsLoading={areReviewsLoading}
          isCompact={isCompact}
          isCreated={Boolean(homeVideoReview.digitalBitsId)}
          isLoading={digitalBits.isPreviewLoading}
          isValid={digitalBits.isPreviewValid}
          label="Digital Bits"
          onClear={onClear}
          onSetOverrides={onSetOverrides}
          overrides={digitalBits.overrides}
          serviceName="digitalBits"
          text={digitalBits.url}
        />
      </li>

      <li aria-label="Do Blu">
        <PodSource
          areReviewsLoading={areReviewsLoading}
          isCompact={isCompact}
          isCreated={Boolean(homeVideoReview.doBluId)}
          isLoading={doBlu.isPreviewLoading}
          isValid={doBlu.isPreviewValid}
          label="Do Blu"
          onClear={onClear}
          onSetOverrides={onSetOverrides}
          overrides={doBlu.overrides}
          serviceName="doBlu"
          text={doBlu.url}
        />
      </li>

      <li aria-label="Hi-Def Digest">
        <PodSource
          areReviewsLoading={areReviewsLoading}
          isCompact={isCompact}
          isCreated={Boolean(homeVideoReview.hidefDigestId)}
          isLoading={hidefDigest.isPreviewLoading}
          isValid={hidefDigest.isPreviewValid}
          label="Hi-Def Digest"
          onClear={onClear}
          onSetOverrides={onSetOverrides}
          overrides={hidefDigest.overrides}
          serviceName="hidefDigest"
          text={hidefDigest.url}
        />
      </li>

      <li aria-label="Home Theater Forum">
        <PodSource
          areReviewsLoading={areReviewsLoading}
          isCompact={isCompact}
          isCreated={Boolean(homeVideoReview.homeTheaterForumId)}
          isLoading={homeTheaterForum.isPreviewLoading}
          isValid={homeTheaterForum.isPreviewValid}
          label="HT Forum"
          onClear={onClear}
          onSetOverrides={onSetOverrides}
          overrides={homeTheaterForum.overrides}
          serviceName="homeTheaterForum"
          text={homeTheaterForum.url}
        />
      </li>

      <li aria-label="Theater Byte">
        <PodSource
          areReviewsLoading={areReviewsLoading}
          isCompact={isCompact}
          isCreated={Boolean(homeVideoReview.theaterByteId)}
          isLoading={theaterByte.isPreviewLoading}
          isValid={theaterByte.isPreviewValid}
          label="Theater Byte"
          onClear={onClear}
          onSetOverrides={onSetOverrides}
          overrides={theaterByte.overrides}
          serviceName="theaterByte"
          text={theaterByte.url}
        />
      </li>

      <li aria-label="Why So Blu?">
        <PodSource
          areReviewsLoading={areReviewsLoading}
          isCompact={isCompact}
          isCreated={Boolean(homeVideoReview.whySoBluId)}
          isLoading={whySoBlu.isPreviewLoading}
          isValid={whySoBlu.isPreviewValid}
          label="Why So Blu?"
          onClear={onClear}
          onSetOverrides={onSetOverrides}
          overrides={whySoBlu.overrides}
          serviceName="whySoBlu"
          text={whySoBlu.url}
        />
      </li>
    </Ul>
  </Wrapper>
)

export default HeaderPods
