import gql from "graphql-tag"

const EIGHT_LEGGED_FREAK_GATHER = gql`
  mutation eightLeggedFreakGather(
    $eightLeggedFreakGatherInput: EightLeggedFreakGatherInput!
  ) {
    eightLeggedFreakGather(
      eightLeggedFreakGatherInput: $eightLeggedFreakGatherInput
    ) {
      status
    }
  }
`
export default EIGHT_LEGGED_FREAK_GATHER
