/* eslint-disable max-lines-per-function, max-statements */
import { useCallback, useMemo, useState } from "react";
import Add from "./Add";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import useCreateNewsCritics from "hooks/useCreateNewsCritics";
import useNewsCritics from "hooks/useNewsCritics";
import usePreviewsNewsCritics from "hooks/usePreviewsNewsCritics";
import useUser from "hooks/useUser";
const DEFAULT_IS_COMPACT = true;
const usePrepareComponent = () => {
    const { removeImage } = usePreviewsNewsCritics();
    const { clear, setOverrides, state: { avNirvana, bluRay, hidefDigest, news, whySoBlu } } = useNewsCritics();
    const { isAdmin } = useUser();
    const [isCompact, setIsCompact] = useState(DEFAULT_IS_COMPACT);
    const { areNewsSourcesLoading } = useCreateNewsCritics();
    const onClear = useCallback((serviceName) => () => {
        // Remove image
        removeImage(serviceName);
        // Clear review
        clear(serviceName);
    }, [clear, removeImage]);
    const onSetOverrides = useCallback((serviceName) => (overrides) => {
        setOverrides(overrides, serviceName);
    }, [setOverrides]);
    const newsControlCheckboxesProps = useMemo(() => {
        const isCompactCheckboxProps = {
            checked: isCompact,
            isDisabled: areNewsSourcesLoading,
            onChange: () => {
                setIsCompact(!isCompact);
            }
        };
        return {
            isAdmin,
            isCompactCheckboxProps
        };
    }, [areNewsSourcesLoading, isAdmin, isCompact, setIsCompact]);
    return {
        areNewsSourcesLoading,
        avNirvana: avNirvana.selected,
        bluRay: bluRay.selected,
        hidefDigest: hidefDigest.selected,
        news,
        newsControlCheckboxesProps,
        onClear,
        onSetOverrides,
        whySoBlu: whySoBlu.selected
    };
};
export default prepareComponent(usePrepareComponent)(Add);
