/* eslint-disable max-lines-per-function */
import { mutations, queries } from "@bluframe/mightychroma-mightytool-graphql-schema";
import { useMutation, useQuery } from "@apollo/client";
import NewsComponent from "./News";
import { newsServiceNames } from "config/site/serviceNames";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import { useLocation } from "react-router-dom";
const { news: { PUBLISH_DRAFT_NEWS } } = mutations;
const { news: { GET_DRAFTS_NEWS_WAVE } } = queries;
const usePrepareComponent = () => {
    const { search } = useLocation();
    const startAt = new URLSearchParams(search).get("startAt");
    const { data, loading } = useQuery(GET_DRAFTS_NEWS_WAVE, {
        fetchPolicy: "network-only",
        variables: {
            cursor: startAt ?? undefined
        }
    });
    const [publishDrafts, { loading: draftsLoading }] = useMutation(PUBLISH_DRAFT_NEWS, {
        refetchQueries: [GET_DRAFTS_NEWS_WAVE]
    });
    const onPublish = (id) => {
        publishDrafts({
            variables: {
                ids: [id]
            }
        });
    };
    const getPreviewsFromDraft = (draft) => {
        const previews = [];
        newsServiceNames.forEach((serviceName) => {
            const review = draft[serviceName];
            if (review) {
                previews.push({
                    serviceName,
                    url: review.url ?? ""
                });
            }
        });
        return previews;
    };
    const drafts = data?.draftsNewsWave.news
        .filter((draft) => Boolean(draft.name))
        .map((draft) => ({
        id: draft.id,
        isChecked: false,
        isLoading: draftsLoading,
        // eslint-disable-next-line no-empty-function
        onChange: () => { },
        onPublish: () => onPublish(draft.id),
        previews: getPreviewsFromDraft(draft),
        // Typescript needs the `as string` here to satisfy the type checker
        // we filter out drafts without a name or title above
        title: draft.name
    }));
    return {
        drafts,
        loading,
        pagination: {
            hasNextData: data?.draftsNewsWave.hasNextData,
            hasPrevData: data?.draftsNewsWave.hasPrevData,
            link: "/drafts/news",
            next: data?.draftsNewsWave.cursor,
            prev: data?.draftsNewsWave.prevCursor
        }
    };
};
export default prepareComponent(usePrepareComponent)(NewsComponent);
