/* eslint-disable no-ternary, no-nested-ternary, max-lines-per-function */

import {
  H1,
  H2,
  Left,
  Main,
  Paragraph,
  Right,
  Row,
  Top,
  Wrapper
} from "./styled"
import Autosuggest from "components/Autosuggest"
import Button from "components/buttons/Button"
import { ComponentProps } from "."
import { InputText } from "@bluframe/blublocks"
import Select from "components/inputs/Select"

const ONE = 1

const Roundup = ({
  characters,
  characterId,
  homeVideoReviewTitles,
  isAutoSuggestLoading,
  isCharactersLoading,
  isGenerateBlogRoundupEnabled,
  isGenerateBlogRoundupLoading,
  onChangeCharacterId,
  onChangeTheme,
  onClickGenerate,
  onGetSuggestions,
  onSelectHomeVideoReviewId,
  homeVideoReviewIds,
  reviewNames,
  theme
}: ComponentProps) => (
  <Wrapper>
    <Top>
      <H1>{"Reviews Roundup"}</H1>
      <Button
        disabled={!isGenerateBlogRoundupEnabled}
        label="Generate"
        loading={isGenerateBlogRoundupLoading}
        onClick={onClickGenerate}
      />
    </Top>
    <Main>
      <Left>
        <Row>
          <Select
            label="Character"
            loading={isCharactersLoading}
            name="characterId"
            onChange={onChangeCharacterId}
            options={characters}
            placeholder="Select Character"
            value={characterId}
          />
        </Row>
        <Row>
          <InputText
            label="Theme"
            name="theme"
            // @ts-ignore
            onChange={onChangeTheme}
            value={theme}
          />
        </Row>
        {homeVideoReviewIds.map((homeVideoReviewId, index) => (
          <Row key={`${homeVideoReviewId}-${index + ONE}`}>
            <Autosuggest
              disabled={isAutoSuggestLoading}
              inputId={`homeVideoReviewId-${index + ONE}`}
              label={`Select Home Video Review ${index + ONE}`}
              onGetSuggestions={onGetSuggestions}
              onSuggestionClick={onSelectHomeVideoReviewId(index)}
              suggestions={homeVideoReviewTitles}
              value={homeVideoReviewId}
            />
          </Row>
        ))}
      </Left>
      <Right>
        <Row>
          <H2>{"Roundup Theme"}</H2>
          <Paragraph>{theme}</Paragraph>
        </Row>
        {reviewNames?.map((reviewName, index) => (
          <Row key={`Review ${index + ONE}`}>
            <H2>{`Review ${index + ONE}`}</H2>
            <Paragraph>{reviewName}</Paragraph>
          </Row>
        ))}
      </Right>
    </Main>
  </Wrapper>
)

export default Roundup
