/* eslint-disable max-lines-per-function, max-statements, no-undefined */
import { getIsAddHomeVideoReviewEnabled, getIsHomeVideoReviewReadyToBeCreated } from "./helpers";
import { useContext, useEffect, useMemo } from "react";
import Context from "contexts/MightyTool";
import { DEFAULT_HOME_VIDEO_REVIEW } from "contexts/Reviews";
import equals from "ramda/src/equals";
import useAddEditHomeVideoReview from "hooks/useAddEditHomeVideoReview";
import useCreateReviews from "hooks/useCreateReviews";
import usePreviews from "hooks/usePreviews";
import useReviews from "hooks/useReviews";
const useAddHomeVideoReview = (props) => {
    const { resetImages } = usePreviews();
    const { addHomeVideoReview: { addHomeVideoReview, called, data, loading: addHomeVideoReviewLoading, reset } } = useContext(Context);
    // Review State
    const { resetSelectedReviews, state: { avForums, avNirvana, avsForum, bluRay, bluRayAuthority, digitalBits, doBlu, hidefDigest, homeTheaterForum, homeVideoReview, theaterByte, whySoBlu } } = useReviews();
    // Create Reviews State
    const { areReviewsCalled, areReviewsLoading, onCreateReviews, resetReviews } = useCreateReviews();
    // Clear All
    const { onClearAll } = useAddEditHomeVideoReview();
    // Are all Mighty Chroma properties ready?
    const isHomeVideoReviewReadyToBeCreated = useMemo(() => getIsHomeVideoReviewReadyToBeCreated({
        avForums: avForums.selected,
        avNirvana: avNirvana.selected,
        avsForum: avsForum.selected,
        bluRay: bluRay.selected,
        bluRayAuthority: bluRayAuthority.selected,
        digitalBits: digitalBits.selected,
        doBlu: doBlu.selected,
        hidefDigest: hidefDigest.selected,
        homeTheaterForum: homeTheaterForum.selected,
        homeVideoReview,
        theaterByte: theaterByte.selected,
        whySoBlu: whySoBlu.selected
    }), [
        homeVideoReview,
        avForums.selected,
        avNirvana.selected,
        avsForum.selected,
        bluRay.selected,
        bluRayAuthority.selected,
        digitalBits.selected,
        doBlu.selected,
        hidefDigest.selected,
        homeTheaterForum.selected,
        theaterByte.selected,
        whySoBlu.selected
    ]);
    // If reviews are called and Mighty Chroma is ready to be created and
    // create hasn't been called then create a Mighty Chroma
    useEffect(() => {
        if (props?.isReadOnly) {
            return;
        }
        if (areReviewsCalled) {
            if (isHomeVideoReviewReadyToBeCreated && !called) {
                addHomeVideoReview({
                    variables: {
                        homeVideoReview: {
                            ...homeVideoReview,
                            isPublished: false
                        }
                    }
                });
            }
        }
    }, [
        addHomeVideoReview,
        areReviewsCalled,
        called,
        isHomeVideoReviewReadyToBeCreated,
        homeVideoReview,
        props?.isReadOnly
    ]);
    // If Mighty Chroma has been created then reset reviews
    useEffect(() => {
        if (props?.isReadOnly) {
            return;
        }
        if (data) {
            if (!equals(homeVideoReview, DEFAULT_HOME_VIDEO_REVIEW)) {
                resetSelectedReviews();
                reset();
            }
            // Reset images
            resetImages();
            // Reset reviews
            resetReviews();
        }
    }, [
        data,
        homeVideoReview,
        props?.isReadOnly,
        reset,
        resetImages,
        resetReviews,
        resetSelectedReviews
    ]);
    // Are we creating reviews or a Mighty Chroma?
    const loading = useMemo(() => addHomeVideoReviewLoading || areReviewsLoading, [addHomeVideoReviewLoading, areReviewsLoading]);
    const isOmdbIdValid = useMemo(() => Boolean(homeVideoReview.OMDbId && /^tt\d+$/u.test(homeVideoReview.OMDbId)), [homeVideoReview.OMDbId]);
    // Are all Mighty Chroma properties ready for the create button to be enabled?
    const isAddHomeVideoReviewEnabled = useMemo(() => getIsAddHomeVideoReviewEnabled({
        homeVideoReview,
        isOmdbIdValid,
        services: {
            avForums: avForums.selected,
            avNirvana: avNirvana.selected,
            avsForum: avsForum.selected,
            bluRay: bluRay.selected,
            bluRayAuthority: bluRayAuthority.selected,
            digitalBits: digitalBits.selected,
            doBlu: doBlu.selected,
            hidefDigest: hidefDigest.selected,
            homeTheaterForum: homeTheaterForum.selected,
            theaterByte: theaterByte.selected,
            whySoBlu: whySoBlu.selected
        }
    }) && !loading, [
        avForums.selected,
        avNirvana.selected,
        avsForum.selected,
        bluRay.selected,
        bluRayAuthority.selected,
        digitalBits.selected,
        doBlu.selected,
        hidefDigest.selected,
        homeTheaterForum.selected,
        isOmdbIdValid,
        loading,
        homeVideoReview,
        theaterByte.selected,
        whySoBlu.selected
    ]);
    return {
        OMDbId: homeVideoReview.OMDbId,
        isAddHomeVideoReviewEnabled,
        isOmdbIdValid,
        loading,
        name: homeVideoReview.name,
        onAddHomeVideoReview: onCreateReviews,
        onClearAll
    };
};
export default useAddHomeVideoReview;
