import EditActions from "./EditActions"

export interface Props {
  isUpdateItemEnabled: boolean
  label: string
  loading: boolean
  onClearAll: () => void
  onUpdateItem: () => void
  topItem?: React.ReactNode
}

export interface ComponentProps extends Props {}

export default EditActions
