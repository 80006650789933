import gql from "graphql-tag"
import news from "fragments/news"

const GET_NEWS = gql`
  ${news}

  query getNews($id: ID!) {
    getNews(id: $id) {
      ...News
    }
  }
`

export default GET_NEWS
