import { gql } from "graphql-tag"

const news = gql`
  type Mutation {
    addNews(news: NewsAddInput!): NewsAddResponse!

    addNewsSource(newsSourceAddInput: NewsSourceAddInput!): NewsSourceResponse!

    addPreviewNewsSource(
      previewNewsSourceAddInput: PreviewNewsSourceAddInput!
    ): PreviewNewsSource!

    publishDraftNews(ids: [ID!]!): PublishDraftNewsResponse!

    updateNews(news: NewsUpdateInput!): NewsUpdateResponse!

    updateNewsSource(
      serviceName: String!
      newsSourceUpdateInput: NewsSourceUpdateInput!
    ): NewsSourceResponse!

    updateCriticsNewsSourcesFromUrl(
      id: ID!
      url: String!
      serviceName: String!
    ): NewsSourceResponse!
  }
`

export default news
