import gql from "graphql-tag"
import news from "fragments/news"

const GET_DRAFTS_NEWS = gql`
  ${news}

  query getDraftsNews {
    draftsNews {
      ...News
    }
  }
`
export default GET_DRAFTS_NEWS
