/* eslint-disable no-ternary */

import {
  HomeVideoReviewSearchItem,
  NewsSearchItem
} from "@bluframe/mightychroma-mightytool-frontend-types"
import { MouseEventHandler } from "react"
import Suggestion from "./Suggestion"
import { UseComboboxGetItemPropsReturnValue } from "downshift"
import prepareComponent from "@bluframe/grapple/prepareComponent"

export interface Props {
  item: HomeVideoReviewSearchItem | NewsSearchItem
  itemProps: UseComboboxGetItemPropsReturnValue
  // eslint-disable-next-line no-unused-vars
  onSuggestionClick: (id: string, name: string) => void
}

export interface ComponentProps
  extends Omit<Props, "item" | "onSuggestionClick"> {
  label: string
  onClick: MouseEventHandler
}

export const usePrepareComponent = ({
  item,
  onSuggestionClick,
  ...props
}: Props): ComponentProps => {
  const label = `${item.name}${
    (item as HomeVideoReviewSearchItem).mas?.year
      ? ` (${(item as HomeVideoReviewSearchItem).mas?.year})`
      : ""
  }`

  const onClick: MouseEventHandler = (event) => {
    // Downshift onClick
    props.itemProps.onClick?.(event)

    // onSuggestionClick
    onSuggestionClick(item.id, label)
  }

  return {
    ...props,
    label,
    onClick
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  Suggestion
)
