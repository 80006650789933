/* eslint-disable max-lines-per-function */
import Gather from "./Gather";
import { mutations } from "@bluframe/mightychroma-mightytool-graphql-schema";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import useStateByServiceName from "helpers/getStateByServiceName";
const { eightLeggedFreak: { EIGHT_LEGGED_FREAK_GATHER, EIGHT_LEGGED_FREAK_NEWS_SOURCES_GATHER } } = mutations;
const usePrepareComponent = ({ serviceName, serviceType }) => {
    const { state, stateByServiceName } = useStateByServiceName(serviceName, serviceType);
    const [gatherReviews, { loading: reviewsLoading }] = useMutation(EIGHT_LEGGED_FREAK_GATHER, {
        variables: {
            eightLeggedFreakGatherInput: {
                isAllAroundGather: stateByServiceName
                    .isAllAroundGather,
                isBluRay: true,
                isUHD: state.isUHD,
                serviceName
            }
        }
    });
    const [gatherNewsCritics, { loading: newsCriticsLoading }] = useMutation(EIGHT_LEGGED_FREAK_NEWS_SOURCES_GATHER, {
        variables: {
            eightLeggedFreakNewsSourcesGatherInput: {
                serviceName
            }
        }
    });
    const onGather = useCallback(() => {
        if (serviceType === "news") {
            gatherNewsCritics();
        }
        else {
            gatherReviews();
        }
    }, [gatherNewsCritics, gatherReviews, serviceType]);
    return {
        isLoading: newsCriticsLoading || reviewsLoading,
        onGather
    };
};
export default prepareComponent(usePrepareComponent)(Gather);
