export const onCreateNewsCritics = (createNewsCritics, isReady) => {
    if (isReady) {
        createNewsCritics();
    }
};
const createNewsCritics = ({ addAvNirvana, addBluRay, addHidefDigest, addWhySoBlu, avNirvanaReady, bluRayReady, hidefDigestReady, whySoBluReady }) => {
    onCreateNewsCritics(addAvNirvana, avNirvanaReady);
    onCreateNewsCritics(addBluRay, bluRayReady);
    onCreateNewsCritics(addHidefDigest, hidefDigestReady);
    onCreateNewsCritics(addWhySoBlu, whySoBluReady);
};
export default createNewsCritics;
