import {
  NewsServiceName,
  ReviewsServiceName
} from "@bluframe/mightychroma-mightytool-frontend-types"
import Draft from "./Draft"
import { OptionValue } from "@bluframe/blublocks"

export interface DraftPreview {
  image?: string
  releaseDate?: string
  serviceName: NewsServiceName | ReviewsServiceName
  url: string
}

export interface Props {
  id: string
  isChecked: boolean
  isLoading: boolean
  // eslint-disable-next-line no-unused-vars
  onChange: (value: OptionValue) => void
  onPublish: () => void
  previews: DraftPreview[]
  title: string
}

export interface ComponentProps extends Props {}

export default Draft
