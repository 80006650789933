/* eslint-disable max-lines-per-function */

import { ComponentProps } from "."
import CriticsOverrides from "components/Critics/PodSource/Overrides"
import InputDate from "components/inputs/Date"
import OverridesScoreQuoteAndSection from "./OverridesScoreQuoteAndSection"
import { OverridesSections } from "./styled"

const Overrides = ({
  inputRefs,
  onSetReleaseDate,
  overrides
}: ComponentProps) => (
  <CriticsOverrides>
    <OverridesSections>
      <OverridesScoreQuoteAndSection
        inputId="video"
        inputRefs={inputRefs.video}
        label="Video"
        name="video"
        quote={overrides.video?.quote}
        score={overrides.video?.score}
        section={overrides.video?.section}
      />
      <OverridesScoreQuoteAndSection
        inputId="audio"
        inputRefs={inputRefs.audio}
        label="Audio"
        name="audio"
        quote={overrides.audio?.quote}
        score={overrides.audio?.score}
        section={overrides.audio?.section}
      />
      <OverridesScoreQuoteAndSection
        inputId="extra"
        inputRefs={inputRefs.extra}
        label="Extra"
        name="extra"
        quote={overrides.extra?.quote}
        score={overrides.extra?.score}
        section={overrides.extra?.section}
      />
      <OverridesScoreQuoteAndSection
        inputId="movie"
        inputRefs={inputRefs.movie}
        label="Movie"
        name="movie"
        quote={overrides.movie?.quote}
        score={overrides.movie?.score}
        section={overrides.movie?.section}
      />
      <OverridesScoreQuoteAndSection
        inputId="total"
        inputRefs={inputRefs.total}
        label="Total"
        name="Total"
        quote={overrides.total?.quote}
        score={overrides.total?.score}
        section={overrides.total?.section}
      />
    </OverridesSections>
    <InputDate
      label="Release Date"
      onChange={onSetReleaseDate}
      value={overrides.releaseDate}
    />
  </CriticsOverrides>
)
export default Overrides
