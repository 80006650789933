import Button from "components/buttons/Button"
import { ComponentProps } from "."
import { Wrapper } from "./styled"

const EditActions = ({
  isUpdateItemEnabled,
  label,
  loading,
  onClearAll,
  onUpdateItem,
  topItem
}: ComponentProps) => (
  <Wrapper>
    {topItem}

    <Button
      bold
      disabled={!isUpdateItemEnabled || loading}
      label={label}
      loading={loading}
      onClick={onUpdateItem}
    />
    <Button
      bold
      disabled={loading}
      label="Clear All"
      onClick={onClearAll}
      secondary
    />
  </Wrapper>
)

export default EditActions
