import character from "fragments/character"
import gql from "graphql-tag"

const GET_CHARACTERS = gql`
  ${character}

  query getCharacters {
    getCharacters {
      ...Character
    }
  }
`
export default GET_CHARACTERS
