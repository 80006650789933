import gql from "graphql-tag"

const UPDATE_NEWS = gql`
  mutation updateNews($news: NewsUpdateInput!) {
    updateNews(news: $news) {
      id
    }
  }
`
export default UPDATE_NEWS
