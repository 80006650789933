import gql from "graphql-tag"

const PUBLISH_DRAFT_NEWS = gql`
  mutation publishDraftNews($ids: [ID!]!) {
    publishDraftNews(ids: $ids) {
      ids
    }
  }
`
export default PUBLISH_DRAFT_NEWS
