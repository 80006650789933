/* eslint-disable no-ternary, max-lines-per-function */

import { FormEventHandler, useState } from "react"
import {
  NewsServiceName,
  ReviewsServiceName
} from "@bluframe/mightychroma-mightytool-frontend-types"
import PodSource from "./PodSource"
import prepareComponent from "@bluframe/grapple/prepareComponent"

export interface Props {
  areItemsLoading: boolean
  isCompact: boolean
  isCreated: boolean
  isLoading: boolean
  isValid: boolean
  itemLabel: "News" | "Reviews"
  label: string
  onCancelOverrides: () => void
  onClear: () => void
  onSaveOverrides: FormEventHandler
  overrides: React.ReactNode
  serviceName: NewsServiceName | ReviewsServiceName
  text: string
}

export interface ComponentProps extends Omit<Props, "onSetOverrides"> {
  isEditOpen: boolean
  // eslint-disable-next-line no-unused-vars
  onSetIsEditOpen: (isOpen: boolean) => () => void
}

const DEFAULT_IS_EDIT_OPEN = false

const usePrepareComponent = ({ ...props }: Props): ComponentProps => {
  const [isEditOpen, setIsEditOpen] = useState<boolean>(DEFAULT_IS_EDIT_OPEN)

  const onSetIsEditOpen = (isOpen: boolean) => () => {
    setIsEditOpen(isOpen)
  }

  const onCancelOverrides = () => {
    props.onCancelOverrides()
    setIsEditOpen(false)
  }

  const onSaveOverrides: FormEventHandler = (event) => {
    props.onSaveOverrides(event)
    setIsEditOpen(false)
  }

  return {
    ...props,
    isEditOpen,
    onCancelOverrides,
    onSaveOverrides,
    onSetIsEditOpen
  }
}

export default prepareComponent(usePrepareComponent)(PodSource)
