import EIGHT_LEGGED_FREAK_GATHER from "./eightLeggedFreakGather"
import EIGHT_LEGGED_FREAK_NEWS_SOURCES_GATHER from "./eightLeggedFreakNewsSourcesGather"
import EIGHT_LEGGED_FREAK_NEWS_SOURCE_UPDATE from "./eightLeggedFreakNewsSourceUpdate"
import EIGHT_LEGGED_FREAK_REVIEW_UPDATE from "./eightLeggedFreakReviewUpdate"

export default {
  EIGHT_LEGGED_FREAK_GATHER,
  EIGHT_LEGGED_FREAK_NEWS_SOURCES_GATHER,
  EIGHT_LEGGED_FREAK_NEWS_SOURCE_UPDATE,
  EIGHT_LEGGED_FREAK_REVIEW_UPDATE
}
