import gql from "graphql-tag"

const UPDATE_NEWS_SOURCES_FROM_URL = gql`
  mutation updateNewsSourcesFromUrl(
    $id: ID!
    $serviceName: String!
    $url: String!
  ) {
    updateNewsSourcesFromUrl(id: $id, serviceName: $serviceName, url: $url) {
      id
      serviceName
    }
  }
`

export default UPDATE_NEWS_SOURCES_FROM_URL
