import { gql } from "graphql-tag"

const mutation = gql`
  type Mutation {
    addContest(contestAddInput: ContestAddInput!): ContestAddResponse!

    deleteContest(id: ID!): ContestDeleteResponse!

    updateContest(
      id: ID!
      contestUpdateInput: ContestUpdateInput!
    ): ContestUpdateResponse!
  }
`

export default mutation
