/* eslint-disable no-ternary, max-lines-per-function */
import { useCallback, useMemo } from "react";
import Checkbox from "./Checkbox";
import isNewsServiceName from "helpers/isNewsServiceName";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import useNewsCritics from "hooks/useNewsCritics";
import useReviews from "hooks/useReviews";
import useStateByServiceName from "helpers/getStateByServiceName";
const usePrepareComponent = ({ inputId, isNeedsDisplayList = true, label, serviceName, serviceType, setValueName, valueName }) => {
    const newsCriticsContext = useNewsCritics();
    const reviewsContext = useReviews();
    const { state: reviewsState } = reviewsContext;
    const { state: newsCriticsState } = newsCriticsContext;
    const { stateByServiceName } = useStateByServiceName(serviceName, serviceType);
    const isDisplayList = useMemo(() => {
        return Boolean(stateByServiceName.display.length);
    }, [stateByServiceName]);
    const checked = useMemo(() => 
    // We need to do this as typescript isn't gonna allow
    // stateByServiceName without checking for type first
    serviceType === "news" &&
        setValueName !== "setIsAllAroundGather" &&
        valueName !== "isAllAroundGather" &&
        isNewsServiceName(serviceName)
        ? newsCriticsState[serviceName][valueName]
        : reviewsState[serviceName][valueName], [
        newsCriticsState,
        reviewsState,
        serviceName,
        serviceType,
        setValueName,
        valueName
    ]);
    const onChange = useCallback(() => {
        if (serviceType === "news" &&
            setValueName !== "setIsAllAroundGather" &&
            isNewsServiceName(serviceName)) {
            newsCriticsContext[setValueName](!checked, serviceName);
        }
        else {
            reviewsContext[setValueName](!checked, serviceName);
        }
    }, [
        checked,
        newsCriticsContext,
        reviewsContext,
        serviceName,
        serviceType,
        setValueName
    ]);
    return {
        checked,
        inputId,
        isDisplayList,
        isNeedsDisplayList,
        label,
        name: valueName,
        onChange
    };
};
export default prepareComponent(usePrepareComponent)(Checkbox);
