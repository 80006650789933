import Card from "components/Cards/Card"
import { ComponentProps } from "."

const NewsCard = ({
  actions,
  avatar,
  display,
  flagLoading,
  isShowFlagButton,
  onSelect,
  onToggleFlagged,
  selected,
  serviceName,
  subheader,
  title
}: ComponentProps) => (
  <Card
    actions={actions}
    avatar={avatar}
    display={display}
    flagLoading={flagLoading}
    isShowFlagButton={isShowFlagButton}
    label="News"
    name="news"
    onSelect={onSelect}
    onToggleFlagged={onToggleFlagged}
    selected={selected}
    serviceName={serviceName}
    subheader={subheader}
    title={title}
  />
)

export default NewsCard
