import blog from "./blog"
import content from "./content"
import contests from "./contests"
import dashboard from "./dashboard"
import eightLeggedFreak from "./eightLeggedFreak"
import emails from "./emails"
import forum from "./forum"
import { gql } from "graphql-tag"
import homeVideoReviews from "./homeVideoReviews"
import news from "./news"
import newsletters from "./newsletters"

const query = gql`
  ${blog}
  ${content}
  ${contests}
  ${dashboard}
  ${eightLeggedFreak}
  ${emails}
  ${forum}
  ${homeVideoReviews}
  ${news}
  ${newsletters}

  type Query {
    getSiteUsersByContestId(contestId: ID!): [SiteUser]

    me: User

    omdbId(bluRayId: String!): String

    reviews(serviceName: String!): [Review]
  }
`

export default query
