import { jsx as _jsx } from "react/jsx-runtime";
import "./wdyr";
import "@bluframe/blublocks/blublocks.css";
import { ApolloProvider } from "@apollo/client";
import App from "./app";
import { BluBlocksProvider } from "@bluframe/blublocks";
import { BrowserRouter } from "react-router-dom";
import { MightyToolProvider } from "contexts/MightyTool";
import { createRoot } from "react-dom/client";
import graphql from "apolloGraphql";
import { theme } from "@bluframe/mightychroma-mightytool-ui";
// @ts-ignore
const root = createRoot(document.getElementById("root"));
root.render(_jsx(ApolloProvider, { client: graphql(), children: _jsx(BrowserRouter, { children: _jsx(MightyToolProvider, { children: _jsx(BluBlocksProvider, { theme: theme, children: _jsx(App, {}) }) }) }) }));
