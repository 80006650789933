import gql from "graphql-tag"

const ADD_PREVIEW = gql`
  mutation addPreview($previewAddInput: PreviewAddInput!) {
    addPreview(previewAddInput: $previewAddInput) {
      audio {
        quote
        score
        section
      }
      author {
        name
        url
      }
      editions
      extra {
        quote
        score
        section
      }
      errorFields
      images {
        back
        front
        overview
        slip
        slipback
      }
      isValid
      movie {
        quote
        score
        section
      }
      releaseDate
      tags
      total {
        quote
        score
        section
      }
      url
      video {
        quote
        score
        section
      }
    }
  }
`
export default ADD_PREVIEW
