import { gql } from "graphql-tag"

const newsletters = gql`
  type Query {
    getNewsletter(id: ID!): Newsletter
    getNewsletterAndSubscribersCount(id: ID!): NewsletterAndSubscribersCount
    getNewsletters: [Newsletter]
  }
`

export default newsletters
