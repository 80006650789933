import { gql } from "graphql-tag"

const homeVideoReviews = gql`
  type Mutation {
    addHomeVideoReview(
      homeVideoReview: HomeVideoReviewAddInput!
    ): HomeVideoReviewAddResponse!

    createHomeVideoReviewsImagesGrid(
      homeVideoReviewsIds: [ID!]!
    ): CreateImagesGridResponse!

    publishDraftHomeVideoReview(
      ids: [ID!]!
    ): PublishDraftHomeVideoReviewResponse!

    regenerateHomeVideoReviewSummary(
      id: ID!
    ): HomeVideoReviewRegenerateSummaryResponse!

    updateHomeVideoReview(
      homeVideoReview: HomeVideoReviewUpdateInput!
    ): HomeVideoReviewUpdateResponse!
  }
`

export default homeVideoReviews
