import GET_DRAFTS_NEWS from "./getDraftsNews"
import GET_DRAFTS_NEWS_WAVE from "./getDraftsNewsWave"
import GET_NEWS from "./getNews"
import GET_NEWS_LIST from "./getNewsList"
import SEARCH_NEWS from "./searchNews"

export default {
  GET_DRAFTS_NEWS,
  GET_DRAFTS_NEWS_WAVE,
  GET_NEWS,
  GET_NEWS_LIST,
  SEARCH_NEWS
}
