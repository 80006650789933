/* eslint-disable max-lines-per-function */
import getIsServiceValid from "./getIsServiceValid";
/**
 * Determine if the HomeVideoReview update functionality should be enabled
 * based on the given input properties of various reviews and an ID.
 * @function getIsUpdateHomeVideoReviewEnabled
 * @param {HomeVideoReviewEnabledProps} props - An object containing review properties
 * and HomeVideoReview ID.
 * @returns {boolean} - If true, the HomeVideoReview update functionality is considered enabled; otherwise, false.
 */
const getIsUpdateHomeVideoReviewEnabled = ({ avForums, avNirvana, avsForum, bluRay, bluRayAuthority, digitalBits, doBlu, hidefDigest, homeTheaterForum, id, homeVideoReview, theaterByte, whySoBlu }) => Boolean(id) &&
    getIsServiceValid(avForums.url, avForums.isPreviewValid, homeVideoReview.avForumsId) &&
    getIsServiceValid(avNirvana.url, avNirvana.isPreviewValid, homeVideoReview.avNirvanaId) &&
    getIsServiceValid(avsForum.url, avsForum.isPreviewValid, homeVideoReview.avsForumId) &&
    getIsServiceValid(bluRay.url, bluRay.isPreviewValid, homeVideoReview.bluRayId) &&
    getIsServiceValid(bluRayAuthority.url, bluRayAuthority.isPreviewValid, homeVideoReview.bluRayAuthorityId) &&
    getIsServiceValid(digitalBits.url, digitalBits.isPreviewValid, homeVideoReview.digitalBitsId) &&
    getIsServiceValid(doBlu.url, doBlu.isPreviewValid, homeVideoReview.doBluId) &&
    getIsServiceValid(hidefDigest.url, hidefDigest.isPreviewValid, homeVideoReview.hidefDigestId) &&
    getIsServiceValid(homeTheaterForum.url, homeTheaterForum.isPreviewValid, homeVideoReview.homeTheaterForumId) &&
    getIsServiceValid(theaterByte.url, theaterByte.isPreviewValid, homeVideoReview.theaterByteId) &&
    getIsServiceValid(whySoBlu.url, whySoBlu.isPreviewValid, homeVideoReview.whySoBluId);
export default getIsUpdateHomeVideoReviewEnabled;
