/**
 * Returns true if the service is created
 * Note: id is optional
 * @param url - The url of the service
 * @param isPreviewValid - The preview is valid
 * @param id - The id of the service
 * @returns True if the service is created
 * @example
 * getIsServiceCreated("https://www.blu-ray.com/movies/Punch-Drunk-Love-4K-Blu-ray/376197/", true, "1")
 */
const getIsServiceCreated = (url, isPreviewValid, id) => (Boolean(url) && isPreviewValid && Boolean(id)) || !url;
export default getIsServiceCreated;
