/* eslint-disable no-ternary, max-lines-per-function */

import {
  FormEventHandler,
  RefObject,
  useCallback,
  useRef,
  useState
} from "react"
import {
  NewsServiceName,
  NewsSourceOverrides
} from "@bluframe/mightychroma-mightytool-frontend-types"
import PodSource from "./PodSource"
import prepareComponent from "@bluframe/grapple/prepareComponent"

export interface Props {
  areNewsLoading: boolean
  isCompact: boolean
  isCreated: boolean
  isLoading: boolean
  isValid: boolean
  label: string
  // eslint-disable-next-line no-unused-vars
  onClear: (serviceName: NewsServiceName) => () => void
  onSetOverrides: (
    // eslint-disable-next-line no-unused-vars
    serviceName: NewsServiceName
    // eslint-disable-next-line no-unused-vars
  ) => (overrides: NewsSourceOverrides) => void
  overrides?: NewsSourceOverrides
  serviceName: NewsServiceName
  text: string
}

export interface ComponentProps
  extends Omit<Props, "onClear" | "onSetOverrides"> {
  inputRefs: {
    name: RefObject<HTMLInputElement>
    text: RefObject<HTMLTextAreaElement>
  }
  onCancel: () => void
  onClear: () => void
  onSave: FormEventHandler
  overrides: NewsSourceOverrides
}

const DEFAULT_OVERRIDES: NewsSourceOverrides = {}

const usePrepareComponent = ({
  onClear: onClearProps,
  onSetOverrides,
  serviceName,
  ...props
}: Props): ComponentProps => {
  const [newOverrides, setNewOverrides] =
    useState<NewsSourceOverrides>(DEFAULT_OVERRIDES)

  const inputRefs = {
    name: useRef<HTMLInputElement>(null),
    text: useRef<HTMLTextAreaElement>(null)
  }

  const onCancel = () => {
    setNewOverrides(DEFAULT_OVERRIDES)
  }

  const onSave: FormEventHandler = (event) => {
    event.preventDefault()

    onSetOverrides(serviceName)({
      name: inputRefs.name.current?.value,
      text: inputRefs.text.current?.value
    })
  }

  const onClear = useCallback(() => {
    const onClearServiceName = onClearProps(serviceName)

    onClearServiceName()
  }, [onClearProps, serviceName])

  return {
    ...props,
    inputRefs,
    onCancel,
    onClear,
    onSave,
    overrides: newOverrides,
    serviceName
  }
}

export default prepareComponent(usePrepareComponent)(PodSource)
