/* eslint-disable max-lines-per-function, no-empty-function, max-statements, max-lines */
import { useCallback, useMemo } from "react";
import createReviews from "./createReviews";
import { mutations } from "@bluframe/mightychroma-mightytool-graphql-schema";
import { useMutation } from "@apollo/client";
const { critics: { ADD_REVIEW } } = mutations;
export const initialContext = {
    areReviewsCalled: false,
    areReviewsLoading: false,
    avForumsData: undefined,
    avNirvanaData: undefined,
    avsForumData: undefined,
    bluRayAuthorityData: undefined,
    bluRayData: undefined,
    digitalBitsData: undefined,
    doBluData: undefined,
    hidefDigestData: undefined,
    homeTheaterForumData: undefined,
    onCreateReviews: () => { },
    resetReviews: () => { },
    theaterByteData: undefined,
    whySoBluData: undefined
};
const useCreateReviewsContext = ({ avForums, avNirvana, avsForum, bluRay, bluRayAuthority, digitalBits, doBlu, hidefDigest, homeTheaterForum, isUHD, homeVideoReview, theaterByte, whySoBlu }) => {
    const [addAvForums, { called: avForumsCalled, data: avForumsData, loading: avForumsLoading, reset: avForumsReset }] = useMutation(ADD_REVIEW, {
        variables: {
            reviewAddInput: {
                isUHD,
                overrides: avForums.selected.overrides,
                serviceName: "avForums",
                url: avForums.selected.url
            }
        }
    });
    const [addAvNirvana, { called: avNirvanaCalled, data: avNirvanaData, loading: avNirvanaLoading, reset: avNirvanaReset }] = useMutation(ADD_REVIEW, {
        variables: {
            reviewAddInput: {
                isUHD,
                overrides: avNirvana.selected.overrides,
                serviceName: "avNirvana",
                url: avNirvana.selected.url
            }
        }
    });
    const [addAvsForum, { called: avsForumCalled, data: avsForumData, loading: avsForumLoading, reset: avsForumReset }] = useMutation(ADD_REVIEW, {
        variables: {
            reviewAddInput: {
                isUHD,
                overrides: avsForum.selected.overrides,
                serviceName: "avsForum",
                url: avsForum.selected.url
            }
        }
    });
    const [addBluRay, { called: bluRayCalled, data: bluRayData, loading: bluRayLoading, reset: bluRayReset }] = useMutation(ADD_REVIEW, {
        variables: {
            reviewAddInput: {
                isUHD,
                overrides: bluRay.selected.overrides,
                serviceName: "bluRay",
                url: bluRay.selected.url
            }
        }
    });
    const [addBluRayAuthority, { called: bluRayAuthorityCalled, data: bluRayAuthorityData, loading: bluRayAuthorityLoading, reset: bluRayAuthorityReset }] = useMutation(ADD_REVIEW, {
        variables: {
            reviewAddInput: {
                isUHD,
                overrides: bluRayAuthority.selected.overrides,
                serviceName: "bluRayAuthority",
                url: bluRayAuthority.selected.url
            }
        }
    });
    const [addDigitalBits, { called: digitalBitsCalled, data: digitalBitsData, loading: digitalBitsLoading, reset: digitalBitsReset }] = useMutation(ADD_REVIEW, {
        variables: {
            reviewAddInput: {
                isUHD,
                overrides: digitalBits.selected.overrides,
                serviceName: "digitalBits",
                url: digitalBits.selected.url
            }
        }
    });
    const [addDoBlu, { called: doBluCalled, data: doBluData, loading: doBluLoading, reset: doBluReset }] = useMutation(ADD_REVIEW, {
        variables: {
            reviewAddInput: {
                isUHD,
                overrides: doBlu.selected.overrides,
                serviceName: "doBlu",
                url: doBlu.selected.url
            }
        }
    });
    const [addHidefDigest, { called: hidefDigestCalled, data: hidefDigestData, loading: hidefDigestLoading, reset: hidefDigestReset }] = useMutation(ADD_REVIEW, {
        variables: {
            reviewAddInput: {
                isUHD,
                overrides: hidefDigest.selected.overrides,
                serviceName: "hidefDigest",
                url: hidefDigest.selected.url
            }
        }
    });
    const [addHomeTheaterForum, { called: homeTheaterForumCalled, data: homeTheaterForumData, loading: homeTheaterForumLoading, reset: homeTheaterForumReset }] = useMutation(ADD_REVIEW, {
        variables: {
            reviewAddInput: {
                isUHD,
                overrides: homeTheaterForum.selected.overrides,
                serviceName: "homeTheaterForum",
                url: homeTheaterForum.selected.url
            }
        }
    });
    const [addTheaterByte, { called: theaterByteCalled, data: theaterByteData, loading: theaterByteLoading, reset: theaterByteReset }] = useMutation(ADD_REVIEW, {
        variables: {
            reviewAddInput: {
                isUHD,
                overrides: theaterByte.selected.overrides,
                serviceName: "theaterByte",
                url: theaterByte.selected.url
            }
        }
    });
    const [addWhySoBlu, { called: whySoBluCalled, data: whySoBluData, loading: whySoBluLoading, reset: whySoBluReset }] = useMutation(ADD_REVIEW, {
        variables: {
            reviewAddInput: {
                isUHD,
                overrides: whySoBlu.selected.overrides,
                serviceName: "whySoBlu",
                url: whySoBlu.selected.url
            }
        }
    });
    const areReviewsCalled = useMemo(() => avForumsCalled ||
        avNirvanaCalled ||
        avsForumCalled ||
        bluRayCalled ||
        bluRayAuthorityCalled ||
        digitalBitsCalled ||
        doBluCalled ||
        hidefDigestCalled ||
        homeTheaterForumCalled ||
        theaterByteCalled ||
        whySoBluCalled, [
        avForumsCalled,
        avNirvanaCalled,
        avsForumCalled,
        bluRayCalled,
        bluRayAuthorityCalled,
        digitalBitsCalled,
        doBluCalled,
        hidefDigestCalled,
        homeTheaterForumCalled,
        theaterByteCalled,
        whySoBluCalled
    ]);
    const areReviewsLoading = useMemo(() => avForumsLoading ||
        avNirvanaLoading ||
        avsForumLoading ||
        bluRayLoading ||
        bluRayAuthorityLoading ||
        digitalBitsLoading ||
        doBluLoading ||
        hidefDigestLoading ||
        homeTheaterForumLoading ||
        theaterByteLoading ||
        whySoBluLoading, [
        avForumsLoading,
        avNirvanaLoading,
        avsForumLoading,
        bluRayLoading,
        bluRayAuthorityLoading,
        digitalBitsLoading,
        doBluLoading,
        hidefDigestLoading,
        homeTheaterForumLoading,
        theaterByteLoading,
        whySoBluLoading
    ]);
    const resetReviews = useCallback(() => {
        if (avForumsData) {
            avForumsReset();
        }
        if (avNirvanaData) {
            avNirvanaReset();
        }
        if (bluRayData) {
            bluRayReset();
        }
        if (bluRayAuthorityData) {
            bluRayAuthorityReset();
        }
        if (digitalBitsData) {
            digitalBitsReset();
        }
        if (doBluData) {
            doBluReset();
        }
        if (hidefDigestData) {
            hidefDigestReset();
        }
        if (homeTheaterForumData) {
            homeTheaterForumReset();
        }
        if (theaterByteData) {
            theaterByteReset();
        }
        if (avsForumData) {
            avsForumReset();
        }
        if (whySoBluData) {
            whySoBluReset();
        }
    }, [
        avForumsData,
        avForumsReset,
        avNirvanaData,
        avNirvanaReset,
        avsForumData,
        avsForumReset,
        bluRayAuthorityData,
        bluRayAuthorityReset,
        bluRayData,
        bluRayReset,
        digitalBitsData,
        digitalBitsReset,
        doBluData,
        doBluReset,
        hidefDigestData,
        hidefDigestReset,
        homeTheaterForumData,
        homeTheaterForumReset,
        theaterByteData,
        theaterByteReset,
        whySoBluData,
        whySoBluReset
    ]);
    const onCreateReviews = useCallback(() => {
        createReviews({
            addAvForums,
            addAvNirvana,
            addAvsForum,
            addBluRay,
            addBluRayAuthority,
            addDigitalBits,
            addDoBlu,
            addHidefDigest,
            addHomeTheaterForum,
            addTheaterByte,
            addWhySoBlu,
            avForumsReady: Boolean(avForums.selected.url) && !homeVideoReview.avForumsId,
            avNirvanaReady: Boolean(avNirvana.selected.url) && !homeVideoReview.avNirvanaId,
            avsForumReady: Boolean(avsForum.selected.url) && !homeVideoReview.avsForumId,
            bluRayAuthorityReady: Boolean(bluRayAuthority.selected.url) &&
                !homeVideoReview.bluRayAuthorityId,
            bluRayReady: Boolean(bluRay.selected.url) && !homeVideoReview.bluRayId,
            digitalBitsReady: Boolean(digitalBits.selected.url) && !homeVideoReview.digitalBitsId,
            doBluReady: Boolean(doBlu.selected.url) && !homeVideoReview.doBluId,
            hidefDigestReady: Boolean(hidefDigest.selected.url) && !homeVideoReview.hidefDigestId,
            homeTheaterForumReady: Boolean(homeTheaterForum.selected.url) &&
                !homeVideoReview.homeTheaterForumId,
            theaterByteReady: Boolean(theaterByte.selected.url) && !homeVideoReview.theaterByteId,
            whySoBluReady: Boolean(whySoBlu.selected.url) && !homeVideoReview.whySoBluId
        });
    }, [
        addAvForums,
        addAvNirvana,
        addAvsForum,
        addBluRay,
        addBluRayAuthority,
        addDigitalBits,
        addDoBlu,
        addHidefDigest,
        addHomeTheaterForum,
        addTheaterByte,
        addWhySoBlu,
        avForums,
        avNirvana,
        avsForum,
        bluRay,
        bluRayAuthority,
        digitalBits,
        doBlu,
        hidefDigest,
        homeTheaterForum,
        theaterByte,
        homeVideoReview,
        whySoBlu
    ]);
    return useMemo(() => ({
        areReviewsCalled,
        areReviewsLoading,
        avForumsData,
        avNirvanaData,
        avsForumData,
        bluRayAuthorityData,
        bluRayData,
        digitalBitsData,
        doBluData,
        hidefDigestData,
        homeTheaterForumData,
        onCreateReviews,
        resetReviews,
        theaterByteData,
        whySoBluData
    }), [
        areReviewsCalled,
        areReviewsLoading,
        avForumsData,
        avNirvanaData,
        avsForumData,
        bluRayAuthorityData,
        bluRayData,
        digitalBitsData,
        doBluData,
        hidefDigestData,
        homeTheaterForumData,
        theaterByteData,
        onCreateReviews,
        resetReviews,
        whySoBluData
    ]);
};
export default useCreateReviewsContext;
