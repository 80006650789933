/* eslint-disable no-ternary */
import isNewsServiceName from "./isNewsServiceName";
import useNewsCritics from "hooks/useNewsCritics";
import useReviews from "hooks/useReviews";
const useStateByServiceName = (serviceName, serviceType) => {
    const newsCriticsContext = useNewsCritics();
    const reviewsContext = useReviews();
    const { state: newsCriticsState } = newsCriticsContext;
    const { state: reviewsState } = reviewsContext;
    const state = serviceType === "news" ? newsCriticsState : reviewsState;
    const stateByServiceName = serviceType === "news" && isNewsServiceName(serviceName)
        ? newsCriticsState[serviceName]
        : reviewsState[serviceName];
    return { state, stateByServiceName };
};
export default useStateByServiceName;
