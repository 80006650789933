import { gql } from "graphql-tag"

const eightLeggedFreak = gql`
  type Query {
    eightLeggedFreakNewsSourcesList(
      eightLeggedFreakNewsSourcesListInput: EightLeggedFreakNewsSourcesListInput!
    ): EightLeggedFreakNewsSourcesListResponse

    eightLeggedFreakList(
      eightLeggedFreakListInput: EightLeggedFreakListInput!
    ): EightLeggedFreakListResponse
  }
`

export default eightLeggedFreak
