import Checkbox from "components/Cards/Card/Actions/Checkbox"
import { ComponentProps } from "."

const IsMatchTitleCheckbox = ({
  checked,
  isDisabled,
  onChange
}: ComponentProps) => (
  <Checkbox
    checked={checked}
    disabled={isDisabled}
    inputId="isMatchTitle"
    label="Always Match Title in Lists"
    name="isMatchTitle"
    onChange={onChange}
  />
)

export default IsMatchTitleCheckbox
