/* eslint-disable max-lines-per-function, max-statements */
import SingleAdd from "./SingleAdd";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import { useState } from "react";
import useToggle from "@bluframe/grapple/useToggle";
const usePrepareComponent = () => {
    const [avForumsId, setAvForumsId] = useState("");
    const [avNirvanaId, setAvNirvanaId] = useState("");
    const [avsForumId, setAvsForumId] = useState("");
    const [bluRayAuthorityId, setBluRayAuthorityId] = useState("");
    const [bluRayId, setBluRayId] = useState("");
    const [digitalBitsId, setDigitalBitsId] = useState("");
    const [doBluId, setDoBluId] = useState("");
    const [hidefDigestId, setHidefDigestId] = useState("");
    const [homeTheaterForumId, setHomeTheaterForumId] = useState("");
    const [theaterByteId, setTheaterByteId] = useState("");
    const [preview, setPreview] = useState({});
    const [focusedService, setFocusedService] = useState(null);
    const [focusedData, setFocusedData] = useState(null);
    const [whySoBluId, setWhySoBluId] = useState("");
    const [isUHD, toggleIsUHD] = useToggle(false);
    const [name, setName] = useState("");
    return {
        avForumsId,
        avNirvanaId,
        avsForumId,
        bluRayAuthorityId,
        bluRayId,
        digitalBitsId,
        doBluId,
        focusedData,
        focusedService,
        hidefDigestId,
        homeTheaterForumId,
        isUHD,
        name,
        preview,
        setAvForumsId,
        setAvNirvanaId,
        setAvsForumId,
        setBluRayAuthorityId,
        setBluRayId,
        setDigitalBitsId,
        setDoBluId,
        // @ts-ignore
        setFocusedData,
        // @ts-ignore
        setFocusedService,
        setHidefDigestId,
        setHomeTheaterForumId,
        setName,
        setPreview,
        setTheaterByteId,
        setWhySoBluId,
        theaterByteId,
        toggleIsUHD,
        whySoBluId
    };
};
export default prepareComponent(usePrepareComponent)(SingleAdd);
