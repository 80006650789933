import { gql } from "graphql-tag"

const contests = gql`
  type Query {
    getContests: [Contest]
    getContest(id: ID!): Contest
  }
`

export default contests
