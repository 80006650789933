/* eslint-disable max-lines-per-function, max-statements */
import { useCallback, useEffect, useMemo, useState } from "react";
import Add from "./Add";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import useCreateReviews from "hooks/useCreateReviews";
import usePreviews from "hooks/usePreviews";
import useReviews from "hooks/useReviews";
import useUser from "hooks/useUser";
const DEFAULT_IS_COMPACT = true;
const usePrepareComponent = () => {
    const { removeImage } = usePreviews();
    const { clear, setIsMatchTitle, setIsUHD, setOverrides, state: { avForums, avNirvana, avsForum, bluRay, bluRayAuthority, digitalBits, doBlu, hidefDigest, homeTheaterForum, isMatchTitle, isUHD, homeVideoReview, theaterByte, whySoBlu } } = useReviews();
    const { isAdmin } = useUser();
    const [isCompact, setIsCompact] = useState(DEFAULT_IS_COMPACT);
    useEffect(() => {
        if (!isAdmin && isUHD !== false) {
            setIsUHD(false);
        }
    }, [isAdmin, isUHD, setIsUHD]);
    const { areReviewsLoading } = useCreateReviews();
    const onClear = useCallback((serviceName) => () => {
        // Remove image
        removeImage(serviceName);
        // Clear review
        clear(serviceName);
    }, [clear, removeImage]);
    const onSetOverrides = useCallback((serviceName) => (overrides) => {
        setOverrides(overrides, serviceName);
    }, [setOverrides]);
    const reviewsControlCheckboxesProps = useMemo(() => {
        const isMatchTitleCheckboxProps = {
            checked: isMatchTitle,
            isDisabled: areReviewsLoading,
            onChange: () => {
                setIsMatchTitle(!isMatchTitle);
            }
        };
        const isUHDCheckboxProps = {
            checked: isUHD,
            isDisabled: areReviewsLoading,
            onChange: () => {
                setIsUHD(!isUHD);
            }
        };
        const isCompactCheckboxProps = {
            checked: isCompact,
            isDisabled: areReviewsLoading,
            onChange: () => {
                setIsCompact(!isCompact);
            }
        };
        return {
            isAdmin,
            isCompactCheckboxProps,
            isMatchTitleCheckboxProps,
            isUHDCheckboxProps
        };
    }, [
        areReviewsLoading,
        isAdmin,
        isMatchTitle,
        setIsMatchTitle,
        isUHD,
        setIsUHD,
        isCompact,
        setIsCompact
    ]);
    return {
        areReviewsLoading,
        avForums: avForums.selected,
        avNirvana: avNirvana.selected,
        avsForum: avsForum.selected,
        bluRay: bluRay.selected,
        bluRayAuthority: bluRayAuthority.selected,
        digitalBits: digitalBits.selected,
        doBlu: doBlu.selected,
        hidefDigest: hidefDigest.selected,
        homeTheaterForum: homeTheaterForum.selected,
        homeVideoReview,
        onClear,
        onSetOverrides,
        reviewsControlCheckboxesProps,
        theaterByte: theaterByte.selected,
        whySoBlu: whySoBlu.selected
    };
};
export default prepareComponent(usePrepareComponent)(Add);
