/* eslint-disable max-lines-per-function, max-statements */
import { useCallback, useEffect, useMemo } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import GET_OMDB_ID from "queries/getOmdbId";
import Reviews from "./Reviews";
import { mutations } from "@bluframe/mightychroma-mightytool-graphql-schema";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import useEightLeggedFreakList from "hooks/useEightLeggedFreakList";
import usePreviews from "hooks/usePreviews";
import useReviews from "hooks/useReviews";
import useUser from "hooks/useUser";
const { critics: { ADD_PREVIEW }, eightLeggedFreak: { EIGHT_LEGGED_FREAK_REVIEW_UPDATE } } = mutations;
const ZERO = 0;
const THREE = 3;
const usePrepareComponent = ({ actions, name, serviceName, setOMDbId }) => {
    const { addImage } = usePreviews();
    const { setIsToggleFlaggedLoading, setSelectedId, setSelectedIsPreviewLoading, setSelectedIsPreviewValid, state } = useReviews();
    const { isAdmin } = useUser();
    const serviceState = useMemo(() => state[serviceName], [state, serviceName]);
    const [addPreview, { data: addPreviewData, loading: addPreviewLoading }] = useMutation(ADD_PREVIEW, {
        onCompleted: (data) => {
            if (data.addPreview.images?.front) {
                // Add image to image selector
                addImage({
                    serviceName,
                    url: data.addPreview.images.front
                });
            }
        }
    });
    const [getOmdbId] = useLazyQuery(GET_OMDB_ID, {
        onCompleted: (omdbIdData) => {
            if (setOMDbId && omdbIdData?.omdbId) {
                setOMDbId(omdbIdData.omdbId);
            }
        }
    });
    const { refetch } = useEightLeggedFreakList(serviceName);
    const [eightLeggedFreakReviewUpdate, { loading: flagLoading }] = useMutation(EIGHT_LEGGED_FREAK_REVIEW_UPDATE);
    useEffect(() => {
        setIsToggleFlaggedLoading(flagLoading);
        // It just goes into infinite loop if we include
        // setIsToggleFlaggedLoading in the dependency array
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flagLoading]);
    useEffect(() => {
        if (serviceState.selected.url) {
            addPreview({
                variables: {
                    previewAddInput: {
                        isUHD: state.isUHD,
                        overrides: serviceState.selected.overrides,
                        serviceName,
                        url: serviceState.selected.url
                    }
                }
            });
        }
    }, [
        addPreview,
        serviceName,
        serviceState.selected.overrides,
        serviceState.selected.url,
        state.isUHD
    ]);
    useEffect(() => {
        if (addPreviewData) {
            setSelectedIsPreviewValid(addPreviewData.addPreview.isValid, serviceName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addPreviewData]);
    useEffect(() => {
        setSelectedIsPreviewLoading(addPreviewLoading, serviceName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addPreviewLoading]);
    const display = useMemo(() => {
        if (serviceState.isOnlyNew) {
            return serviceState.display.filter(({ isNew }) => isNew);
        }
        if (serviceState.isOnlyPresent) {
            return serviceState.display.filter(({ isNew }) => !isNew);
        }
        return serviceState.display;
    }, [serviceState.display, serviceState.isOnlyNew, serviceState.isOnlyPresent]);
    const filteredDisplay = useMemo(() => {
        if (serviceState.isShowFlagged) {
            return display;
        }
        return display.filter(({ isFlagged }) => !isFlagged);
    }, [display, serviceState.isShowFlagged]);
    // @ts-ignore
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const subheader = useMemo(() => {
        if (serviceState.isOnlyNew) {
            return `Total New: ${filteredDisplay.length}`;
        }
        if (serviceState.isOnlyPresent) {
            return `Total Present: ${filteredDisplay.length}`;
        }
        return `Total Available: ${filteredDisplay.length}`;
    });
    // @ts-ignore
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const title = useMemo(() => `${name} Reviews`);
    // @ts-ignore
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const avatar = useMemo(() => name.substr(ZERO, THREE));
    const onToggleFlagged = useCallback((id, isFlagged) => () => {
        eightLeggedFreakReviewUpdate({
            onCompleted: () => {
                refetch({
                    eightLeggedFreakListInput: {
                        isUHD: state.isUHD,
                        serviceName
                    }
                });
            },
            variables: {
                eightLeggedFreakReviewInput: {
                    id,
                    isFlagged: !isFlagged
                },
                serviceName
            }
        });
    }, [eightLeggedFreakReviewUpdate, refetch, serviceName, state.isUHD]);
    const onSelect = useCallback((ev) => {
        setSelectedId(ev.currentTarget.value, serviceName);
        if (setOMDbId) {
            getOmdbId({
                variables: {
                    bluRayId: ev.currentTarget.value
                }
            });
        }
    }, [getOmdbId, serviceName, setSelectedId, setOMDbId]);
    return {
        actions,
        avatar,
        display: filteredDisplay,
        flagLoading,
        isShowFlagButton: isAdmin,
        onSelect,
        onToggleFlagged,
        selected: serviceState.selected.id,
        serviceName,
        subheader,
        title
    };
};
export default prepareComponent(usePrepareComponent)(Reviews);
