import {
  HomeVideoReviewAddInput,
  ReviewOverrides,
  ReviewsServiceName,
  SelectedReview
} from "@bluframe/mightychroma-mightytool-frontend-types"
import HeaderPods from "./HeaderPods"

export interface Props {
  areReviewsLoading: boolean
  avForums: SelectedReview
  avNirvana: SelectedReview
  avsForum: SelectedReview
  bluRay: SelectedReview
  bluRayAuthority: SelectedReview
  digitalBits: SelectedReview
  doBlu: SelectedReview
  hidefDigest: SelectedReview
  homeTheaterForum: SelectedReview
  homeVideoReview: HomeVideoReviewAddInput
  isCompact: boolean
  left: React.ReactNode
  // eslint-disable-next-line no-unused-vars
  onClear: (serviceName: ReviewsServiceName) => () => void
  onSetOverrides: (
    // eslint-disable-next-line no-unused-vars
    serviceName: ReviewsServiceName
    // eslint-disable-next-line no-unused-vars
  ) => (overrides: ReviewOverrides) => void
  theaterByte: SelectedReview
  whySoBlu: SelectedReview
}

export interface ComponentProps extends Props {}

export default HeaderPods
