import AddActions from "./AddActions"
import { ContentAuthor } from "@bluframe/mightychroma-mightytool-frontend-types"
import { Props as ImageSelectorProps } from "components/ImageSelector"
import { Props as SelectProps } from "components/inputs/Select"

export interface Props {
  contentAuthors: ContentAuthor[]
  imageSelector: ImageSelectorProps
  isAddNewsEnabled: boolean
  isAdmin: boolean
  label: string
  loading: boolean
  onAddNews: () => void
  onClearAll: () => void
  onContentAuthorSelect: SelectProps["onChange"]
  // eslint-disable-next-line no-unused-vars
  onNameChange: (name: string) => void
  name?: string
}

export interface ComponentProps extends Props {}

export default AddActions
