/* eslint-disable max-lines-per-function, max-statements, no-undefined */
import { getIsAddNewsEnabled, getIsNewsReadyToBeCreated } from "./helpers";
import { useContext, useEffect, useMemo } from "react";
import Context from "contexts/MightyTool";
import { DEFAULT_NEWS } from "contexts/NewsCritics";
import equals from "ramda/src/equals";
import useAddEditNews from "hooks/useAddEditNews";
import useCreateNewsCritics from "hooks/useCreateNewsCritics";
import useNewsCritics from "hooks/useNewsCritics";
import usePreviewsNewsCritics from "hooks/usePreviewsNewsCritics";
const useAddNews = (props) => {
    const { resetImages } = usePreviewsNewsCritics();
    const { addNews: { addNews, called, data, loading: addNewsLoading, reset } } = useContext(Context);
    // NewsCritics State
    const { resetSelectedNewsCritics, state: { avNirvana, bluRay, hidefDigest, news, whySoBlu } } = useNewsCritics();
    // Create NewsCritics State
    const { areNewsSourcesCalled, areNewsSourcesLoading, onCreateNewsSource, resetNewsSources } = useCreateNewsCritics();
    // Clear All
    const { onClearAll } = useAddEditNews();
    // Are all News properties ready?
    const isNewsReadyToBeCreated = useMemo(() => getIsNewsReadyToBeCreated({
        avNirvana: avNirvana.selected,
        bluRay: bluRay.selected,
        hidefDigest: hidefDigest.selected,
        news,
        whySoBlu: whySoBlu.selected
    }), [
        news,
        avNirvana.selected,
        bluRay.selected,
        hidefDigest.selected,
        whySoBlu.selected
    ]);
    // If reviews are called and News is ready to be created and
    // create hasn't been called then create a Mighty Chroma
    useEffect(() => {
        if (props?.isReadOnly) {
            return;
        }
        if (areNewsSourcesCalled) {
            if (isNewsReadyToBeCreated && !called) {
                addNews({
                    variables: {
                        news: {
                            ...news,
                            isPublished: false
                        }
                    }
                });
            }
        }
    }, [
        addNews,
        areNewsSourcesCalled,
        called,
        isNewsReadyToBeCreated,
        news,
        props?.isReadOnly
    ]);
    // If News has been created then reset reviews
    useEffect(() => {
        if (props?.isReadOnly) {
            return;
        }
        if (data) {
            if (!equals(news, DEFAULT_NEWS)) {
                resetSelectedNewsCritics();
                reset();
            }
            // Reset images
            resetImages();
            // Reset news critics
            resetNewsSources();
        }
    }, [
        data,
        news,
        props?.isReadOnly,
        reset,
        resetImages,
        resetNewsSources,
        resetSelectedNewsCritics
    ]);
    // Are we creating reviews or a Mighty Chroma?
    const loading = useMemo(() => addNewsLoading || areNewsSourcesLoading, [addNewsLoading, areNewsSourcesLoading]);
    // Are all News properties ready for the create button to be enabled?
    const isAddNewsEnabled = useMemo(() => getIsAddNewsEnabled({
        news,
        services: {
            avNirvana: avNirvana.selected,
            bluRay: bluRay.selected,
            hidefDigest: hidefDigest.selected,
            whySoBlu: whySoBlu.selected
        }
    }) && !loading, [
        avNirvana.selected,
        bluRay.selected,
        hidefDigest.selected,
        loading,
        news,
        whySoBlu.selected
    ]);
    return {
        isAddNewsEnabled,
        loading,
        name: news.name,
        onAddNews: onCreateNewsSource,
        onClearAll
    };
};
export default useAddNews;
