import { gql } from "graphql-tag"

const news = gql`
  input NewsAddInput {
    "Author Id"
    authorId: ID!
    "AVForums Id"
    avForumsId: ID
    "AVNirvana Id"
    avNirvanaId: ID
    "AVSForum Id"
    avsForumId: ID
    "BluRay Id"
    bluRayId: ID
    "BluRayAuthority Id"
    bluRayAuthorityId: ID
    "Digital Bits Id"
    digitalBitsId: ID
    "Do Blu Id"
    doBluId: ID
    "HiDefDigest Id"
    hidefDigestId: ID
    "HomeTheaterForum Id"
    homeTheaterForumId: ID
    "Image Service Name"
    imageServiceName: String!
    "Is it Published"
    isPublished: Boolean!
    "Name"
    name: String
    "Slug"
    slug: String
    "Text"
    text: String
    "TheaterByte Id"
    theaterByteId: ID
    "Why So Blu? Id"
    whySoBluId: ID
  }

  type NewsAddResponse {
    "Status"
    status: String!
  }

  type PublishDraftNewsResponse {
    "IDs"
    ids: [ID!]!
  }

  input NewsUpdateInput {
    "Autor Id"
    authorId: ID
    "AVForums Id"
    avForumsId: ID
    "AVNirvana Id"
    avNirvanaId: ID
    "AVSForum Id"
    avsForumId: ID
    "BluRay Id"
    bluRayId: ID
    "BluRayAuthority Id"
    bluRayAuthorityId: ID
    "Digital Bits Id"
    digitalBitsId: ID
    "Do Blu Id"
    doBluId: ID
    "HiDefDigest Id"
    hidefDigestId: ID
    "HomeTheaterForum Id"
    homeTheaterForumId: ID
    "ID"
    id: ID!
    "Image Service Name"
    imageServiceName: String
    "Is it Published"
    isPublished: Boolean
    "Name"
    name: String
    "Slug"
    slug: String
    "Subheading"
    subheading: String
    "Text"
    text: String
    "TheaterByte Id"
    theaterByteId: ID
    "Why So Blu? Id"
    whySoBluId: ID
  }

  type NewsUpdateResponse {
    "News Id"
    id: ID!
  }

  type News {
    "Author"
    author: ContentAuthor
    "AVForums News Source"
    avForums: NewsSource
    "AVNirvana News Source"
    avNirvana: NewsSource
    "AVSForum News Source"
    avsForum: NewsSource
    "Blu-ray.com News Source"
    bluRay: NewsSource
    "Blu-ray Authority News Source"
    bluRayAuthority: NewsSource
    "Digital Bits News Source"
    digitalBits: NewsSource
    "Do Blu News Source"
    doBlu: NewsSource
    "HighDefDigest News Source"
    hidefDigest: NewsSource
    "HomeTheaterForum News Source"
    homeTheaterForum: NewsSource
    "Id"
    id: ID!
    "Image"
    image: Image
    "Image Service Name"
    imageServiceName: String!
    "Is Published?"
    isPublished: Boolean!
    "Name"
    name: String!
    "Published At"
    publishedAt: String
    "Slug"
    slug: String!
    "Subheading"
    subheading: String!
    "Text"
    text: String!
    "TheaterByte News Source"
    theaterByte: NewsSource
    "Why So Blu? News Source"
    whySoBlu: NewsSource
  }

  type NewsWave {
    cursor: String
    hasPrevData: Boolean
    hasNextData: Boolean!
    news: [News]!
    prevCursor: String
  }

  input SearchNewsInput {
    name: String
  }

  input NewsSourceAddInput {
    isOverridesOnly: Boolean
    overrides: NewsSourceOverridesInput
    serviceName: String!
    url: String!
  }

  type NewsSource {
    id: ID!
    image: String
    isFlagged: Boolean!
    name: String
    serviceName: String!
    text: String
    url: String!
  }

  input NewsSourceOverridesInput {
    image: String
    name: String
    text: String
  }

  input NewsSourceUpdateInput {
    id: ID!
    isFlagged: Boolean
    name: String
    overrides: NewsSourceOverridesInput
    text: String
    url: String
  }

  type NewsSourceResponse {
    id: ID!
    serviceName: String!
  }

  input PreviewNewsSourceAddInput {
    overrides: NewsSourceOverridesInput
    serviceName: String!
    url: String!
  }

  type PreviewNewsSource {
    errorFields: [String]
    image: String
    isValid: Boolean!
    name: String
    text: String
    url: String!
  }

  type EightLeggedFreakNewsSource {
    id: ID!
    isFlagged: Boolean!
    isNew: Boolean!
    name: String!
    url: String!
  }

  input EightLeggedFreakGatherNewsSourcesInput {
    isAllAroundGather: Boolean
    serviceName: String!
  }

  input EightLeggedFreakNewsSourcesListInput {
    cursor: String
    serviceName: String!
  }

  input EightLeggedFreakNewsSourceInput {
    id: ID!
    isFlagged: Boolean
    name: String
    url: String
  }

  type EightLeggedFreakGatherNewsSources {
    status: String!
  }

  type EightLeggedFreakNewsSourcesListResponse {
    links: [EightLeggedFreakNewsSource]
    nextCursor: String
  }

  type EightLeggedFreakNewsSourceResponse {
    id: ID!
  }
`

export default news
