import { gql } from "graphql-tag"

const blog = gql`
  type Mutation {
    addPlatform(
      platform: PlatformAddInput!
      socialLinks: SocialLinksAddInput
    ): PlatformAddResponse!

    createCharacter(characterInput: CharacterAddInput!): CharacterAddResponse!

    generateBlogRoundup(
      blogRoundupGenerateInput: BlogRoundupGenerateInput!
    ): BlogRoundupGenerateResponse!

    updateCharacter(
      characterInput: CharacterUpdateInput!
    ): CharacterUpdateResponse!
  }
`

export default blog
