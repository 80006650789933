import {
  NewsSource,
  Review
} from "@bluframe/mightychroma-mightytool-frontend-types"
import List from "./List"

export interface Props {
  display: NewsSource[] | Review[]
  flagLoading: boolean
  isShowFlagButton: boolean
  label: string
  name: string
  onSelect: () => void
  // eslint-disable-next-line no-unused-vars
  onToggleFlagged: (id: string, isFlagged: boolean) => () => void
  selected: string
}

export interface ComponentProps extends Props {}

export default List
