import { Theme } from "@bluframe/blublocks"
import styled from "styled-components"

export const ModalContent = styled.div`
  background: ${({ theme }: { theme: Theme }) => theme.palette.text.contrast};
  display: grid;
  padding: 20px;
  row-gap: 20px;
`

export const ModalTitle = styled.h2`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.0075em;
  line-height: 1.6;
  margin: 0;
  text-align: center;
`
