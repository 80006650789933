import getIsServiceCreated from "./getIsServiceCreated";
/**
 * Determine if News is ready to be updated based on the input properties
 * of various reviews and an ID.
 * @function getIsNewsReadyToBeUpdated
 * @param {NewsUpdatedProps} props - An object containing review properties
 * and News ID.
 * @return {boolean} - If true, News is considered ready to be updated; otherwise, false.
 */
const getIsNewsReadyToBeUpdated = ({ avNirvana, bluRay, hidefDigest, id, news, whySoBlu }) => 
// Check if id exists, and if each review service is valid
// by calling getIsServiceCreated with the respective review's url and ID
Boolean(id) &&
    getIsServiceCreated(avNirvana.url, news.avNirvanaId) &&
    getIsServiceCreated(bluRay.url, news.bluRayId) &&
    getIsServiceCreated(hidefDigest.url, news.hidefDigestId) &&
    getIsServiceCreated(whySoBlu.url, news.whySoBluId);
export default getIsNewsReadyToBeUpdated;
