/* eslint-disable no-ternary, max-lines-per-function */

import { Props as ItemProps } from "./Item"

export interface Item
  extends Omit<
    ItemProps,
    "isAdmin" | "isExpanded" | "isShowSubItems" | "onSetOpenSubItems"
  > {
  isAdmin?: boolean
}

const items: Item[] = [
  {
    iconGroup: "fa6",
    iconName: "FaHouse",
    label: "Home",
    link: "/"
  },
  {
    iconGroup: "fa6",
    iconName: "FaRegFileLines",
    label: "Home Video Reviews",
    link: "/home-video-reviews",
    subItems: [
      { label: "Add", link: "/home-video-reviews/add" },
      { isAdmin: true, label: "Edit", link: "/home-video-reviews/edit" },
      {
        isAdmin: true,
        label: "Single Add",
        link: "/home-video-reviews/single-add"
      }
    ]
  },
  {
    iconGroup: "fa6",
    iconName: "FaRegNewspaper",
    label: "News",
    link: "/news",
    subItems: [
      { label: "Add", link: "/news/add" },
      { isAdmin: true, label: "Edit", link: "/news/edit" }
    ]
  },
  {
    iconGroup: "fa6",
    iconName: "FaRegBookmark",
    isAdmin: true,
    label: "Drafts",
    link: "/drafts",
    subItems: [
      { label: "Home Video Reviews", link: "/drafts/home-video-reviews" },
      { label: "News", link: "/drafts/news" }
    ]
  },
  {
    iconGroup: "fa6",
    iconName: "FaRegEnvelope",
    isAdmin: true,
    label: "E-Mails",
    link: "/emails",
    subItems: [
      { label: "Headings", link: "/emails/headings" },
      { label: "Footers", link: "/emails/footers" },
      { label: "Templates", link: "/emails/templates" }
    ]
  },
  {
    iconGroup: "fa6",
    iconName: "FaTrophy",
    isAdmin: true,
    label: "Contests",
    link: "/contests"
  },
  {
    iconGroup: "fa6",
    iconName: "FaRegCommentDots",
    isAdmin: true,
    label: "Forum",
    link: "/forum",
    subItems: [
      { label: "Categories", link: "/forum/categories" },
      { label: "Sections", link: "/forum/sections" }
    ]
  },
  {
    iconGroup: "fa6",
    iconName: "FaEnvelopeOpenText",
    isAdmin: true,
    label: "Newsletters",
    link: "/newsletters"
  },
  {
    iconGroup: "fa6",
    iconName: "FaRegMap",
    isAdmin: true,
    label: "Eight Legged Freak",
    link: "/eightleggedfreak",
    subItems: [
      { label: "News", link: "/eightleggedfreak/news" },
      { label: "Reviews", link: "/eightleggedfreak/reviews" }
    ]
  },
  {
    iconGroup: "fa6",
    iconName: "FaBlog",
    isAdmin: true,
    label: "Blog",
    link: "/blog",
    subItems: [{ label: "Roundup", link: "/blog/roundup" }]
  },
  {
    iconGroup: "fa6",
    iconName: "FaRegImages",
    isAdmin: true,
    label: "Images Grid",
    link: "/images-grid"
  },
  {
    iconGroup: "fa6",
    iconName: "FaFilm",
    isAdmin: true,
    label: "Movies and Shows",
    link: "/moviesandshows",
    subItems: [
      { label: "Movies", link: "/moviesandshows/movies/add" },
      { label: "TV Shows", link: "/moviesandshows/shows" }
    ]
  }
]

export default items
