/* eslint-disable no-empty-function */
import { mutations } from "@bluframe/mightychroma-mightytool-graphql-schema";
import { useMemo } from "react";
import { useMutation } from "@apollo/client";
const { homeVideoReviews: { ADD_HOME_VIDEO_REVIEW } } = mutations;
export const initialContext = {
    addHomeVideoReview: () => { },
    called: false,
    data: undefined,
    loading: false,
    reset: () => { }
};
const useAddHomeVideoReviewContext = () => {
    const [addHomeVideoReview, { called, data, loading, reset }] = useMutation(ADD_HOME_VIDEO_REVIEW);
    return useMemo(() => ({
        addHomeVideoReview,
        called,
        data,
        loading,
        reset
    }), [addHomeVideoReview, called, data, loading, reset]);
};
export default useAddHomeVideoReviewContext;
