import gql from "graphql-tag"

const ADD_NEWS = gql`
  mutation addNews($news: NewsAddInput!) {
    addNews(news: $news) {
      status
    }
  }
`
export default ADD_NEWS
