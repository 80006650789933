/* eslint-disable max-lines-per-function */
import getIsServiceValid from "./getIsServiceValid";
/**
 * Determine if the News update functionality should be enabled
 * based on the given input properties of various reviews and an ID.
 * @function getIsUpdateNewsEnabled
 * @param {NewsEnabledProps} props - An object containing review properties
 * and News ID.
 * @returns {boolean} - If true, the News update functionality is considered enabled; otherwise, false.
 */
const getIsUpdateNewsEnabled = ({ avNirvana, bluRay, hidefDigest, id, news, whySoBlu }) => Boolean(id) &&
    getIsServiceValid(avNirvana.url, avNirvana.isPreviewValid, news.avNirvanaId) &&
    getIsServiceValid(bluRay.url, bluRay.isPreviewValid, news.bluRayId) &&
    getIsServiceValid(hidefDigest.url, hidefDigest.isPreviewValid, news.hidefDigestId) &&
    getIsServiceValid(whySoBlu.url, whySoBlu.isPreviewValid, news.whySoBluId);
export default getIsUpdateNewsEnabled;
