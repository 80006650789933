import {
  NewsSource,
  Review,
  ReviewsServiceName
} from "@bluframe/mightychroma-mightytool-frontend-types"
import Content from "./Content"

export interface Props {
  avatar: string
  display: NewsSource[] | Review[]
  flagLoading: boolean
  isShowFlagButton: boolean
  label: string
  name: string
  onSelect: () => void
  // eslint-disable-next-line no-unused-vars
  onToggleFlagged: (id: string, isFlagged: boolean) => () => void
  selected: string
  serviceName: ReviewsServiceName
  subheader: string
  title: string
}

export interface ComponentProps extends Props {}

export default Content
