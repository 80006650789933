/* eslint-disable no-empty-function */
import { useCallback, useMemo, useState } from "react";
const DEFAULT_IMAGES = [];
export const initialContext = {
    addImage: () => { },
    images: DEFAULT_IMAGES,
    removeImage: () => { },
    resetImages: () => { }
};
const usePreviewsContext = () => {
    const [images, setImages] = useState(DEFAULT_IMAGES);
    const addImage = useCallback((image) => {
        setImages((prevImages) => {
            if (!prevImages.some((item) => item.serviceName === image.serviceName)) {
                return [...prevImages, image];
            }
            return prevImages;
        });
    }, []);
    const removeImage = useCallback((serviceName) => {
        setImages((prevImages) => prevImages.filter((item) => item.serviceName !== serviceName));
    }, []);
    const resetImages = useCallback(() => {
        setImages(DEFAULT_IMAGES);
    }, []);
    return useMemo(() => ({
        addImage,
        images,
        removeImage,
        resetImages
    }), [addImage, images, removeImage, resetImages]);
};
export default usePreviewsContext;
