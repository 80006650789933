import { gql } from "graphql-tag"

const query = gql`
  type Query {
    getCharacter(id: ID!): Character
    getCharacters: [Character]
  }
`

export default query
