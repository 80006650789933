import Main from "./Main";
import React from "react";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import useUser from "hooks/useUser";
const usePrepareComponent = () => {
    const { isAdmin } = useUser();
    return {
        isAdmin
    };
};
export default prepareComponent(usePrepareComponent)(React.memo(Main));
