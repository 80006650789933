import { gql } from "graphql-tag"

const content = gql`
  type ContentAuthor {
    "Id"
    id: ID!
    "Name"
    name: String!
    "Slug"
    slug: String!
  }
`

export default content
