import gql from "graphql-tag"

const GET_EIGHT_LEGGED_FREAK_LIST = gql`
  query getEightLeggedFreakList(
    $eightLeggedFreakListInput: EightLeggedFreakListInput!
  ) {
    eightLeggedFreakList(
      eightLeggedFreakListInput: $eightLeggedFreakListInput
    ) {
      links {
        id
        isFlagged
        isNew
        title
        url
      }
      nextCursor
    }
  }
`
export default GET_EIGHT_LEGGED_FREAK_LIST
