import AddActions from "./AddActions"
import { Props as ImageSelectorProps } from "components/ImageSelector"

export type Props = {
  OMDbId?: string
  imageSelector: ImageSelectorProps
  isAddHomeVideoReviewEnabled: boolean
  isAdmin: boolean
  isOmdbIdValid: boolean
  label: string
  loading: boolean
  onAddHomeVideoReview: () => void
  onClearAll: () => void
  // eslint-disable-next-line no-unused-vars
  onOmdbIdChange: (OMDbId: string) => void
  // eslint-disable-next-line no-unused-vars
  onNameChange: (name: string) => void
  name?: string
}

export interface ComponentProps extends Props {}

export default AddActions
