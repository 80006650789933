/* eslint-disable max-lines-per-function */
import LoadMore from "./LoadMore";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import { useCallback } from "react";
import useEightLeggedFreakList from "hooks/useEightLeggedFreakList";
import useEightLeggedFreakNewsCriticsList from "hooks/useEightLeggedFreakNewsCriticsList";
import useStateByServiceName from "helpers/getStateByServiceName";
const usePrepareComponent = ({ serviceName, serviceType }) => {
    const { fetchMore: reviewsFetchMore, isUHD, loading: reviewsLoading } = useEightLeggedFreakList(serviceName);
    const { fetchMore: newsCriticsFetchMore, loading: newsCriticsLoading } = useEightLeggedFreakNewsCriticsList(serviceName);
    const { stateByServiceName } = useStateByServiceName(serviceName, serviceType);
    const onLoadMore = useCallback(() => {
        if (serviceType === "news") {
            newsCriticsFetchMore({
                variables: {
                    eightLeggedFreakNewsSourcesListInput: {
                        cursor: stateByServiceName.nextCursor ?? undefined,
                        serviceName: serviceName
                    }
                }
            });
        }
        if (serviceType === "reviews") {
            reviewsFetchMore({
                variables: {
                    eightLeggedFreakListInput: {
                        cursor: stateByServiceName.nextCursor ?? undefined,
                        isUHD,
                        serviceName
                    }
                }
            });
        }
    }, [
        newsCriticsFetchMore,
        reviewsFetchMore,
        isUHD,
        serviceName,
        serviceType,
        stateByServiceName.nextCursor
    ]);
    return {
        isDisabled: !stateByServiceName.nextCursor || newsCriticsLoading || reviewsLoading,
        loading: newsCriticsLoading || reviewsLoading,
        onLoadMore
    };
};
export default prepareComponent(usePrepareComponent)(LoadMore);
