import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-lines-per-function */
import { Row, Wrapper } from "./styled";
import Gather from "components/Cards/Actions/Gather";
import List from "components/Cards/Actions/List";
import News from "components/News";
import OnlyNew from "components/Cards/Actions/OnlyNew";
import config from "config/site";
const EightLeggedFreakNews = () => (_jsx(Wrapper, { children: config.news.map((item) => (_jsx(Row, { children: item.rows.map((row) => (_jsx(News, { actions: _jsxs(_Fragment, { children: [_jsx(Gather, { serviceName: row.serviceName, serviceType: "news" }), _jsx(List, { serviceName: row.serviceName, serviceType: "news" }), _jsx(OnlyNew, { serviceName: row.serviceName, serviceType: "news" })] }), name: row.name, serviceName: row.serviceName }, row.serviceName))) }, item.id))) }));
export default EightLeggedFreakNews;
