/* eslint-disable no-ternary */

import Checkbox from "components/Cards/Card/Actions/Checkbox"
import { ComponentProps } from "."

const IsUHDCheckbox = ({ checked, isDisabled, onChange }: ComponentProps) => (
  <Checkbox
    checked={checked}
    disabled={isDisabled}
    inputId="isUHD"
    label={`Editing ${checked ? "4K UHD " : ""}Blu-rays`}
    name="isUHD"
    onChange={onChange}
  />
)

export default IsUHDCheckbox
