import gql from "graphql-tag"

const EIGHT_LEGGED_FREAK_NEWS_SOURCES_GATHER = gql`
  mutation eightLeggedFreakNewsSourcesGather(
    $eightLeggedFreakNewsSourcesGatherInput: EightLeggedFreakNewsSourcesGatherInput!
  ) {
    eightLeggedFreakNewsSourcesGather(
      eightLeggedFreakNewsSourcesGatherInput: $eightLeggedFreakNewsSourcesGatherInput
    ) {
      status
    }
  }
`
export default EIGHT_LEGGED_FREAK_NEWS_SOURCES_GATHER
