import {
  NewsSource,
  ReviewsServiceName
} from "@bluframe/mightychroma-mightytool-frontend-types"
import NewsCard from "./NewsCard"

export interface Props {
  actions: React.ReactNode
  avatar: string
  display: NewsSource[]
  flagLoading: boolean
  isShowFlagButton: boolean
  onSelect: () => void
  // eslint-disable-next-line no-unused-vars
  onToggleFlagged: (id: string, isFlagged: boolean) => () => void
  selected: string
  serviceName: ReviewsServiceName
  subheader: string
  title: string
}

export interface ComponentProps extends Props {}

export default NewsCard
