/* eslint-disable max-statements, max-lines-per-function */
import { useState } from "react";
import { handleAdd, handleGetAll, handlePreview, handleUpdateFromUrl } from "./handlers";
import { useLazyQuery, useMutation } from "@apollo/client";
import GET_REVIEWS from "queries/getReviews";
import Source from "./Source";
import { mutations } from "@bluframe/mightychroma-mightytool-graphql-schema";
import prepareComponent from "@bluframe/grapple/prepareComponent";
const { critics: { ADD_PREVIEW, ADD_REVIEW, UPDATE_CRITICS_REVIEW_FROM_URL } } = mutations;
const handleChangeId = 
// eslint-disable-next-line no-unused-vars
(setId) => (ev) => {
    ev.preventDefault();
    setId(ev.currentTarget.value);
};
const handleChangeUrl = 
// eslint-disable-next-line no-unused-vars
(setUrl) => (ev) => {
    ev.preventDefault();
    setUrl(ev.currentTarget.value);
};
const usePrepareComponent = (props) => {
    const [url, setUrl] = useState("");
    const [id, setId] = useState("");
    const [addPreview, { data: addPreviewData, loading: addPreviewLoading }] = useMutation(ADD_PREVIEW, {
        variables: {
            previewAddInput: { isUHD: props.isUHD, serviceName: props.name, url }
        }
    });
    const [getReviews, { data: reviewsData, loading: getReviewsLoading }] = useLazyQuery(GET_REVIEWS, {
        variables: { serviceName: props.name, url }
    });
    const [addReview, { data: reviewData, loading: addReviewLoading }] = useMutation(ADD_REVIEW, {
        variables: {
            reviewAddInput: { isUHD: props.isUHD, serviceName: props.name, url }
        }
    });
    const [updateReviewFromUrl, { data: updateReviewFromUrlData, loading: updateReviewFromUrlLoading }] = useMutation(UPDATE_CRITICS_REVIEW_FROM_URL, {
        variables: {
            id,
            serviceName: props.name,
            url
        }
    });
    const loadingMessage = `Loading: ${url}...`;
    if (props.focusedService === props.name) {
        if ((addPreviewLoading || addReviewLoading || getReviewsLoading) &&
            props.preview.message !== loadingMessage) {
            props.setPreview({ message: loadingMessage });
        }
        if (props.focusedData === "preview" && addPreviewData) {
            props.setPreview(addPreviewData.addPreview);
        }
        if (props.focusedData === "createReview" && reviewData) {
            // Need to be able to set review there. Check types
            props.setPreview(reviewData.addReview);
            props.setHomeVideoReviewId(reviewData.addReview.id);
        }
        if (props.focusedData === "reviews" && reviewsData) {
            props.setPreview(reviewsData.reviews);
        }
        if (props.focusedData === "updateFromUrl" && updateReviewFromUrlData) {
            props.setPreview({
                message: `Updating Critics Review from URL: ${url}...`
            });
        }
    }
    return {
        ...props,
        createPreviewLoading: addPreviewLoading,
        createReviewLoading: addReviewLoading,
        getReviewsLoading,
        id,
        onAdd: handleAdd(props, addReview),
        onChangeId: handleChangeId(setId),
        onChangeUrl: handleChangeUrl(setUrl),
        onGetAll: handleGetAll({ ...props, getReviews }),
        onPreview: handlePreview(props, addPreview),
        onUpdate: handleUpdateFromUrl(props, updateReviewFromUrl, url),
        setUrl,
        updateReviewFromUrlLoading,
        url
    };
};
export default prepareComponent(usePrepareComponent)(Source);
