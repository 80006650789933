/* eslint-disable no-ternary */

import {
  Left,
  Middle,
  Preview,
  Previews,
  ServiceName,
  Title,
  Wrapper
} from "./styled"
import Button from "components/buttons/Button"
import { Checkbox } from "@bluframe/blublocks"
import { ComponentProps } from "."
import serviceNameLabels from "config/serviceNameLabels"

const Draft = ({
  id,
  isChecked,
  isLoading,
  onChange,
  onPublish,
  previews,
  title
}: ComponentProps) => (
  <Wrapper>
    <Left>
      <Checkbox
        inputId={id}
        isChecked={isChecked}
        label=""
        name={title}
        onChange={onChange}
        value={id}
      />
    </Left>
    <Middle>
      <Title>{title}</Title>
      <Previews>
        {previews.map((preview) => (
          <Preview key={preview.url}>
            {preview.image ? (
              <img
                alt={serviceNameLabels[preview.serviceName]}
                src={preview.image}
              />
            ) : null}
            <ServiceName as="a" href={preview.url} target="__blank">
              {serviceNameLabels[preview.serviceName]}
            </ServiceName>
          </Preview>
        ))}
      </Previews>
    </Middle>
    <Button
      disabled={isLoading}
      label="Publish"
      loading={isLoading}
      onClick={onPublish}
    />
  </Wrapper>
)

export default Draft
