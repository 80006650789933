import blog from "./blog"
import content from "./content"
import contests from "./contests"
import dashboard from "./dashboard"
import eightLeggedFreak from "./eightLeggedFreak"
import forum from "./forum"
import homeVideoReviews from "./homeVideoReviews"
import news from "./news"

export default {
  blog,
  content,
  contests,
  dashboard,
  eightLeggedFreak,
  forum,
  homeVideoReviews,
  news
}
