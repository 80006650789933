import { gql } from "graphql-tag"

const mutation = gql`
  type Mutation {
    addEmailsHeading(
      emailsHeadingAddInput: EmailsHeadingAddInput!
    ): EmailsHeading
    addEmailsFooter(emailsFooterAddInput: EmailsFooterAddInput!): EmailsFooter
    addEmailsTemplate(
      emailsTemplateAddInput: EmailsTemplateAddInput!
    ): EmailsTemplate

    deleteEmailsHeading(id: ID!): EmailsHeadingDeleteResponse!
    deleteEmailsFooter(id: ID!): EmailsFooterDeleteResponse!
    deleteEmailsTemplate(id: ID!): EmailsTemplateDeleteResponse

    sendEmailsTemplate(
      emailsSendTemplateInput: EmailsSendTemplateInput!
    ): EmailsSendTemplateResponse!

    updateEmailsHeading(
      id: ID!
      emailsHeadingUpdateInput: EmailsHeadingUpdateInput!
    ): EmailsHeading
    updateEmailsFooter(
      id: ID!
      emailsFooterUpdateInput: EmailsFooterUpdateInput!
    ): EmailsFooter
    updateEmailsTemplate(
      id: ID!
      emailsTemplateUpdateInput: EmailsTemplateUpdateInput!
    ): EmailsTemplate
  }
`

export default mutation
