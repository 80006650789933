import gql from "graphql-tag"

const contentAuthor = gql`
  fragment ContentAuthor on ContentAuthor {
    id
    name
    slug
  }
`

export default contentAuthor
