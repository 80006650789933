import ADD_PREVIEW from "./addPreview"
import ADD_REVIEW from "./addReview"
import UPDATE_CRITICS_REVIEW_FROM_URL from "./updateCriticsReviewFromUrl"
import UPDATE_REVIEW from "./updateReview"

export default {
  ADD_PREVIEW,
  ADD_REVIEW,
  UPDATE_CRITICS_REVIEW_FROM_URL,
  UPDATE_REVIEW
}
