import { BluBlocksSelect } from "./styled"
import { ComponentProps } from "."

const Select = ({
  label,
  loading,
  name,
  onChange,
  options,
  placeholder,
  value
}: ComponentProps) => (
  <BluBlocksSelect
    label={label}
    loading={loading}
    name={name}
    onChange={onChange}
    options={options}
    placeholder={placeholder}
    value={value}
  />
)

export default Select
