import { ModalContent, ModalTitle } from "./styled"
import { ComponentProps } from "."

const Overrides = ({ children }: ComponentProps) => (
  <ModalContent>
    <ModalTitle>{"Overrides"}</ModalTitle>
    {children}
  </ModalContent>
)
export default Overrides
