import { ComponentProps } from "."
import CriticsPodSource from "components/Critics/PodSource"
import Overrides from "./Overrides"

const PodSource = ({
  areNewsLoading,
  inputRefs,
  isCompact,
  isCreated,
  isLoading,
  isValid,
  label,
  onCancel,
  onClear,
  onSave,
  overrides,
  serviceName,
  text
}: ComponentProps) => (
  <CriticsPodSource
    areItemsLoading={areNewsLoading}
    isCompact={isCompact}
    isCreated={isCreated}
    isLoading={isLoading}
    isValid={isValid}
    itemLabel="News"
    label={label}
    onCancelOverrides={onCancel}
    onClear={onClear}
    onSaveOverrides={onSave}
    overrides={<Overrides inputRefs={inputRefs} overrides={overrides} />}
    serviceName={serviceName}
    text={text}
  />
)
export default PodSource
