import { ComponentProps } from "."
import CriticsAddActions from "components/Critics/AddActions"
import Text from "components/inputs/Text"

const AddActions = ({
  OMDbId,
  imageSelector,
  isAddHomeVideoReviewEnabled,
  isAdmin,
  isOmdbIdValid,
  label,
  loading,
  name,
  onAddHomeVideoReview,
  onClearAll,
  onNameChange,
  onOmdbIdChange
}: ComponentProps) => (
  <CriticsAddActions
    imageSelector={imageSelector}
    isAddItemEnabled={isAddHomeVideoReviewEnabled}
    isAdmin={isAdmin}
    label={label}
    loading={loading}
    name={name}
    onAddItem={onAddHomeVideoReview}
    onClearAll={onClearAll}
    onNameChange={onNameChange}
    serviceType="reviews"
    topItem={
      <Text
        disabled={loading}
        hasValid
        id="OMDbId"
        isValid={isOmdbIdValid}
        label="OMDbId"
        name="OMDbId"
        onChange={onOmdbIdChange}
        text={OMDbId}
      />
    }
  />
)

export default AddActions
