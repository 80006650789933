import gql from "graphql-tag"

const GET_EIGHT_LEGGED_FREAK_NEWS_SOURCES_LIST = gql`
  query getEightLeggedFreakNewsSourcesList(
    $eightLeggedFreakNewsSourcesListInput: EightLeggedFreakNewsSourcesListInput!
  ) {
    eightLeggedFreakNewsSourcesList(
      eightLeggedFreakNewsSourcesListInput: $eightLeggedFreakNewsSourcesListInput
    ) {
      links {
        id
        isFlagged
        isNew
        name
        url
      }
      nextCursor
    }
  }
`
export default GET_EIGHT_LEGGED_FREAK_NEWS_SOURCES_LIST
