const site = {
    news: [
        {
            id: "0",
            rows: [
                {
                    name: "Blu-ray.com",
                    serviceName: "bluRay"
                },
                {
                    name: "Hi-Def Digest",
                    serviceName: "hidefDigest"
                }
            ]
        },
        {
            id: "1",
            rows: [
                {
                    name: "Why So Blu?",
                    serviceName: "whySoBlu"
                },
                {
                    name: "AV Nirvana",
                    serviceName: "avNirvana"
                }
            ]
        }
    ],
    reviews: [
        {
            id: "0",
            rows: [
                {
                    name: "Blu-ray.com",
                    serviceName: "bluRay"
                },
                {
                    name: "AV Forums",
                    serviceName: "avForums"
                }
            ]
        },
        {
            id: "1",
            rows: [
                {
                    name: "AVS Forum",
                    serviceName: "avsForum"
                },
                {
                    name: "Hi-Def Digest",
                    serviceName: "hidefDigest"
                }
            ]
        },
        {
            id: "2",
            rows: [
                {
                    name: "Digital Bits",
                    serviceName: "digitalBits"
                },
                {
                    name: "Do Blu",
                    serviceName: "doBlu"
                }
            ]
        },
        {
            id: "3",
            rows: [
                {
                    name: "Why So Blu?",
                    serviceName: "whySoBlu"
                },
                {
                    name: "AV Nirvana",
                    serviceName: "avNirvana"
                }
            ]
        },
        {
            id: "4",
            rows: [
                {
                    name: "Home Theater Forum",
                    serviceName: "homeTheaterForum"
                },
                {
                    name: "Blu-ray Authority",
                    serviceName: "bluRayAuthority"
                }
            ]
        },
        {
            id: "5",
            rows: [
                {
                    name: "Theater Byte",
                    serviceName: "theaterByte"
                }
            ]
        }
    ]
};
export default site;
