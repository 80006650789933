import AddActions from "./AddActions"
import { ContentAuthor } from "@bluframe/mightychroma-mightytool-frontend-types"
import { Props as ImageSelectorProps } from "components/ImageSelector"
import { Props as SelectProps } from "components/inputs/Select"

export interface Props {
  contentAuthors?: ContentAuthor[]
  imageSelector: ImageSelectorProps
  isAddItemEnabled: boolean
  isAdmin: boolean
  label: string
  loading: boolean
  onAddItem: () => void
  onClearAll: () => void
  onContentAuthorSelect?: SelectProps["onChange"]
  // eslint-disable-next-line no-unused-vars
  onNameChange: (name: string) => void
  name?: string
  serviceType: "news" | "reviews"
  topItem?: React.ReactNode
}

export interface ComponentProps extends Props {}

export default AddActions
