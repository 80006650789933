import { gql } from "graphql-tag"

const homeVideoReviews = gql`
  type Query {
    drafts: [HomeVideoReview]
    draftsWave(cursor: String): HomeVideoReviewWave

    getHomeVideoReviews(isUHD: Boolean): [HomeVideoReview]
    getHomeVideoReview(id: ID!): HomeVideoReview
    searchHomeVideoReviews(
      searchHomeVideoReviewsInput: SearchHomeVideoReviewsInput!
    ): [HomeVideoReview]
  }
`

export default homeVideoReviews
