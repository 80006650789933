/* eslint-disable max-lines-per-function, no-empty-function, max-lines */
import reducer, { DEFAULT_HOME_VIDEO_REVIEW, RESET_SELECTED_REVIEW, RESET_SELECTED_REVIEWS, SET_IMAGE_SERVICE_NAME, SET_IS_ALL_AROUND_GATHER, SET_IS_MATCH_TITLE, SET_IS_ONLY_NEW, SET_IS_ONLY_PRESENT, SET_IS_SHOW_FLAGGED, SET_IS_TOGGLE_FLAGGED_LOADING, SET_IS_UHD, SET_LIST, SET_NAME, SET_NEXT_CURSOR, SET_OMDB_ID, SET_OVERRIDES, SET_SELECTED_HOME_VIDEO_REVIEW, SET_SELECTED_ID, SET_SELECTED_IS_PREVIEW_LOADING, SET_SELECTED_IS_PREVIEW_VALID, SET_SELECTED_IS_REVIEW_CREATED, initialState } from "./reducer";
import { useCallback, useReducer } from "react";
export { DEFAULT_HOME_VIDEO_REVIEW };
export const initialContext = {
    clear: () => { },
    clearAll: () => { },
    resetSelectedReviews: () => { },
    setImageServiceName: () => { },
    setIsAllAroundGather: () => { },
    setIsMatchTitle: () => { },
    setIsOnlyNew: () => { },
    setIsOnlyPresent: () => { },
    setIsShowFlagged: () => { },
    setIsToggleFlaggedLoading: () => { },
    setIsUHD: () => { },
    setList: () => { },
    setName: () => { },
    setNextCursor: () => { },
    setOMDbId: () => { },
    setOverrides: () => { },
    setSelectedHomeVideoReview: () => { },
    setSelectedId: () => { },
    setSelectedIsPreviewLoading: () => { },
    setSelectedIsPreviewValid: () => { },
    setSelectedIsReviewCreated: () => { },
    state: initialState
};
const useReviewsContext = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const resetSelectedReviews = useCallback(() => {
        dispatch({ type: RESET_SELECTED_REVIEWS });
    }, [dispatch]);
    const setNextCursor = useCallback((nextCursor, service) => {
        dispatch({
            payload: { nextCursor, service },
            type: SET_NEXT_CURSOR
        });
    }, [dispatch]);
    const setOMDbId = useCallback((OMDbId) => {
        dispatch({
            payload: { OMDbId },
            type: SET_OMDB_ID
        });
    }, [dispatch]);
    const clear = useCallback((serviceName) => {
        dispatch({
            payload: { service: serviceName },
            type: RESET_SELECTED_REVIEW
        });
    }, [dispatch]);
    const clearAll = useCallback(() => {
        resetSelectedReviews();
        setOMDbId("");
    }, [resetSelectedReviews, setOMDbId]);
    const setImageServiceName = useCallback((imageServiceName) => {
        dispatch({
            payload: { imageServiceName },
            type: SET_IMAGE_SERVICE_NAME
        });
    }, [dispatch]);
    return {
        clear,
        clearAll,
        resetSelectedReviews,
        setImageServiceName,
        setIsAllAroundGather: useCallback((isAllAroundGather, serviceName) => {
            dispatch({
                payload: { isAllAroundGather, service: serviceName },
                type: SET_IS_ALL_AROUND_GATHER
            });
        }, [dispatch]),
        setIsMatchTitle: useCallback((isMatchTitle) => {
            dispatch({ payload: { isMatchTitle }, type: SET_IS_MATCH_TITLE });
        }, [dispatch]),
        setIsOnlyNew: useCallback((isOnlyNew, serviceName) => {
            dispatch({
                payload: { isOnlyNew, service: serviceName },
                type: SET_IS_ONLY_NEW
            });
        }, [dispatch]),
        setIsOnlyPresent: useCallback((isOnlyPresent, serviceName) => {
            dispatch({
                payload: { isOnlyPresent, service: serviceName },
                type: SET_IS_ONLY_PRESENT
            });
        }, [dispatch]),
        setIsShowFlagged: useCallback((isShowFlagged, serviceName) => {
            dispatch({
                payload: { isShowFlagged, service: serviceName },
                type: SET_IS_SHOW_FLAGGED
            });
        }, [dispatch]),
        setIsToggleFlaggedLoading: useCallback((isToggleFlaggedLoading) => {
            dispatch({
                payload: { isToggleFlaggedLoading },
                type: SET_IS_TOGGLE_FLAGGED_LOADING
            });
        }, [dispatch]),
        setIsUHD: useCallback((isUHD) => {
            dispatch({
                payload: { isUHD },
                type: SET_IS_UHD
            });
        }, [dispatch]),
        setList: useCallback((list, serviceName) => {
            dispatch({
                payload: { list, service: serviceName },
                type: SET_LIST
            });
        }, [dispatch]),
        setName: useCallback((name) => {
            dispatch({ payload: { name }, type: SET_NAME });
        }, [dispatch]),
        setNextCursor,
        setOMDbId,
        setOverrides: useCallback((overrides, serviceName) => {
            dispatch({
                payload: { overrides, serviceName },
                type: SET_OVERRIDES
            });
        }, [dispatch]),
        setSelectedHomeVideoReview: useCallback(({ id, isPublished, serviceName, title, url }) => {
            dispatch({
                payload: { id, isPublished, service: serviceName, title, url },
                type: SET_SELECTED_HOME_VIDEO_REVIEW
            });
        }, [dispatch]),
        setSelectedId: useCallback((selected, serviceName) => {
            dispatch({
                payload: { selected, service: serviceName },
                type: SET_SELECTED_ID
            });
        }, [dispatch]),
        setSelectedIsPreviewLoading: useCallback((isPreviewLoading, serviceName) => {
            dispatch({
                payload: { isPreviewLoading, service: serviceName },
                type: SET_SELECTED_IS_PREVIEW_LOADING
            });
        }, [dispatch]),
        setSelectedIsPreviewValid: useCallback((isPreviewValid, serviceName) => {
            dispatch({
                payload: { isPreviewValid, service: serviceName },
                type: SET_SELECTED_IS_PREVIEW_VALID
            });
        }, [dispatch]),
        setSelectedIsReviewCreated: useCallback((id, serviceName) => {
            dispatch({
                payload: { id, service: serviceName },
                type: SET_SELECTED_IS_REVIEW_CREATED
            });
        }, [dispatch]),
        state
    };
};
export default useReviewsContext;
