/* eslint-disable max-lines-per-function, max-statements, no-undefined, max-lines */
import { mutations, queries } from "@bluframe/mightychroma-mightytool-graphql-schema";
import { useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { DEFAULT_NEWS } from "contexts/NewsCritics";
import EditActions from "./EditActions";
import equals from "ramda/src/equals";
import getIsNewsReadyToBeUpdated from "./helpers/getIsNewsReadyToBeUpdated";
import getIsUpdateNewsEnabled from "./helpers/getIsUpdateNewsEnabled";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import useAddEditNews from "hooks/useAddEditNews";
import useCreateNewsCritics from "hooks/useCreateNewsCritics";
import useNewsCritics from "hooks/useNewsCritics";
const { news: { UPDATE_NEWS } } = mutations;
const { news: { GET_NEWS, SEARCH_NEWS } } = queries;
// Define the service names that will be used in the application
const SERVICE_NAMES = [
    "avNirvana",
    "bluRay",
    "hidefDigest",
    "whySoBlu"
];
// Define the default value for a selected News
const DEFAULT_SELECTED_NEWS = "";
// This function prepares the component with the necessary data
const usePrepareComponent = () => {
    // Using custom hooks for various actions and state management
    const { resetSelectedNewsCritics, setSelectedNewsCritics, state: { avNirvana, bluRay, hidefDigest, news, whySoBlu } } = useNewsCritics();
    const { areNewsSourcesCalled, areNewsSourcesLoading, onCreateNewsSource, resetNewsSources } = useCreateNewsCritics();
    const { onClearAll } = useAddEditNews();
    const [selectedNewsId, setSelectedNewsId] = useState(DEFAULT_SELECTED_NEWS);
    // Using Apollo client hooks for data fetching and updating
    const [searchNews, { data: searchNewsData, loading: searchNewsLoading }] = useLazyQuery(SEARCH_NEWS);
    const [updateNews, { called: updateNewsCalled, data: updateNewsData, loading: updateNewsLoading, reset: updateNewsReset }] = useMutation(UPDATE_NEWS);
    const [getNews, { loading: getNewsLoading }] = useLazyQuery(GET_NEWS);
    // Determine if the News is ready to be updated
    const isNewsReadyToBeUpdated = useMemo(() => getIsNewsReadyToBeUpdated({
        avNirvana: avNirvana.selected,
        bluRay: bluRay.selected,
        hidefDigest: hidefDigest.selected,
        id: selectedNewsId,
        news,
        whySoBlu: whySoBlu.selected
    }), [
        avNirvana.selected,
        bluRay.selected,
        hidefDigest.selected,
        news,
        selectedNewsId,
        whySoBlu.selected
    ]);
    // If news are submitted, check if the News is ready to be updated and update it if necessary
    useEffect(() => {
        if (areNewsSourcesCalled) {
            if (isNewsReadyToBeUpdated && !updateNewsCalled) {
                const { authorId, imageServiceName, name: ____, ...preparedNews } = news;
                updateNews({
                    variables: {
                        news: {
                            ...preparedNews,
                            id: selectedNewsId
                        }
                    }
                });
            }
        }
    }, [
        areNewsSourcesCalled,
        updateNews,
        updateNewsCalled,
        isNewsReadyToBeUpdated,
        news,
        selectedNewsId
    ]);
    // If the News is updated, reset the selected news and News ID
    useEffect(() => {
        if (updateNewsData) {
            if (!equals(news, DEFAULT_NEWS)) {
                resetSelectedNewsCritics();
                updateNewsReset();
            }
            resetNewsSources();
            setSelectedNewsId("");
        }
    }, [
        news,
        resetNewsSources,
        resetSelectedNewsCritics,
        setSelectedNewsId,
        updateNewsData,
        updateNewsReset
    ]);
    // Determine if the update News button should be enabled
    const isUpdateNewsEnabled = useMemo(() => getIsUpdateNewsEnabled({
        avNirvana: avNirvana.selected,
        bluRay: bluRay.selected,
        hidefDigest: hidefDigest.selected,
        id: selectedNewsId,
        news,
        whySoBlu: whySoBlu.selected
    }), [
        avNirvana.selected,
        bluRay.selected,
        hidefDigest.selected,
        news,
        selectedNewsId,
        whySoBlu.selected
    ]);
    // Check loading state of various API calls
    const loading = useMemo(() => updateNewsLoading ||
        areNewsSourcesLoading ||
        getNewsLoading ||
        searchNewsLoading, [
        areNewsSourcesLoading,
        updateNewsLoading,
        getNewsLoading,
        searchNewsLoading
    ]);
    // When a News is selected, fetch its data and update the form values
    const onSelectNewsId = (id) => {
        setSelectedNewsId(id ?? "");
        getNews({
            onCompleted: (response) => {
                const data = response.getNews;
                if (data) {
                    SERVICE_NAMES.forEach((serviceName) => {
                        const service = data[serviceName];
                        if (service && service.id !== news?.[`${serviceName}Id`]) {
                            setSelectedNewsCritics({
                                id: service.id,
                                isPublished: data.isPublished,
                                serviceName,
                                title: data.name,
                                url: service.url ?? ""
                            });
                        }
                    });
                }
            },
            variables: {
                id
            }
        });
    };
    // Clear all form values and deselect the News ID
    const onClearAllAndSelected = () => {
        setSelectedNewsId("");
        onClearAll();
    };
    const onGetSuggestions = ({ inputValue }) => {
        searchNews({
            variables: {
                searchNewsInput: {
                    name: inputValue
                }
            }
        });
    };
    // Return the necessary props for the component
    return {
        isUpdateNewsEnabled,
        loading,
        newsTitles: searchNewsData?.searchNews ?? [],
        onClearAll: onClearAllAndSelected,
        onGetSuggestions,
        onSelectNewsId,
        onUpdateNews: onCreateNewsSource,
        selectedNewsId
    };
};
// Export the prepared component
export default prepareComponent(usePrepareComponent)(EditActions);
