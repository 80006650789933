/* eslint-disable no-empty-function */
import { mutations } from "@bluframe/mightychroma-mightytool-graphql-schema";
import { useMemo } from "react";
import { useMutation } from "@apollo/client";
const { news: { ADD_NEWS } } = mutations;
export const initialContext = {
    addNews: () => { },
    called: false,
    data: undefined,
    loading: false,
    reset: () => { }
};
const useAddNewsContext = () => {
    const [addNews, { called, data, loading, reset }] = useMutation(ADD_NEWS);
    return useMemo(() => ({
        addNews,
        called,
        data,
        loading,
        reset
    }), [addNews, called, data, loading, reset]);
};
export default useAddNewsContext;
