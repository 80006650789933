import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-lines-per-function */
import { Row, Wrapper } from "./styled";
import AllAroundGather from "components/Cards/Actions/AllAroundGather";
import Gather from "components/Cards/Actions/Gather";
import List from "components/Cards/Actions/List";
import OnlyNew from "components/Cards/Actions/OnlyNew";
import Reviews from "components/Reviews";
import { ReviewsControlCheckboxes } from "@bluframe/mightychroma-mightytool-ui";
import config from "config/site";
const EightLeggedFreakReviews = ({ reviewsControlCheckboxesProps }) => (_jsxs(Wrapper, { children: [_jsx(ReviewsControlCheckboxes, { ...reviewsControlCheckboxesProps }), config.reviews.map((item) => (_jsx(Row, { children: item.rows.map((row) => (_jsx(Reviews, { actions: _jsxs(_Fragment, { children: [_jsx(Gather, { serviceName: row.serviceName, serviceType: "reviews" }), _jsx(List, { serviceName: row.serviceName, serviceType: "reviews" }), _jsx(OnlyNew, { serviceName: row.serviceName, serviceType: "reviews" }), _jsx(AllAroundGather, { serviceName: row.serviceName, serviceType: "reviews" })] }), name: row.name, serviceName: row.serviceName }, row.serviceName))) }, item.id)))] }));
export default EightLeggedFreakReviews;
