/* eslint-disable max-lines-per-function, max-statements */
import Edit from "./Edit";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import useCreateNewsCritics from "hooks/useCreateNewsCritics";
import useNewsCritics from "hooks/useNewsCritics";
import { useState } from "react";
const DEFAULT_IS_COMPACT = true;
const usePrepareComponent = () => {
    const { clear, setOverrides, state: { avNirvana, bluRay, hidefDigest, news, whySoBlu } } = useNewsCritics();
    const [isCompact, setIsCompact] = useState(DEFAULT_IS_COMPACT);
    const { areNewsSourcesLoading } = useCreateNewsCritics();
    const onClear = (serviceName) => () => {
        clear(serviceName);
    };
    const onSetOverrides = (serviceName) => (overrides) => {
        setOverrides(overrides, serviceName);
    };
    const isCompactCheckboxProps = {
        checked: isCompact,
        isDisabled: areNewsSourcesLoading,
        onChange: () => {
            setIsCompact(!isCompact);
        }
    };
    const newsControlCheckboxesProps = {
        isCompactCheckboxProps
    };
    return {
        areNewsSourcesLoading,
        avNirvana: avNirvana.selected,
        bluRay: bluRay.selected,
        hidefDigest: hidefDigest.selected,
        news,
        newsControlCheckboxesProps,
        onClear,
        onSetOverrides,
        whySoBlu: whySoBlu.selected
    };
};
export default prepareComponent(usePrepareComponent)(Edit);
