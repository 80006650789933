/* eslint-disable max-lines-per-function, max-statements, no-ternary, complexity, max-lines */
const DEFAULT_LIST = [];
const DEFAULT_IS_ONLY_NEW = true;
const DEFAULT_IS_ONLY_PRESENT = false;
const DEFAULT_IS_SHOW_FLAGGED = false;
const DEFAULT_IS_TOGGLE_FLAGGED_LOADING = false;
const DEFAULT_NEXT_CURSOR = null;
// Selected News
const DEFAULT_SELECTED = {
    id: "",
    isPreviewLoading: false,
    isPreviewValid: false,
    url: ""
};
// Default News to create
export const DEFAULT_NEWS = {
    authorId: null,
    avNirvanaId: "",
    bluRayId: "",
    hidefDigestId: "",
    imageServiceName: "bluRay",
    isPublished: false,
    name: "",
    whySoBluId: ""
};
const initalNewsCriticsState = {
    display: DEFAULT_LIST,
    isOnlyNew: DEFAULT_IS_ONLY_NEW,
    isOnlyPresent: DEFAULT_IS_ONLY_PRESENT,
    isShowFlagged: DEFAULT_IS_SHOW_FLAGGED,
    nextCursor: DEFAULT_NEXT_CURSOR,
    selected: DEFAULT_SELECTED
};
export const initialState = {
    avNirvana: initalNewsCriticsState,
    bluRay: initalNewsCriticsState,
    hidefDigest: initalNewsCriticsState,
    isToggleFlaggedLoading: DEFAULT_IS_TOGGLE_FLAGGED_LOADING,
    news: DEFAULT_NEWS,
    whySoBlu: initalNewsCriticsState
};
export const RESET_SELECTED_NEWS_CRITICS = "RESET_SELECTED_NEWS_CRITICS";
export const RESET_SELECTED_NEWS_CRITICS_LIST = "RESET_SELECTED_NEWS_CRITICS_LIST";
export const SET_AUTHOR_ID = "SET_AUTHOR_ID";
export const SET_IMAGE_SERVICE_NAME = "SET_IMAGE_SERVICE_NAME";
export const SET_IS_ONLY_NEW = "SET_IS_ONLY_NEW";
export const SET_IS_ONLY_PRESENT = "SET_IS_ONLY_PRESENT";
export const SET_IS_SHOW_FLAGGED = "SET_IS_SHOW_FLAGGED";
export const SET_IS_TOGGLE_FLAGGED_LOADING = "SET_IS_TOGGLE_FLAGGED_LOADING";
export const SET_LIST = "SET_LIST";
export const SET_NAME = "SET_NAME";
export const SET_NEXT_CURSOR = "SET_NEXT_CURSOR";
export const SET_OVERRIDES = "SET_OVERRIDES";
export const SET_SELECTED_ID = "SET_SELECTED_ID";
export const SET_SELECTED_IS_PREVIEW_LOADING = "SET_SELECTED_IS_PREVIEW_LOADING";
export const SET_SELECTED_IS_PREVIEW_VALID = "SET_SELECTED_IS_PREVIEW_VALID";
export const SET_SELECTED_IS_NEWS_CRITICS_CREATED = "SET_SELECTED_IS_NEWS_CRITICS_CREATED";
export const SET_SELECTED_NEWS = "SET_SELECTED_NEWS";
const parseName = (name) => name
    // Remove year in parentheses
    .replace(/\(\d+\)$/u, "")
    .trim()
    // Remove 4K + 3D
    .replace(/4K \+ 3D/u, "")
    .trim()
    // Remove 4K
    .replace(/4K$/u, "")
    .trim()
    // Remove 3D
    .replace(/3D$/u, "")
    .trim();
const getSelectedNameAndUrl = (state, selected) => {
    const result = state.display.find(({ id }) => id === selected);
    if (!result) {
        return { name: "", url: "" };
    }
    return {
        name: parseName(result.name ?? ""),
        url: result.url
    };
};
const reducer = (
// eslint-disable-next-line default-param-last
state = initialState, action) => {
    switch (action.type) {
        case RESET_SELECTED_NEWS_CRITICS: {
            return {
                ...state,
                [`${action.payload.service}`]: {
                    ...state[action.payload.service],
                    selected: DEFAULT_SELECTED
                }
            };
        }
        case RESET_SELECTED_NEWS_CRITICS_LIST: {
            return {
                avNirvana: { ...state.avNirvana, selected: DEFAULT_SELECTED },
                bluRay: { ...state.bluRay, selected: DEFAULT_SELECTED },
                hidefDigest: { ...state.hidefDigest, selected: DEFAULT_SELECTED },
                isToggleFlaggedLoading: state.isToggleFlaggedLoading,
                news: DEFAULT_NEWS,
                whySoBlu: { ...state.whySoBlu, selected: DEFAULT_SELECTED }
            };
        }
        case SET_AUTHOR_ID: {
            return {
                ...state,
                news: {
                    ...state.news,
                    authorId: action.payload.authorId
                }
            };
        }
        case SET_IMAGE_SERVICE_NAME: {
            return {
                ...state,
                news: {
                    ...state.news,
                    imageServiceName: action.payload.imageServiceName
                }
            };
        }
        case SET_IS_ONLY_NEW: {
            return {
                ...state,
                [`${action.payload.service}`]: {
                    ...state[action.payload.service],
                    isOnlyNew: action.payload.isOnlyNew,
                    isOnlyPresent: action.payload.isOnlyNew
                        ? false
                        : state[action.payload.service].isOnlyPresent
                }
            };
        }
        case SET_IS_ONLY_PRESENT: {
            return {
                ...state,
                [`${action.payload.service}`]: {
                    ...state[action.payload.service],
                    isOnlyNew: action.payload.isOnlyPresent
                        ? false
                        : state[action.payload.service].isOnlyNew,
                    isOnlyPresent: action.payload.isOnlyPresent
                }
            };
        }
        case SET_IS_SHOW_FLAGGED: {
            return {
                ...state,
                [`${action.payload.service}`]: {
                    ...state[action.payload.service],
                    isShowFlagged: action.payload.isShowFlagged
                }
            };
        }
        case SET_IS_TOGGLE_FLAGGED_LOADING: {
            return {
                ...state,
                isToggleFlaggedLoading: action.payload.isToggleFlaggedLoading
            };
        }
        case SET_LIST: {
            return {
                ...state,
                [`${action.payload.service}`]: {
                    ...state[action.payload.service],
                    display: action.payload.list
                }
            };
        }
        case SET_NAME: {
            return {
                ...state,
                news: {
                    ...state.news,
                    name: action.payload.name
                }
            };
        }
        case SET_NEXT_CURSOR: {
            return {
                ...state,
                [`${action.payload.service}`]: {
                    ...state[action.payload.service],
                    nextCursor: action.payload.nextCursor
                }
            };
        }
        case SET_OVERRIDES: {
            return {
                ...state,
                [`${action.payload.serviceName}`]: {
                    ...state[action.payload.serviceName],
                    selected: {
                        ...state[action.payload.serviceName].selected,
                        overrides: {
                            ...state[action.payload.serviceName].selected.overrides,
                            ...action.payload.overrides
                        }
                    }
                }
            };
        }
        case SET_SELECTED_ID: {
            const { url } = getSelectedNameAndUrl(state[action.payload.service], action.payload.selected);
            return {
                ...state,
                news: {
                    ...state.news,
                    // Always reset selected service id
                    [`${action.payload.service}Id`]: ""
                },
                // Add selected service to state
                [`${action.payload.service}`]: {
                    ...state[action.payload.service],
                    selected: {
                        ...state[action.payload.service].selected,
                        id: action.payload.selected,
                        isPreviewValid: false,
                        url
                    }
                }
            };
        }
        case SET_SELECTED_NEWS: {
            return {
                ...state,
                news: {
                    ...state.news,
                    [`${action.payload.service}Id`]: action.payload.id,
                    isPublished: action.payload.isPublished
                },
                [`${action.payload.service}`]: {
                    ...state[action.payload.service],
                    selected: {
                        ...state[action.payload.service].selected,
                        isPreviewValid: false,
                        url: action.payload.url
                    }
                }
            };
        }
        case SET_SELECTED_IS_PREVIEW_LOADING: {
            return {
                ...state,
                [`${action.payload.service}`]: {
                    ...state[action.payload.service],
                    selected: {
                        ...state[action.payload.service].selected,
                        isPreviewLoading: action.payload.isPreviewLoading
                    }
                }
            };
        }
        case SET_SELECTED_IS_PREVIEW_VALID: {
            return {
                ...state,
                [`${action.payload.service}`]: {
                    ...state[action.payload.service],
                    selected: {
                        ...state[action.payload.service].selected,
                        isPreviewValid: action.payload.isPreviewValid
                    }
                }
            };
        }
        case SET_SELECTED_IS_NEWS_CRITICS_CREATED: {
            return {
                ...state,
                news: {
                    ...state.news,
                    [`${action.payload.service}Id`]: action.payload.id
                }
            };
        }
        default: {
            return state;
        }
    }
};
export default reducer;
