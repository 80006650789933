import Autosuggest from "components/Autosuggest"
import { ComponentProps } from "."
import CriticsEditActions from "components/Critics/EditActions"

const EditActions = ({
  homeVideoReviewTitles,
  isUpdateHomeVideoReviewEnabled,
  label,
  loading,
  onClearAll,
  onGetSuggestions,
  onSelectHomeVideoReviewId,
  onUpdateHomeVideoReview,
  selectedHomeVideoReviewId
}: ComponentProps) => (
  <CriticsEditActions
    isUpdateItemEnabled={isUpdateHomeVideoReviewEnabled}
    label={label}
    loading={loading}
    onClearAll={onClearAll}
    onUpdateItem={onUpdateHomeVideoReview}
    topItem={
      <Autosuggest
        disabled={loading}
        label="Select a Home Video Review"
        onGetSuggestions={onGetSuggestions}
        onSuggestionClick={onSelectHomeVideoReviewId}
        suggestions={homeVideoReviewTitles}
        value={selectedHomeVideoReviewId}
      />
    }
  />
)

export default EditActions
