import contentAuthor from "fragments/contentAuthor"
import gql from "graphql-tag"

const GET_CONTENT_AUTHORS = gql`
  ${contentAuthor}

  query getContentAuthors {
    getContentAuthors {
      ...ContentAuthor
    }
  }
`

export default GET_CONTENT_AUTHORS
