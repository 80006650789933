import {
  NewsSource,
  Review
} from "@bluframe/mightychroma-mightytool-frontend-types"
import { ComponentProps } from "."
import Item from "./Item"
import MUIList from "@material-ui/core/List"
import RadioGroup from "@material-ui/core/RadioGroup"
import colors from "styles/colors"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  list: {
    background: colors.white,
    maxHeight: 220,
    overflowY: "auto"
  }
})

const List = ({
  display,
  flagLoading,
  isShowFlagButton,
  label,
  name,
  onSelect,
  onToggleFlagged,
  selected
}: ComponentProps) => {
  const classes = useStyles()

  return (
    <RadioGroup
      aria-label={label}
      name={name}
      onChange={onSelect}
      value={selected}
    >
      <MUIList aria-label={label} className={classes.list}>
        {display.map(({ id, isFlagged, url, ...item }) => (
          <Item
            flagLoading={flagLoading}
            id={id}
            isFlagged={isFlagged}
            isShowFlagButton={isShowFlagButton}
            key={id}
            label={(item as Review).title ?? (item as NewsSource).name ?? url}
            onToggleFlagged={onToggleFlagged}
            url={url}
          />
        ))}
      </MUIList>
    </RadioGroup>
  )
}

export default List
