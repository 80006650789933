/* eslint-disable no-ternary, max-lines-per-function */

import Button from "components/buttons/Button"
import { ComponentProps } from "."
import ImageSelector from "components/ImageSelector"
import Select from "components/inputs/Select"
import Text from "components/inputs/Text"
import { Wrapper } from "./styled"

const AddActions = ({
  contentAuthors,
  imageSelector,
  isAddItemEnabled,
  isAdmin,
  label,
  loading,
  name,
  onAddItem,
  onClearAll,
  onContentAuthorSelect,
  onNameChange,
  serviceType,
  topItem
}: ComponentProps) => (
  <Wrapper>
    {topItem}

    <Button
      bold
      disabled={!isAddItemEnabled || loading}
      label={label}
      loading={loading}
      onClick={onAddItem}
    />
    <Button
      bold
      disabled={loading}
      label="Clear All"
      onClick={onClearAll}
      secondary
    />
    {isAdmin ? <ImageSelector {...imageSelector} /> : null}
    {isAdmin ? (
      <Text
        disabled={loading}
        id="name"
        label="Name"
        name="name"
        onChange={onNameChange}
        text={name}
      />
    ) : null}
    {isAdmin &&
    serviceType === "news" &&
    contentAuthors &&
    onContentAuthorSelect ? (
      <Select
        label="Author"
        name="author"
        onChange={onContentAuthorSelect}
        options={contentAuthors.map((author) => ({
          label: author.name,
          value: author.id
        }))}
      />
    ) : null}
  </Wrapper>
)

export default AddActions
