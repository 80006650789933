/* eslint-disable max-lines-per-function */

import Card from "components/Cards/Card"
import { ComponentProps } from "."

const ReviewsCard = ({
  actions,
  avatar,
  display,
  flagLoading,
  isShowFlagButton,
  onSelect,
  onToggleFlagged,
  selected,
  serviceName,
  subheader,
  title
}: ComponentProps) => (
  <Card
    actions={actions}
    avatar={avatar}
    display={display}
    flagLoading={flagLoading}
    isShowFlagButton={isShowFlagButton}
    label="Reviews"
    name="reviews"
    onSelect={onSelect}
    onToggleFlagged={onToggleFlagged}
    selected={selected}
    serviceName={serviceName}
    subheader={subheader}
    title={title}
  />
)

export default ReviewsCard
