import gql from "graphql-tag"

const EIGHT_LEGGED_FREAK_REVIEW_UPDATE = gql`
  mutation eightLeggedFreakReviewUpdate(
    $eightLeggedFreakReviewInput: EightLeggedFreakReviewInput!
    $serviceName: String!
  ) {
    eightLeggedFreakReviewUpdate(
      eightLeggedFreakReviewInput: $eightLeggedFreakReviewInput
      serviceName: $serviceName
    ) {
      id
    }
  }
`
export default EIGHT_LEGGED_FREAK_REVIEW_UPDATE
