/* eslint-disable no-ternary */

import { ComponentProps } from "."
import Icon from "components/Icon"
import Link from "components/Typography/Link"
import { Wrapper } from "./styled"

const SocialLink = ({
  iconGroup,
  iconName,
  isBig,
  isShowLabel,
  link,
  name,
  platformName
}: ComponentProps) => (
  <Wrapper $isBig={isBig}>
    <Link
      aria-label={`${platformName} Icon`}
      href={link}
      rel="noopener noreferrer"
      target="__blank"
    >
      <Icon group={iconGroup} name={iconName} title={platformName} />
    </Link>
    {isShowLabel ? (
      <Link
        aria-label={`${platformName} Name`}
        href={link}
        rel="noopener noreferrer"
        target="__blank"
      >
        {name}
      </Link>
    ) : null}
  </Wrapper>
)

export default SocialLink
