import { useCallback, useMemo } from "react";
import GET_ME from "queries/getMe";
import { useQuery } from "@apollo/client";
export const initialContext = {
    firstName: "",
    isAdmin: false,
    onClearToken: () => undefined,
    onGetToken: () => undefined,
    onSetToken: () => undefined
};
const useUserContext = () => {
    const { data } = useQuery(GET_ME, {
        fetchPolicy: "cache-first"
    });
    const onClearToken = useCallback(() => {
        localStorage.removeItem("token");
    }, []);
    const onSetToken = useCallback((newToken) => {
        localStorage.setItem("token", newToken);
    }, []);
    const onGetToken = useCallback(() => localStorage.getItem("token"), []);
    return useMemo(() => ({
        firstName: data?.me?.firstName ?? "",
        isAdmin: data?.me?.isAdmin ?? false,
        onClearToken,
        onGetToken,
        onSetToken,
        username: data?.me?.username
    }), [
        data?.me?.firstName,
        data?.me?.isAdmin,
        data?.me?.username,
        onClearToken,
        onGetToken,
        onSetToken
    ]);
};
export default useUserContext;
