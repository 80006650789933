/**
 * Returns true if the service is valid
 * @param {string} url - The url of the service
 * @param {boolean} isPreviewValid - The validity of the preview
 * @returns {boolean} - The validity of the service
 * @example
 * getIsServiceValid("https://www.blu-ray.com/movies/Punch-Drunk-Love-4K-Blu-ray/376197/", true)
 */
const getIsServiceValid = (url, isPreviewValid) => (url && isPreviewValid) || !url;
export default getIsServiceValid;
