import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-ternary, no-undefined */
import { Buttons, Checkboxes, Row } from "./styled";
import List from "components/Cards/Actions/List";
import LoadMore from "components/Cards/Actions/LoadMore";
import News from "components/News";
import OnlyNew from "components/Cards/Actions/OnlyNew";
import OnlyPresent from "components/Cards/Actions/OnlyPresent";
import ShowFlagged from "components/Cards/Actions/ShowFlagged";
import config from "config/site";
const Main = ({ isAdmin }) => (_jsx(_Fragment, { children: config.news.map((item) => (_jsx(Row, { children: item.rows.map((row) => (_jsx(News, { actions: _jsxs(_Fragment, { children: [_jsxs(Buttons, { children: [_jsx(List, { serviceName: row.serviceName, serviceType: "news" }), row.serviceName === "bluRay" ? (_jsx(LoadMore, { serviceName: row.serviceName, serviceType: "news" })) : null] }), isAdmin ? (_jsxs(Checkboxes, { children: [_jsx(OnlyNew, { serviceName: row.serviceName, serviceType: "news" }), _jsx(OnlyPresent, { serviceName: row.serviceName, serviceType: "news" }), _jsx(ShowFlagged, { serviceName: row.serviceName, serviceType: "news" })] })) : null] }), name: row.name, serviceName: row.serviceName }, row.serviceName))) }, item.id))) }));
Main.whyDidYouRender = true;
export default Main;
