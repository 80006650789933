import gql from "graphql-tag"

const UPDATE_REVIEW = gql`
  mutation updateReview(
    $serviceName: String!
    $reviewUpdateInput: ReviewUpdateInput!
  ) {
    updateReview(
      serviceName: $serviceName
      reviewUpdateInput: $reviewUpdateInput
    ) {
      id
      serviceName
    }
  }
`
export default UPDATE_REVIEW
