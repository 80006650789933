/* eslint-disable no-ternary */

import { Theme } from "styles/theme"
import mediaQueries from "styles/mediaQueries"
import styled from "styled-components"

export const Wrapper = styled.div<{
  $isBig?: boolean
}>`
  align-items: center;
  column-gap: 5px;
  display: grid;
  grid-template-columns: auto 1fr;

  & svg {
    color: ${({ theme }: { theme: Theme }) => theme.palette.primary.main};
    ${({ $isBig }) =>
      $isBig ? "height: 30px; width: 30px;" : "height: 20px; width: 20px;"}
  }

  & a {
    align-items: center;
    display: grid;
    overflow-wrap: anywhere;
  }

  ${mediaQueries.tablet} {
    & a {
      overflow-wrap: break-word;
    }
  }
`
