import { Props as AutosuggestProps } from "components/Autosuggest"
import EditActions from "./EditActions"
import { NewsSearchItem } from "@bluframe/mightychroma-mightytool-frontend-types"

export interface Props {
  isUpdateNewsEnabled: boolean
  label: string
  loading: boolean
  newsTitles: NewsSearchItem[]
  onClearAll: () => void
  onGetSuggestions: AutosuggestProps["onGetSuggestions"]
  // eslint-disable-next-line no-unused-vars
  onSelectNewsId: (id: string, name: string) => void
  onUpdateNews: () => void
  selectedNewsId: string
}

export interface ComponentProps extends Props {}

export default EditActions
