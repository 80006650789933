import AddActions from "./AddActions";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import { queries } from "@bluframe/mightychroma-mightytool-graphql-schema";
import useAddNews from "hooks/useAddNews";
import useImageSelector from "hooks/useImageSelector";
import useNewsCritics from "hooks/useNewsCritics";
import { useQuery } from "@apollo/client";
import useUser from "hooks/useUser";
const { content: { GET_CONTENT_AUTHORS } } = queries;
const usePrepareComponent = () => {
    const { isAddNewsEnabled, loading, onAddNews, onClearAll, name } = useAddNews();
    const { setAuthorId, setName } = useNewsCritics();
    const { isAdmin } = useUser();
    const imageSelector = useImageSelector({ serviceType: "news" });
    const { data: getContentAuthorsData } = useQuery(GET_CONTENT_AUTHORS);
    const onContentAuthorSelect = (authorId) => {
        if (authorId) {
            setAuthorId(Number(authorId));
        }
    };
    const onNameChange = (newName) => {
        setName(newName);
    };
    return {
        contentAuthors: getContentAuthorsData?.getContentAuthors || [],
        imageSelector,
        isAddNewsEnabled,
        isAdmin,
        loading,
        name,
        onAddNews,
        onClearAll,
        onContentAuthorSelect,
        onNameChange
    };
};
export default prepareComponent(usePrepareComponent)(AddActions);
