import { jsx as _jsx } from "react/jsx-runtime";
import useAddHomeVideoReviewContext, { initialContext as initialAddHomeVideoReviewContext } from "contexts/AddHomeVideoReview";
import useAddNewsContext, { initialContext as initialAddNewsContext } from "contexts/AddNews";
import useCreateNewsCriticsContext, { initialContext as initialCreateNewsCriticsContext } from "contexts/CreateNewsCritics";
import useCreateReviewsContext, { initialContext as initialCreateReviewsContext } from "contexts/CreateReviews";
import useNewsCriticsContext, { initialContext as initialNewsCriticsContext } from "contexts/NewsCritics";
import usePreviewsContext, { initialContext as initialPreviewsContext } from "contexts/Previews";
import useReviewsContext, { initialContext as initialReviewsContext } from "contexts/Reviews";
import useUserContext, { initialContext as initialUserContext } from "contexts/User";
import { createContext } from "react";
import prepareComponent from "@bluframe/grapple/prepareComponent";
const initialContext = {
    addHomeVideoReview: initialAddHomeVideoReviewContext,
    addNews: initialAddNewsContext,
    createNewsCritics: initialCreateNewsCriticsContext,
    createReviews: initialCreateReviewsContext,
    newsCritics: initialNewsCriticsContext,
    previews: initialPreviewsContext,
    reviews: initialReviewsContext,
    user: initialUserContext
};
const usePrepareComponent = ({ children }) => {
    const addHomeVideoReviewContext = useAddHomeVideoReviewContext();
    const addNewsContext = useAddNewsContext();
    const previewsContext = usePreviewsContext();
    const reviewsContext = useReviewsContext();
    const newsCriticsContext = useNewsCriticsContext();
    const createNewsCriticsContext = useCreateNewsCriticsContext({
        avNirvana: newsCriticsContext.state.avNirvana,
        bluRay: newsCriticsContext.state.bluRay,
        hidefDigest: newsCriticsContext.state.hidefDigest,
        news: newsCriticsContext.state.news,
        whySoBlu: newsCriticsContext.state.whySoBlu
    });
    const createReviewsContext = useCreateReviewsContext({
        avForums: reviewsContext.state.avForums,
        avNirvana: reviewsContext.state.avNirvana,
        avsForum: reviewsContext.state.avsForum,
        bluRay: reviewsContext.state.bluRay,
        bluRayAuthority: reviewsContext.state.bluRayAuthority,
        digitalBits: reviewsContext.state.digitalBits,
        doBlu: reviewsContext.state.doBlu,
        hidefDigest: reviewsContext.state.hidefDigest,
        homeTheaterForum: reviewsContext.state.homeTheaterForum,
        homeVideoReview: reviewsContext.state.homeVideoReview,
        isUHD: reviewsContext.state.isUHD,
        theaterByte: reviewsContext.state.theaterByte,
        whySoBlu: reviewsContext.state.whySoBlu
    });
    const userContext = useUserContext();
    return {
        addHomeVideoReview: addHomeVideoReviewContext,
        addNews: addNewsContext,
        children,
        createNewsCritics: createNewsCriticsContext,
        createReviews: createReviewsContext,
        newsCritics: newsCriticsContext,
        previews: previewsContext,
        reviews: reviewsContext,
        user: userContext
    };
};
const MightyTool = createContext(initialContext);
export const MightyToolProvider = prepareComponent(usePrepareComponent)(({ children, ...props }) => (_jsx(MightyTool.Provider, { value: props, children: children })));
export default MightyTool;
