/* eslint-disable max-lines-per-function, max-statements */
import pick from "ramda/src/pick";
import { queries } from "@bluframe/mightychroma-mightytool-graphql-schema";
import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import usePrevious from "@bluframe/grapple/usePrevious";
import useReviews from "hooks/useReviews";
const { eightLeggedFreak: { GET_EIGHT_LEGGED_FREAK_LIST } } = queries;
const useEightLeggedFreakList = (serviceName) => {
    const location = useLocation();
    let matchTitle;
    const { setList, setNextCursor, state } = useReviews();
    const isEdit = location.pathname === "/home-video-reviews/edit";
    // Only match title if our selected service isn't bluRay
    if ((isEdit || serviceName !== "bluRay") && state.isMatchTitle) {
        // eslint-disable-next-line prefer-destructuring
        matchTitle = state.matchTitle;
    }
    const isSkipGetList = 
    // @ts-ignore
    // Maybe this is our bug?
    (isEdit || serviceName !== "bluRay") && state.isMatchTitle && !matchTitle;
    const [getList, { data, fetchMore, loading, refetch }] = useLazyQuery(GET_EIGHT_LEGGED_FREAK_LIST, {
        fetchPolicy: "network-only",
        nextFetchPolicy: () => {
            if (isSkipGetList) {
                return "cache-only";
            }
            return "network-only";
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: ({ eightLeggedFreakList }) => {
            setList(eightLeggedFreakList.links.map(pick(["id", "isFlagged", "isNew", "title", "url"])), serviceName);
            setNextCursor(eightLeggedFreakList.nextCursor ?? null, serviceName);
        },
        variables: {
            eightLeggedFreakListInput: {
                isUHD: state.isUHD,
                // @ts-ignore
                // Maybe this is our bug?
                matchTitle,
                serviceName
            }
        }
    });
    const { selected: { id: selectedId } } = state[serviceName];
    const previousSelectedId = usePrevious(state[serviceName].selected.id);
    // Refetch on reset
    useEffect(() => {
        if (!selectedId &&
            selectedId !== previousSelectedId &&
            data &&
            !isSkipGetList) {
            refetch();
        }
    }, [
        data,
        isSkipGetList,
        previousSelectedId,
        refetch,
        selectedId,
        // This is important to differentiate between services
        serviceName
    ]);
    return {
        fetchMore,
        getList,
        isSkipGetList,
        isUHD: state.isUHD,
        loading,
        nextCursor: data?.eightLeggedFreakList?.nextCursor,
        refetch
    };
};
export default useEightLeggedFreakList;
