import gql from "graphql-tag"

const UPDATE_NEWS_SOURCE = gql`
  mutation updateNewsSource(
    $serviceName: String!
    $newsSourceUpdateInput: NewsSourceUpdateInput!
  ) {
    updateNewsSource(
      serviceName: $serviceName
      newsSourceUpdateInput: $newsSourceUpdateInput
    ) {
      id
      serviceName
    }
  }
`

export default UPDATE_NEWS_SOURCE
