import { gql } from "graphql-tag"

const news = gql`
  type Query {
    draftsNews: [News]
    draftsNewsWave(cursor: String): NewsWave

    getNews(id: ID!): News
    getNewsList: [News]
    searchNews(searchNewsInput: SearchNewsInput!): [News]
  }
`

export default news
